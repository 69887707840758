import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { notArrayOrEmpty, notEmptyArray, getDistinct1 } from 'src/misc/Misc';
import TeacketUsersPicker from '../../Components/ServicesUsersPicker';
import RangePicker from 'src/components/controls/RangePicker/RangePicker';

//import PropTypes from 'prop-types';

const TicketListSearchBar = () => {
  const { servicesForTenant, setTicketListUsers, ticketListPriorities, setTicketListPriorities, ticketListStatuses, setTicketListStatuses, ticketListServices, setTicketListServices, ticketListQuery, setTicketListQuery, ticketListStartDate, ticketListEndDate, setTicketListStartDate, setTicketListEndDate } = useContext(TeacketContext);

  const [statusList, setStatusList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);

  useEffect(() => {
    // console.log('servicesForTenant:', servicesForTenant);
    if (true === notArrayOrEmpty(servicesForTenant)) return;
    let sl = [];
    let pl = [];
    for (const service of servicesForTenant) {
      if (true === notEmptyArray(service?.props?.statusList)) sl = sl.concat(service.props.statusList.map((x) => x.status));
      if (true === notEmptyArray(service?.props?.priorityList)) pl = pl.concat(service.props.priorityList.map((x) => x.priority));
    }
    sl = getDistinct1(sl);
    pl = getDistinct1(pl);
    // console.log('sl:', sl);
    // console.log('pl:', pl);
    setStatusList(sl);
    setPriorityList(pl);
  }, [servicesForTenant]);

  return (
    <div className='flex flex-row flex-wrap gap-4'>
      <div>
        <RangePicker
          startDate={ticketListStartDate}
          endDate={ticketListEndDate}
          onChange={(v) => {
            setTicketListStartDate(v.startDate);
            setTicketListEndDate(v.endDate);
          }}
        />
      </div>
      <div>
        <TeacketUsersPicker serviceList={servicesForTenant?.map((x) => x.id)} onPick={(e) => setTicketListUsers(e)} />
      </div>
      <div>
        <Select
          mode='multiple'
          value={ticketListPriorities}
          onChange={(v) => {
            setTicketListPriorities(v);
          }}
          className='w-60'
          options={priorityList?.map((x) => {
            return { value: x, label: x };
          })}
          allowClear
          placeholder='[all priorities]'
        />
      </div>
      <div>
        <Select
          mode='multiple'
          value={ticketListStatuses}
          onChange={(v) => {
            setTicketListStatuses(v);
          }}
          className='w-60'
          options={statusList?.map((x) => {
            return { value: x, label: x };
          })}
          allowClear
          placeholder='[all statuses]'
        />
      </div>
      <div>
        <Select
          mode='multiple'
          value={ticketListServices}
          onChange={(v) => {
            setTicketListServices(v);
          }}
          className='w-60'
          options={servicesForTenant?.map((x) => {
            return { value: x.id, label: x.name };
          })}
          allowClear
          placeholder='[all services]'
        />
      </div>
      <div>
        <Input
          className='w-60'
          placeholder='Search...'
          prefix={<SearchOutlined className='mr-2' />}
          allowClear
          value={ticketListQuery}
          onChange={(e) => {
            setTicketListQuery(e.target.value);
          }}
        />
        <span className='ml-4 text-xs opacity-60'>Id, Title, Category, SubCategory and Account Name</span>
      </div>
    </div>
  );
};

TicketListSearchBar.propTypes = {};

export default TicketListSearchBar;
