import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useUserInfo from 'src/hooks/useUserInfo';
import { patchTicketMembers } from 'src/api/teacket';
import { PiBellSimpleLight, PiBellSimpleRingingFill } from 'react-icons/pi';
import { Tooltip, Button, message } from 'antd';
import { uuid } from 'short-uuid';

const SubscribeForLiveEvents = () => {
  const { currentTicket, updateCurrentTicket, currentTicketMembers } = useContext(TeacketContext);
  const { id: userId } = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();

  const isUserMember = currentTicketMembers?.find((user) => user.user_id === userId);

  const subscribeForLiveEvents = async () => {
    console.log('subscribeForLiveEvents()');
    try {
      const status = currentTicket?.notifications?.live ? true : false;

      await patchTicketMembers(currentTicket.id, userId, { live_notifications: !status });
      updateCurrentTicket({ notifications: { ...currentTicket.notifications, live: !status } });

      messageApi.open({
        key: uuid(),
        type: 'success',
        content: 'Change saved',
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {contextHolder}
      {isUserMember && (
        <Tooltip title={`${!currentTicket?.notifications?.live ? 'Subscribe for live events' : 'Unsubscribe for live events'}`} placement='left'>
          <Button type='text' onClick={subscribeForLiveEvents}>
            {currentTicket?.notifications?.live ? <PiBellSimpleRingingFill /> : <PiBellSimpleLight />}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default SubscribeForLiveEvents;
