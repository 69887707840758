import React, { /*useEffect,*/ useContext, useState } from 'react';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';
import { green } from '@ant-design/colors';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getJobs, getJob } from 'src/api/dataSubjectRequests';
import BarLoader from 'react-bar-loader';
import useTheme from 'src/hooks/useTheme';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useNavigate } from 'react-router-dom';

import { MdRefresh } from 'react-icons/md';
import { IoMdAddCircleOutline } from 'react-icons/io';
// import { GrSchedulePlay } from 'react-icons/gr';

// import { Tooltip } from 'antd';

//import PropTypes from 'prop-types';
dayjs.extend(localizedFormat);

const Controls = () => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const navigate = useNavigate();

  const [loading /*, setLoading */] = useState(false);

  const { setJobs, job, setJob } = useContext(DataSubjectRequestsContext);

  const showWizard = () => {
    navigate('/data-subject-requests/new');
  };

  const reloadData = async () => {
    console.log('reloadData()');
    try {
      await executeAsyncProcess(async () => {
        const resp = await getJobs();
        setJobs(resp?.jobs);
        if (job) {
          const selectedJob = await getJob(job.id);
          setJob(selectedJob);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: 'transparent' }}></div>}

      <div className={`flex flex-row justify-center ${!loading ? null : 'disabled'}`}>
        <div className='text-2xl text-center m-4 transform transition hover:scale-125 cursor-pointer' onClick={showWizard}>
          <IoMdAddCircleOutline color={green[4]} />
          <div className='text-sm'>Create</div>
        </div>

        <div className='text-2xl text-center m-4 transform transition hover:scale-125 cursor-pointer' onClick={reloadData}>
          <MdRefresh />
          <div className='text-sm'>Refresh</div>
        </div>
      </div>
    </>
  );
};

Controls.propTypes = {};

export default Controls;
