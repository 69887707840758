import React, { useState } from 'react';
import useScreen from 'src/hooks/useScreen';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import useTheme from 'src/hooks/useTheme';
import AssignmentsRoleSelector from './AssignmentsRoleSelector';
import AssignmentsAssignedItems from './AssignmentsAssignedItems';
//import PropTypes from 'prop-types';

const Assignments = () => {
  const { theme } = useTheme();
  const { isDesktop } = useScreen();

  const [selectedRole, setSelectedRole] = useState(null);

  return (
    <div className='h-full max-h-full w-full overflow-hidden'>
      <Splitter direction={isDesktop ? SplitDirection.Horizontal : SplitDirection.Vertical} initialSizes={[33, 66]}>
        <div className={'overflow-hidden h-full max-h-full w-full pr-4'} style={{ backgroundColor: theme.backgroundBase }}>
          <AssignmentsRoleSelector onRoleSelected={(role) => setSelectedRole(role)} />
        </div>
        <div className={'overflow-scroll h-full max-h-full w-full pl-4'} style={{ backgroundColor: theme.backgroundBase }}>
          <AssignmentsAssignedItems role={selectedRole} />
        </div>
      </Splitter>
    </div>
  );
};

Assignments.propTypes = {};

export default Assignments;
