import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { BsCupHotFill } from 'react-icons/bs';
import { Input, Modal, Space, Button, Alert } from 'antd';
import { getAdminServices, postAdminServices } from 'src/api/teacket';
import TeacketServiceEditor from './TeacketServiceEditor/TeacketServiceEditor';
import { FaPlus } from 'react-icons/fa6';
import StyledButton from 'src/components/layout/StyledButton';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import useUserInfo from 'src/hooks/useUserInfo';
import PageLayoutType2 from 'src/components/layout/PageLayoutType2';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import CondensedTable from 'src/components/layout/CondensedTable';
//import PropTypes from 'prop-types';

const Teacket = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { theme } = useTheme();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();

  const pageSizeOptions = [20, 50, 100];

  const userInfo = useUserInfo();

  const columns = [
    // {
    //   title: 'ID',
    //   key: 'id',
    //   width: 360,
    //   render: (record) => <>{record.id}</>,
    // },
    {
      title: 'Name',
      key: 'name',
      render: (record) => (
        <div style={{ lineHeight: '1rem' }}>
          <div>{record.name}</div>
          <div className='font-light text-xs opacity-60'>{record.id}</div>
        </div>
      ),
    },
    {
      title: 'Prefix',
      key: 'prefix',
      render: (record) => <>{record.ticket_no_prefix}</>,
    },
    {
      title: 'No Sequence',
      key: 'noSequence',
      render: (record) => <>{record.ticket_no_sequence}</>,
    },
  ];

  const [services, setServices] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [createServiceModalOpen, setCreateServiceModalOpen] = useState(false);
  const [newServiceName, setNewServiceName] = useState(null);
  const [newServicePrefix, setNewServicePrefix] = useState(null);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Green Teacket Services',
      },
    ]);

    loadData();

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    setNewServiceName(null);
    setNewServicePrefix(null);
  }, [createServiceModalOpen]);

  const loadData = async (loadMore = false) => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getAdminServices();
        setServices(resp);
      } catch (error) {
        if (error?.toString().includes('The user aborted a request')) return;
        console.log(error);
      }
    });
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //     setSelectedService(selectedRowKeys[0] ?? null);
  //   },
  //   selectedRowKeys: [selectedService],
  //   type: 'radio',
  // };

  const handleOnRow = (record) => {
    return {
      style: { cursor: 'pointer', background: record.id === selectedService ? theme.backgroundLight : null },
      onClick: () => {
        setSelectedService(record.id);
      },
    };
  };

  const handleCreateService = async () => {
    await executeAsyncProcess(async () => {
      try {
        await postAdminServices(newServiceName, newServicePrefix);
        loadData();
        setCreateServiceModalOpen(false);
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master', 'admin.tenant']}>
      <Modal
        title='Create Service'
        open={createServiceModalOpen}
        onCancel={() => setCreateServiceModalOpen(false)}
        footer={
          <Button type='primary' onClick={handleCreateService} disabled={isBusy || !newServiceName || !newServicePrefix || newServiceName.length < 3 || newServicePrefix.length < 2}>
            Create
          </Button>
        }
      >
        <div className={isBusy && 'disabled'}>
          <div>Name</div>
          <div>
            <Input
              maxLength={30}
              count={{
                show: true,
                max: 30,
              }}
              onChange={(e) => setNewServiceName(e.target.value)}
              value={newServiceName}
            />
          </div>
          <div className='mt-2'>Prefix</div>
          <div>
            <Input
              maxLength={3}
              count={{
                show: true,
                max: 3,
              }}
              onChange={(e) => setNewServicePrefix(e.target.value)}
              value={newServicePrefix}
            />
          </div>
        </div>
      </Modal>

      <TitleBar
        title={
          <Space className='btn'>
            <BsCupHotFill />
            Green Teacket
            <span className='font-extralight'>Services</span>
          </Space>
        }
        afterTitleExtras={
          userInfo?.permissions?.includes('admin.master') ? (
            <Space>
              <StyledButton color={theme.textBase} onClick={() => setCreateServiceModalOpen(true)} size='small'>
                <Space className='btn' size='small'>
                  <FaPlus />
                  <WhenDesktop>Create service</WhenDesktop>
                </Space>
              </StyledButton>
            </Space>
          ) : (
            services?.length === 0 && <Alert message='To create a service, please contact the SPC administrators at spc.portal@genesys.com' type='info' showIcon />
          )
        }
      />
      <PageLayoutType2
        left={
          <DraggableLayoutItem title='Service list'>
            <CondensedTable
              size='small'
              pagination={{
                showSizeChanger: true,
                current: page,
                pageSize,
                size: 'small',
                pageSizeOptions,
                onShowSizeChange: (current, size) => {
                  console.log('onShowSizeChange', current, size);
                  setPageSize(size);
                },
                onChange: (page) => setPage(page),
              }}
              columns={columns}
              dataSource={services}
              rowKey={'id'}
              //rowSelection={rowSelection}
              onRow={handleOnRow}
            />
          </DraggableLayoutItem>
        }
        right={<TeacketServiceEditor serviceId={selectedService} />}
      />
    </PermissionsProvider>
  );
};

// Teacket.propTypes = {};

export default Teacket;
