import { ENV, DEFAULT_CALLBACK_URL_SIGNED_IN, IDENTITY_PROVIDERS, LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS, DEFAULT_CALLBACK_URL_SIGNED_IN_ERROR, gcRegionList } from './Config';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';

export const loginCognito = () => {
  const cu = sessionStorage.getItem(SESSION_STORAGE_KEYS.authTargetPath) || DEFAULT_CALLBACK_URL_SIGNED_IN;
  window.location.href = `/login-cognito.html?ci=${ENV.cognitoClientId}&cd=${ENV.cognitoDomain}&cu=${cu}&ap=${ENV.apiUrl}`;
};

export const loginGenesysCloud = (baseUrl, clientId) => {
  const cu = sessionStorage.getItem(SESSION_STORAGE_KEYS.authTargetPath) || DEFAULT_CALLBACK_URL_SIGNED_IN;
  const ecu = DEFAULT_CALLBACK_URL_SIGNED_IN_ERROR;
  const loginPageUrl = `/login-gc.html?en=${baseUrl}&ci=${clientId}&cu=${cu}&ecu=${ecu}&ap=${ENV.apiUrl}`;
  window.location.href = loginPageUrl;
};

export const logout = async (logoutReason = null, callback_url) => {
  if (logoutReason) {
    console.log(`Logout reason: ${logoutReason}`);
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.authLogoutReason, logoutReason);
  }
  const provider = localStorage.getItem(LOCAL_STORAGE_KEYS.authProvider);
  switch (provider) {
    case IDENTITY_PROVIDERS.awsCognito: {
      logoutCognito();
      break;
    }
    default:
      // Use Genesys Cloud as the default identity provider
      logoutGenesysCloud(callback_url);
  }
};

const logoutCognito = () => {
  window.location.href = `/logout-cognito.html?ci=${ENV.cognitoClientId}&cd=${ENV.cognitoDomain}`;
};

const logoutGenesysCloud = (callback_url) => {
  const en = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedGcRegion);
  if (!en) throw new Error('No Genesys Cloud region');
  const ci = gcRegionList.find((item) => item.baseUrl === en)?.clientId;
  if (!ci) throw new Error('No Genesys Cloud client id');
  window.location.href = `/logout-gc.html?ci=${ci}&en=${en}${callback_url ? `&ta=${callback_url}` : ''}`;
};

export const filterItemsBasedOnUserPermissions = (item, userPermissions) => {
  if (true === notArrayOrEmpty(item.permissions)) return true; // no permissions property means no permissions required to display the menu item
  if (true === notEmptyArray(item.permissions) && true === notEmptyArray(userPermissions) && userPermissions.includes(MASTER_ADMIN_PERMISSION)) return true;
  if (true === notEmptyArray(item.permissions) && true === notEmptyArray(userPermissions) && item.permissions.some((permission) => userPermissions.includes(permission))) return true;
  return false;
};

export const hasUserPermission = (permission, userPermissions) => {
  if (!permission) return false;
  if (true === notArrayOrEmpty(userPermissions)) return false;
  if (true === userPermissions.includes(MASTER_ADMIN_PERMISSION)) return true;
  return userPermissions.includes(permission);
};
