import React, { useEffect, useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Button, Space } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import UserTicketListWidget from './Components/UserTicketListWidget';
import { MdAdd, MdRefresh, MdManageAccounts } from 'react-icons/md';
import GraphMyTicketsActive from './Components/GraphMyTicketsActive';
import GraphMyTicketsActiveOldest from './Components/GraphMyTicketsActiveOldest';
import GraphMyTicketsByPriority from './Components/GraphMyTicketsByPriority';
// import GraphMyTicketsVsTenant from './Components/GraphMyTicketsVsTenant';
// import GraphMyTicketsTotalVsTenant from './Components/GraphMyTicketsTotalVsTenant';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import LoaderServicesForTenant from './Loaders/LoaderServicesForTenant';

//import PropTypes from 'prop-types';

const Home = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { setUserTicketListRefresh, currentTicket } = useContext(TeacketContext);
  const navigate = useNavigate();

  const componentsGallery = [
    {
      id: 'My Open Tickets',
      col: 0,
      component: (
        <DraggableLayoutItem
          titleExtras={
            <Space>
              <Button size='small' onClick={() => setUserTicketListRefresh(true)}>
                <Space className='btn'>
                  <MdRefresh />
                  <WhenDesktop>Refresh</WhenDesktop>
                </Space>
              </Button>
              <Button type='primary' size='small' onClick={() => navigate('/teacket/create-new')}>
                <Space className='btn'>
                  <MdAdd />
                  <WhenDesktop>Create new</WhenDesktop>
                </Space>
              </Button>
            </Space>
          }
          title={
            <Space className='btn'>
              {/* <MdOutlineSecurity /> */}
              My Open Tickets
            </Space>
          }
          centered
        >
          <UserTicketListWidget />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'My Active Tickets by Status',
      col: 1,
      component: (
        <DraggableLayoutItem title={<Space className='btn'>My Active Tickets by Status</Space>} centered>
          <GraphMyTicketsActive />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'My Active Tickets by Priority',
      col: 1,
      component: (
        <DraggableLayoutItem title={<Space className='btn'>My Active Tickets by Priority</Space>} centered>
          <GraphMyTicketsByPriority />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'My Oldest Active Ticket',
      col: 1,
      component: (
        <DraggableLayoutItem title={<Space className='btn'>My Oldest Active Ticket</Space>} centered>
          <GraphMyTicketsActiveOldest />
        </DraggableLayoutItem>
      ),
    },
    // {
    //   id: 'My Active Tickets vs Tenant',
    //   col: 2,
    //   component: (
    //     <DraggableLayoutItem title={<Space className='btn'>My Active Tickets vs Tenant</Space>} centered>
    //       <GraphMyTicketsVsTenant />
    //     </DraggableLayoutItem>
    //   ),
    // },
    // {
    //   id: 'All My Tickets vs Tenant',
    //   col: 2,
    //   component: (
    //     <DraggableLayoutItem title={<Space className='btn'>All My Tickets vs Tenant</Space>} centered>
    //       <GraphMyTicketsTotalVsTenant />
    //     </DraggableLayoutItem>
    //   ),
    // },
  ];

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.view']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space className='btn'>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space>
          }
          afterTitleExtras={
            <div>
              <PageSwitch pageList={pageList} />
            </div>
          }
        >
          <PermissionsProvider requiredPermissions={['master.admin']} isControl>
            <Button
              type='text'
              onClick={() => {
                navigate('/master-admin/teacket-services');
              }}
            >
              <MdManageAccounts />
            </Button>
          </PermissionsProvider>
          {layoutItemsSelector}
        </TitleBar>
        <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={0} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutTeacketHome} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutTeacketHomeHidden} enabled={null === currentTicket} />
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// Home.propTypes = {};

export default Home;
