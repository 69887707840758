import React, { useState, useEffect } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { AiOutlineAudit } from 'react-icons/ai';
import { Space } from 'antd';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import { MdOutlineEmail } from 'react-icons/md';
import SubscriptionsWidget from './Components/Events/SubscriptionsWidget';
import EventsSetup from './Components/Events/EventsSetup';
import SubscriptionsWidgetBtns from './Components/Events/SubscriptionsWidgetBtns';

//import PropTypes from 'prop-types';

const Events = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  const componentsGallery = [
    {
      id: 'Subscriptions',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdOutlineEmail />
              Email Subscriptions
            </Space>
          }
          centered
          titleExtras={<SubscriptionsWidgetBtns />}
        >
          <SubscriptionsWidget />
        </DraggableLayoutItem>
      ),
    },
  ];

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Audit Viewer',
        path: '/audit-viewer',
      },
      {
        title: 'Notifications',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.auditviewer.view', 'features.sns.edit']}>
      <TitleBar
        title={
          <Space className='btn'>
            <AiOutlineAudit />
            Audit Viewer
            <span className='font-thin'>Notifications</span>
          </Space>
        }
      >
        {layoutItemsSelector}
      </TitleBar>
      <EventsSetup allowToInstall>
        <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={1} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutAuditViewerEvents} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutAuditViewerEventsHidden} />
      </EventsSetup>
    </PermissionsProvider>
  );
};

// Events.propTypes = {};

export default Events;
