import React, { useState, useEffect } from 'react';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import Chat from '../../controls/SecurityQAGPT/Chat';
import MobileSiderbar from '../../controls/SecurityQAGPT/MobileSidebar';
import Sidebar from '../../controls/SecurityQAGPT/Sidebar';
import useBreadcrumb from 'src/hooks/useBreadcrumb';

const SecurityQAGPTHome = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  const [isComponentVisible, setIsComponentVisible] = useState(false);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'COE AI Assistant',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const toggleComponentVisibility = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <PermissionsProvider requiredPermissions={['apps.copilot.view']}>
      <div className='overflow-hidden w-full h-screen relative flex'>
        {isComponentVisible ? <MobileSiderbar toggleComponentVisibility={toggleComponentVisibility} /> : null}
        <div className='dark hidden flex-shrink-0 bg-gray-900 md:flex md:w-[260px] md:flex-col'>
          <div className='flex h-full min-h-0 flex-col'>
            <Sidebar />
          </div>
        </div>
        <Chat toggleComponentVisibility={toggleComponentVisibility} />
      </div>
    </PermissionsProvider>
  );
};

export default SecurityQAGPTHome;
