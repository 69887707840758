import React, { useEffect } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useUserInfo from 'src/hooks/useUserInfo';
import TitleBar from 'src/components/layout/TitleBar';
import { Content } from 'antd/es/layout/layout';
import UserImage from 'src/components/layout/UserImage';
import { Table } from 'antd';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import ThirdPartyProvider from 'src/components/controls/ThirdPartyProvider/ThirdPartyProvider';
import UserPermissions from 'src/components/controls/UserResultantPermissions/UserResultantPermissions';
//import PropTypes from 'prop-types';

const UserProfile = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const userInfo = useUserInfo();

  const columns = [
    {
      title: 'Property',
      key: 'property',
      width: 120,
      render: (record) => <div style={{ maxWidth: '110px', wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{record.property}</div>,
    },
    {
      title: 'Value',
      key: 'value',
      width: 320,
      render: (record) => <div style={{ wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{record.value}</div>,
    },
  ];

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'My profile',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const getData = () => {
    return [
      {
        key: 'provider',
        property: 'Identity provider',
        value: <ThirdPartyProvider provider={userInfo.provider} />,
      },
      {
        key: 'userId',
        property: 'User id',
        value: userInfo.id,
      },
      {
        key: 'department',
        property: 'Department',
        value: userInfo.department ?? 'n/a',
      },
      {
        key: 'country',
        property: 'Country',
        value: (
          <>
            {userInfo.country ?? 'n/a'} <span className='font-light text-xs opacity-60'>(based on phone number from the profile)</span>
          </>
        ),
      },
      {
        key: 'tenantId',
        property: 'Tenant id',
        value: userInfo.tenant_id,
      },
      {
        key: 'tenant_name',
        property: 'Tenant name',
        value: userInfo.tenant_name ?? 'n/a',
      },
      {
        key: 'acc_type',
        property: 'Account type',
        value: userInfo.acc_type ?? 'n/a',
      },
      {
        key: 'third_party_env',
        property: '3rd party env',
        value: userInfo.third_party_env ?? 'n/a',
      },
      {
        key: 'email',
        property: 'Email',
        value: userInfo.email,
      },
      {
        key: 'createdAt',
        property: 'Created at',
        value: userInfo.created_at,
      },
      {
        key: 'updatedAt',
        property: 'Updated at',
        value: userInfo.updated_at,
      },
    ];
  };

  return (
    <>
      <TitleBar title='My profile' />
      <Content className='p-4 flex flex-row justify-center'>
        <div className='w-full max-w-3xl'>
          <DraggableLayoutItem>
            <div className='flex flex-row justify-left items-center'>
              <UserImage image={userInfo.image} />
              <span className='ml-4 text-4xl font-semibold  overflow-hidden overflow-ellipsis'>{userInfo.name}</span>
            </div>
            <hr />
            <div className='my-4'>
              <Table columns={columns} dataSource={getData()} pagination={false} size='small' bordered={false} showHeader={false} />
            </div>
            <hr />
            <div className='my-4'>
              <div className='mb-2'>Permissions:</div>
              <UserPermissions resultantPermissions={userInfo.resultantPermissions} />
            </div>
          </DraggableLayoutItem>
        </div>
      </Content>
    </>
  );
};

// UserProfile.propTypes = {};

export default UserProfile;
