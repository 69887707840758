import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useUserInfo from 'src/hooks/useUserInfo';
//import useUserInfo from 'src/hooks/useUserInfo';
// import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropertyItem from 'src/components/layout/PropertyItem';
import TicketActiveViewers from './TicketActiveViewers';
import { notEmptyArray } from 'src/misc/Misc';
import { Modal, message } from 'antd';
import { patchTicket } from 'src/api/teacket';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import SnowflakeAccountPicker from 'src/components/controls/SnowflakeAccountPicker/SnowflakeAccountPicker';
import SnowflakeOpportunityPicker from 'src/components/controls/SnowflakeOpportunityPicker/SnowflakeOpportunityPicker';
import DeploymentPicker from './DeploymentPicker';
import { uuid } from 'short-uuid';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const TicketProperties = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { currentTicket, activeViewers, servicesForTenant, updateCurrentTicket, currentTicketReadOnly, getServiceSettingForCurrentTicket, currentTicketIsMasterTenant } = useContext(TeacketContext);
  const { id: userId } = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();
  const [isOkDisabled, setIsOkDisabled] = useState(true);

  const serviceName = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.name;

  // account and opportunity
  const [canUpdateOppAcc, setCanUpdateOppAcc] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [snowflakeOpportunitySupport, setSnowflakeOpportunitySupport] = useState(false);
  const [opportunityModalOpen, setOpportunityModalOpen] = useState(false);
  const [opportunity, setOpportunity] = useState(null);

  // deployment
  const [gcDeploymentSupport, setGcDeploymentSupport] = useState(null);
  const [deploymentModalOpen, setDeploymentModalOpen] = useState(false);
  const [canUpdateDeployment, setCanUpdateDeployment] = useState(false);
  const [deployment, setDeployment] = useState(null);

  // const [deploymentOptions, setDeploymentOptions] = useState(null);

  useEffect(() => {
    const sf = '1' === getServiceSettingForCurrentTicket('SnowflakeOpportunitySupport');
    setSnowflakeOpportunitySupport(sf);

    const gc = '1' === getServiceSettingForCurrentTicket('GcDeploymentSupport');
    setGcDeploymentSupport(gc);
  }, []);

  useEffect(() => {
    setCanUpdateOppAcc(determineIfCanUpdateOppAcc());
    setCanUpdateDeployment(determineIfCanUpdateDeployment());
  }, [currentTicketReadOnly, currentTicketIsMasterTenant]);

  // <TODO: move this handler to "more global" place - maybe to LoaderCurrentTicket or create a dedicated component? - this component is responsible for displaying part of the ticket data only>
  const handleOnWsMessageReceived = async (message) => {
    try {
      console.log('[TicketProperties] TICKET_UPDATED:', message);
      if (!message?.data?.details) return;
      message.data.details.updated_at = new Date();
      updateCurrentTicket(message.data.details);
      let msg = `${Object.keys(message.data.details)[0]}`;
      msg = msg.charAt(0).toUpperCase() + msg.slice(1);
      messageApi.open({
        key: uuid(),
        type: 'success',
        content: `${msg} updated.`,
        duration: 3,
      });
    } catch (error) {
      console.error(error);
    }
  };
  // </TODO: move this handler to "more global" place - maybe to LoaderCurrentTicket or create a dedicated component?>

  const handleAccountModalOnSelect = (v) => {
    console.log('handleAccountModalOnSelect', v);
    setAccount(v);
  };

  const handleOpportunityModalOnSelect = (v) => {
    console.log('handleOpportunityModalOnSelect', v);
    setOpportunity(v);
  };

  const handleDeploymentModalOnSelect = (v) => {
    console.log('handleDeploymentModalOnSelect', v);
    setDeployment(v);
  };

  const handleAccountModalOnOk = () => {
    console.log('handleAccountModalOnOk');
    executeAsyncProcess(async () => {
      try {
        const { ACCOUNT_ID: acc_id, ACCOUNT_NAME: acc_name } = account;
        const ticket = await patchTicket(currentTicket.id, { acc_id, acc_name, opp_id: null, opp_name: null });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Account successfully updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
    setAccountModalOpen(false);
  };

  const handleOpportunityModalOnOk = () => {
    console.log('handleOpportunityModalOnOk');
    executeAsyncProcess(async () => {
      try {
        const { ACCOUNT_ID: acc_id, ACCOUNT_NAME: acc_name, OPPORTUNITY_ID: opp_id, OPPORTUNITY_NAME: opp_name } = opportunity;

        const oppProps = {
          OPPORTUNITY_AGENTS: opportunity?.OPPORTUNITY_AGENTS,
          OPPORTUNITY_CURRENCY_ISO_CODE: opportunity?.OPPORTUNITY_CURRENCY_ISO_CODE,
          OPPORTUNITY_GROSS_ACV_BOOKING: opportunity?.OPPORTUNITY_GROSS_ACV_BOOKING,
          OPPORTUNITY_GROSS_ACV_BOOKING_USD: opportunity?.OPPORTUNITY_GROSS_ACV_BOOKING_USD,
          OPPORTUNITY_COUNTRY_REGION: opportunity?.OPPORTUNITY_COUNTRY_REGION,
          OPPORTUNITY_COUNTRY_SUB_REGION: opportunity?.OPPORTUNITY_COUNTRY_SUB_REGION,
          OPPORTUNITY_DEAL_TYPE: opportunity?.OPPORTUNITY_DEAL_TYPE,
          OPPORTUNITY_DIRECT_INDIRECT_SALE: opportunity?.OPPORTUNITY_DIRECT_INDIRECT_SALE,
          OPPORTUNITY_SALES_SEGMENT: opportunity?.OPPORTUNITY_SALES_SEGMENT,
          OPPORTUNITY_SEATS: opportunity?.OPPORTUNITY_SEATS,
          OPPORTUNITY_STAGE_NAME: opportunity?.OPPORTUNITY_STAGE_NAME,
          OPPORTUNITY_DSR_URL: opportunity?.OPPORTUNITY_DSR_URL,
          OPPORTUNITY_OWNER_NAME: opportunity?.OPPORTUNITY_OWNER_NAME,
          OPPORTUNITY_OWNER_EMAIL: opportunity?.OPPORTUNITY_OWNER_EMAIL,
          OPPORTUNITY_SC_NAME: opportunity?.OPPORTUNITY_SC_NAME,
          OPPORTUNITY_SC_EMAIL: opportunity?.OPPORTUNITY_SC_EMAIL,
        };

        let ticketProps = { ...currentTicket.props, ...oppProps };
        const ticket = await patchTicket(currentTicket.id, { acc_id, acc_name, opp_id, opp_name, props: ticketProps });
        ticketProps = { ...ticket.props, ...oppProps };

        updateCurrentTicket({ ...ticket, props: ticketProps });
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Opportunity successfully updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
    setOpportunityModalOpen(false);
  };

  const handleDeploymentModalOnOk = () => {
    console.log('handleDeploymentModalOnOk');
    executeAsyncProcess(async () => {
      try {
        const { value: dep_id, label: dep_name } = deployment;
        const ticket = await patchTicket(currentTicket.id, { dep_id, dep_name });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Deployment successfully updated',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      }
    });
    setDeploymentModalOpen(false);
  };

  const determineIfCanUpdateDeployment = () => {
    if (!currentTicketReadOnly && currentTicketIsMasterTenant) return true;
    return false;
  };

  const determineIfCanUpdateOppAcc = () => {
    if (!currentTicketReadOnly && currentTicketIsMasterTenant) return true;
    return false;
  };

  return (
    <>
      {contextHolder}
      {accountModalOpen && (
        <Modal
          title={'Search for account'}
          open={true}
          onCancel={() => {
            setAccountModalOpen(false);
          }}
          onOk={handleAccountModalOnOk}
        >
          <SnowflakeAccountPicker onSelect={handleAccountModalOnSelect} />
        </Modal>
      )}
      {opportunityModalOpen && (
        <Modal
          title={'Search for opportunity'}
          open={true}
          onCancel={() => {
            setOpportunityModalOpen(false);
          }}
          onOk={handleOpportunityModalOnOk}
          okButtonProps={{ disabled: isOkDisabled }}
        >
          <SnowflakeOpportunityPicker onSelect={handleOpportunityModalOnSelect} setBusy={setIsOkDisabled} />
        </Modal>
      )}
      {deploymentModalOpen && (
        <Modal
          title={'Select a deployment'}
          open={true}
          onCancel={() => {
            setDeploymentModalOpen(false);
          }}
          onOk={handleDeploymentModalOnOk}
        >
          <DeploymentPicker accId={currentTicket.acc_id} defaultValue={currentTicket.dep_id} onSelect={handleDeploymentModalOnSelect} />
        </Modal>
      )}
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_UPDATED']}></GlobalWsMessenger>
      <div className='flex flex-col gap-2 mt-4'>
        {true === notEmptyArray(activeViewers.filter((user) => user.user_id !== userId)) && <PropertyItem label='Others are also viewing this ticket' value={<TicketActiveViewers />} loading={!currentTicket} propertyColor='#78350f' />}
        <PropertyItem label='Service' value={serviceName} />
        <PropertyItem label='Category / SubCategory' value={currentTicket.category} secondValue={currentTicket.sub_category} />
        <PropertyItem label='Account' value={currentTicket?.acc_name} secondValue={currentTicket?.acc_id} onClick={canUpdateOppAcc ? () => setAccountModalOpen(true) : null} />
        {true === snowflakeOpportunitySupport && <PropertyItem label='Opportunity' value={currentTicket?.opp_name} secondValue={currentTicket?.opp_id} onClick={canUpdateOppAcc ? () => setOpportunityModalOpen(true) : null} />}
        {currentTicket.acc_id && true === gcDeploymentSupport && <PropertyItem label='Deployment' value={currentTicket.dep_name} secondValue={currentTicket.dep_id} onClick={canUpdateDeployment ? () => setDeploymentModalOpen(true) : null} />}
      </div>
    </>
  );
};

TicketProperties.propTypes = {};

export default TicketProperties;
