import React from 'react';
import useUserInfo from 'src/hooks/useUserInfo';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import { Space } from 'antd';
import { GiPotionBall } from 'react-icons/gi';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import PageVisits from './Components/PageVisits';

const SubjectRequests = () => {
  const { permissions: userPermissions } = useUserInfo();

  const componentsGallery = [
    {
      id: 'Page Visits',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space>
              <GiPotionBall />
              Page Visits
            </Space>
          }
        >
          <PageVisits url='/teacket' />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.dashboards.greenteacket.view'],
    },
  ].filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions));

  return (
    <PermissionsProvider requiredPermissions={['apps.dashboards.greenteacket.view']}>
      <DraggableLayoutProvider columns={3} mainColumnIndex={null} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutDashboards} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutDashboardsHidden} />
    </PermissionsProvider>
  );
};

export default SubjectRequests;
