import React from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { LuFingerprint } from 'react-icons/lu';
import { Space } from 'antd';
//import PropTypes from 'prop-types';

const DataSubjectRequests = () => {
  return (
    <>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 max-w-7xl '>
          <div className='my-8 font-bold text-2xl'>
            <Space>
              <LuFingerprint />
              Data Subject Requests
            </Space>
          </div>
          <div>
            <a href='#installation' target='_self' className='decoration-solid hover:decoration-slice'>
              Requirements & Installation
            </a>
          </div>
          <div className='my-8'>Introducing the Data Subject Requests tool – your comprehensive solution for understanding and navigating the intricacies of GDPR compliance within your Genesys Cloud organization. Designed to provide hands-on experience and real-world insights, the Data Subject Requests tool empowers organizations to efficiently manage GDPR-related operations while ensuring compliance with data protection regulations.</div>
          <div className='my-8'>At its core, the Data Subject Requests tool offers a dynamic platform for performing real GDPR API operations within your Genesys Cloud environment. With seamless integration and intuitive controls, users can interact with the Genesys Cloud API to execute essential GDPR tasks, such as data access requests, data erasure requests, and data portability requests.</div>
          <div className='my-8'>The Data Subject Requests tool goes beyond mere functionality by providing a simulated environment for conducting fake GDPR requests. This invaluable feature allows organizations to simulate various GDPR scenarios and workflows, enabling users to gain practical experience in handling GDPR-related processes without impacting real data or operations.</div>
          <div className='my-8'>Through its user-friendly interface, the Data Subject Requests tool offers a step-by-step walkthrough of GDPR operations, guiding users through each stage of the process with clarity and precision. Whether you're managing data access requests from customers or responding to data erasure requests, the Data Subject Requests tool streamlines the entire process, ensuring compliance with GDPR regulations while minimizing administrative overhead.</div>
          <div className='my-8'>By leveraging the Data Subject Requests tool, organizations can proactively address GDPR compliance requirements, mitigate risks, and build trust with customers by demonstrating a commitment to protecting their privacy and data rights. With its powerful features and simulated environment, the Data Subject Requests tool is the ultimate solution for mastering GDPR compliance within your Genesys Cloud organization.</div>
          <div id='installation' className='my-8 font-bold text-2xl'>
            <Space>Requirements & Installation</Space>
          </div>
          <div className='my-8'>
            The Data Subject Requests tool requires the following permissions to run:
            <ul className='list-disc list-inside'>
              <li>
                GDPR &gt; All Permissions <i>to be able to view and create GDPR requests as well as searching for GDPR subjects</i>
              </li>
            </ul>
            <div className='my-8'>After creating a role for the users that contains this permission or assigning the permission directly to a user, you will need to logout and log back in this portal to gain full access to this tool. </div>
            <div>You may logout from this portal by using your profile picture on the top-right.</div>
            <div className='flex justify-center mt-4'>
              <img src='/images/products/User_Sign_Out.png' alt='Log out/Sign out' className='shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Copilot.propTypes = {};

export default DataSubjectRequests;
