import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Dropdown, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { searchRoles } from 'src/api/accesscontrol';
import { notEmptyArray } from 'src/misc/Misc';
import { DEFAULT_ROLE } from 'src/misc/Config';

var rolesCurrentPage = 1;

const AssignmentsRoleSelector = ({ onRoleSelected }) => {
  const items = [
    {
      key: 10,
      label: <>10</>,
    },
    {
      key: 20,
      label: <>20</>,
    },
    {
      key: 30,
      label: <>30</>,
    },
  ];

  const columns = [
    {
      title: 'Role',
      key: 'role',
      render: (record) => <>{record.role}</>,
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => <span className='font-extralight'>{record.description}</span>,
    },
  ];

  const [roles, setRoles] = useState(null);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(parseInt(items[0].key));
  const [thereIsMore, setThereIsMore] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [rerenderTable, setRerenderTable] = useState(false);

  // useEffect(() => {
  //   console.log('roles', roles);
  // }, [roles]);

  useEffect(() => {
    rolesCurrentPage = 1;
    setRerenderTable(true);
    setRoles(null);
    setThereIsMore(false);
    setSelectedRole(null);
    loadData();
  }, [query, pageSize]);

  useEffect(() => {
    if (rerenderTable) setRerenderTable(false);
  }, [rerenderTable]);

  useEffect(() => {
    if (onRoleSelected) onRoleSelected(selectedRole);
  }, [selectedRole]);

  const loadData = async (loadMore = false) => {
    setLoading(true);
    try {
      const q = query?.trim() ?? null;
      const resp = await searchRoles(q?.length > 0 ? q : null, rolesCurrentPage, pageSize);
      rolesCurrentPage++;
      setThereIsMore(resp?.length === pageSize ? true : false);
      if (true === notEmptyArray(resp)) {
        if (loadMore) setRoles([...roles, ...resp]);
        else setRoles(resp);
      }
    } catch (error) {
      if (error?.toString().includes('The user aborted a request')) return;
      console.log(error);
    }
    setLoading(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.find((x) => x.role === DEFAULT_ROLE)) {
        setSelectedRole(null);
        return;
      }
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRole(selectedRowKeys[0] ?? null);
    },
    selectedRowKeys: [selectedRole],
    type: 'radio',
  };

  const handleOnRow = (record) => {
    return {
      onClick: () => {
        if (DEFAULT_ROLE === record.role) {
          setSelectedRole(null);
          return;
        }
        setSelectedRole(record.role);
      },
    };
  };

  return (
    <div className='flex flex-col h-full max-h-full w-full overflow-hidden'>
      <div className='flex-initial p-4 flex flex-row justify-center'>
        <div className='flex flex-col'>
          <Input
            className='w-60'
            placeholder='Search...'
            prefix={loading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
            allowClear
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />

          <div>
            <Dropdown
              trigger={['click']}
              menu={{
                activeKey: pageSize,
                items,
                onClick: (e) => {
                  console.log(e);
                  setPageSize(parseInt(e.key));
                },
              }}
              className='text-xs cursor-pointer'
            >
              <span className='text-blue-600 opacity-40 hover:opacity-100 '>page size {pageSize}</span>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className='flex-auto overflow-auto'>
        {!rerenderTable && (
          <Table
            loading={thereIsMore && loading}
            pagination={{
              pageSize,
              total: thereIsMore ? roles?.length + 1 : roles?.length ?? 0,
              onChange: (page, pageSize) => (thereIsMore && page > roles?.length / pageSize ? loadData(true) : null),
            }}
            style={{ marginTop: '1px', marginBottom: '64px' }}
            size='small'
            columns={columns}
            dataSource={roles}
            rowKey={(record) => record.role}
            rowSelection={rowSelection}
            onRow={handleOnRow}
          />
        )}
      </div>
    </div>
  );
};

AssignmentsRoleSelector.propTypes = {
  onRoleSelected: PropTypes.func,
};

export default AssignmentsRoleSelector;
