//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';

//#region "USERS"\
var controllerGetUsers;
export const getUsers = async (query, services, page = 1, pageSize = 8) => {
  controllerGetUsers?.abort('cancelled');
  controllerGetUsers = new AbortController();
  const signal = controllerGetUsers.signal;
  const queryStringParams = objToQueryStringParams({ query, services, page, pageSize });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/users${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

//#endregion

//#region "SERVICES"

export const getServicesForTenant = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/services/tenant`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

var controllerGetServiceUsers;
export const getServiceUsers = async (serviceId, query, page = 1, pageSize = 8, countries) => {
  controllerGetServiceUsers?.abort('cancelled');
  controllerGetServiceUsers = new AbortController();
  const signal = controllerGetServiceUsers.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize, countries });

  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/services/${serviceId}/users${queryStringParams}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getServicesIsMaster = async (serviceId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/services/${serviceId}/is-master`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "TICKETS"

export const postTicket = async (body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchTicket = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const patchTicketProperties = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/props`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

var controllerGetTickets;
export const getTickets = async (page, pageSize, query, services, priorities, statuses, users, from, to) => {
  controllerGetTickets?.abort('cancelled');
  controllerGetTickets = new AbortController();
  const signal = controllerGetTickets.signal;
  const queryStringParams = objToQueryStringParams({ query, services, priorities, statuses, users, from, to, page, pageSize });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getMyTickets = async (page, pageSize, query, services, priorities, statuses, users) => {
  controllerGetTickets?.abort('cancelled');
  controllerGetTickets = new AbortController();
  const signal = controllerGetTickets.signal;
  const queryStringParams = objToQueryStringParams({ query, services, priorities, statuses, users, page, pageSize });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/my${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getTicketsMyOpen = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/my/open`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getTicket = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

var controllerHistory = null;
export const getTicketHistory = async (ticketId) => {
  controllerHistory?.abort('cancelled');
  controllerHistory = new AbortController();
  const signal = controllerHistory.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/history`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getTicketHistoryDetails = async (ticketId, itemId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/history/${itemId}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const postEscalateTicket = async (ticketId, status, justification) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/escalate`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ status, justification }),
    })
  );
};

//#endregion

//#region "CONVERSATION"

export const postTicketConversation = async (ticketId, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/conversations`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const getTicketConversation = async (ticketId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/conversations`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchTicketConversation = async (ticketId, id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/conversations/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

//#endregion

//#region "MEMBERS"

export const postTicketMember = async (ticketId, userId, role) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members/${userId}`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ role }),
    })
  );
};

export const getTicketMembers = async (ticketId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchTicketMembers = async (ticketId, userId, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members/${userId}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const deleteTicketMember = async (ticketId, userId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/members/${userId}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "CUSTOM FIELDS"

export const getTicketCustomFields = async (ticketId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/custom-fields`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const putTicketCustomField = async (ticketId, field, value) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${ticketId}/custom-fields/${encodeURIComponent(field)}`, {
      method: 'PUT',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ value }),
    })
  );
};

//#endregion

//#region "STATS"

export const getMyTicketsGraph = async (type) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/my-graph?type=${type}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "S3Uploader"

export const postUploadSession = async (id, body) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/files`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );
};

export const getFiles = async (id, fileName = null) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/files${fileName ? `?fileName=${fileName}` : ''}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const deleteFile = async (id, fileName) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/tickets/${id}/files?fileName=${encodeURIComponent(fileName)}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region "ADMIN"

export const postAdminServices = async (name, ticket_no_prefix) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, ticket_no_prefix }),
    })
  );
};

export const getAdminServices = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminService = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminServiceTenants = async (serviceId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const putAdminServiceTenant = async (serviceId, tenantId, isMaster) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants/${tenantId}`, {
      method: 'PUT',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ isMaster }),
    })
  );
};

export const deleteAdminServiceTenant = async (serviceId, tenantId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants/${tenantId}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminServiceTenantProp = async (serviceId, tenantId, propName) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants/${tenantId}/props/${propName}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const putAdminServiceTenantProp = async (serviceId, tenantId, propName, propValue) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${serviceId}/tenants/${tenantId}/props`, {
      method: 'PUT',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ [propName]: propValue }),
    })
  );

export const getAdminServicePropList = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/config/service-props`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminServiceProps = async (id, propName, include_schema = undefined) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${id}/props/${propName}${objToQueryStringParams({ include_schema })}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const putAdminServiceProps = async (id, propName, data) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/admin/services/${id}/props/${propName}`, {
      method: 'PUT',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(
        data?.map((x) => {
          return { ...x, key: undefined };
        })
      ),
    })
  );
};

//#endregion

//#region "DASHBOARD"

export const getDashboardsTickets = async (from, to, services) => {
  const queryStringParams = objToQueryStringParams({ from, to, services });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/teacket/dashboards/tickets${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

// export const dashboardGetUserTickets = async (userId) => {
//   const headers = await getAccessHeaders();
//   return handleResponse(
//     await fetch(`${ENV.apiUrl}/tickets/dashboard/users/${userId}`, {
//       method: 'GET',
//       headers: { ...headers, 'Content-Type': 'application/json' },
//     })
//   );
// };

// export const getTicketHistory = async (ticketId) => {
//   const headers = await getAccessHeaders();
//   return handleResponse(
//     await fetch(`${ENV.apiUrl}/tickets/${ticketId}/history`, {
//       method: 'GET',
//       headers: { ...headers, 'Content-Type': 'application/json' },
//     })
//   );
// };

// export const getConversationHistory = async (id) => {
//   await sleepMs(3000);
//   return [
//     {
//       id: '1',
//       text: 'Good Morning',
//       createdAt: '2021-10-22',
//       userId: '1',
//       visibility: 'External', // External, Internal
//     },
//     {
//       id: '2',
//       text: 'Whats up?',
//       createdAt: '2021-11-22',
//       userId: '2',
//       visibility: 'External', // External, Internal
//     },
//     {
//       id: '3',
//       text: 'HELLO',
//       createdAt: '2021-12-22',
//       userId: '2',
//       visibility: 'External', // External Internal
//     },
//     {
//       id: '4',
//       text: 'HELLO',
//       createdAt: '2022-10-22',
//       userId: '1',
//       visibility: 'External', // External, Internal
//     },
//     {
//       id: '5',
//       text: 'HELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONGHELLOLONG',
//       createdAt: '2023-10-22',
//       userId: '1',
//       visibility: 'External', // External, Internal
//     },
//   ];
//   // const access_token = await getAccessToken();
//   // if (!access_token) throw new Error('No access token');
//   // return handleResponse(
//   //   await fetch(`${ENV.apiUrl}/tickets/${id}`, {
//   //     method: 'GET',
//   //     headers: {
//   //       'Content-Type': 'application/json',
//   //       token: access_token,
//   //     },
//   //   })
//   // );
// };
