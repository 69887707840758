import React, { useState, useEffect } from 'react';
import useTheme from 'src/hooks/useTheme';
import { Table, Space, Input, Spin, Button, message, Typography, Tag } from 'antd';
import { searchPermissions, postPermission, patchPermission, deletePermission } from 'src/api/accesscontrol';
import TitleBar from 'src/components/layout/TitleBar';
import StyledButton from 'src/components/layout/StyledButton';
import { FaPlus } from 'react-icons/fa6';
import PermissionCreate from './PermissionCreate';
import { SearchOutlined } from '@ant-design/icons';
import { notEmptyArray } from 'src/misc/Misc';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { FaRegTrashCan } from 'react-icons/fa6';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { MdDelete } from 'react-icons/md';
import { uuid } from 'short-uuid';

const { Paragraph } = Typography;

var permissionsCurrentPage = 1;

const Permissions = () => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'Permission',
      key: 'permission',
      width: 320,
      render: (record) => (
        <>
          {record.permission} {record.justCreated && <Tag color='blue'>just created</Tag>}
        </>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      render: (record) => (
        <>
          <Paragraph
            className='font-extralight'
            style={{ padding: 0, margin: 0 }}
            editable={{
              onChange: async (description) => {
                if (description === record.description) return;
                if (description.length < 1) {
                  messageApi.open({
                    key: uuid(),
                    type: 'error',
                    content: 'Description is required',
                    duration: 3,
                  });

                  return;
                }
                executeAsyncProcess(async () => {
                  try {
                    const resp = await patchPermission(record.permission, { description });
                    const p = [...permissions];
                    const i = p.findIndex((x) => x.permission === record.permission);
                    p[i] = resp;
                    setPermissions(p);
                    messageApi.open({
                      key: uuid(),
                      type: 'success',
                      content: 'Permission has been updated',
                      duration: 3,
                    });
                  } catch (error) {
                    console.log(error);
                  }
                });
              },
            }}
          >
            {record.description}
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <YesNoDialog
            title='Delete'
            body={
              <>
                Do you want to delete permission <strong>{record.permission}</strong>?
              </>
            }
            onYesClick={() => {
              handlePernamentlyDelete(record.permission);
            }}
            iconYes={<MdDelete />}
            showRed={true}
            labelYes='Yes, delete permission'
            labelNo='Cancel'
          >
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
              <FaRegTrashCan />
            </Button>
          </YesNoDialog>
        </Space>
      ),
    },
  ];

  const [permissions, setPermissions] = useState(null);
  const [newOpen, setNewOpen] = useState(false);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(10);
  const [thereIsMore, setThereIsMore] = useState(false);
  const [rerenderTable, setRerenderTable] = useState(false);

  // useEffect(() => {
  //   loadData();
  // }, []);

  useEffect(() => {
    permissionsCurrentPage = 1;
    setRerenderTable(true);
    setPermissions(null);
    setThereIsMore(false);
    loadData();
  }, [query, pageSize]);

  useEffect(() => {
    if (rerenderTable) setRerenderTable(false);
  }, [rerenderTable]);

  const loadData = async (loadMore = false) => {
    setLoading(true);
    try {
      const q = query?.trim() ?? null;
      const resp = await searchPermissions(q?.length > 0 ? q : null, permissionsCurrentPage, pageSize);
      permissionsCurrentPage++;
      setThereIsMore(resp?.length === pageSize ? true : false);
      if (true === notEmptyArray(resp)) {
        if (loadMore) setPermissions([...permissions, ...resp]);
        else setPermissions(resp);
      }
    } catch (error) {
      if (error?.toString().includes('The user aborted a request')) return;
      console.log(error);
    }
    setLoading(false);
  };

  const handlePermissionCreateOnSubmit = async (values) => {
    console.log('handleTenantCreateOnSubmit', values);
    setNewOpen(false);
    executeAsyncProcess(async () => {
      try {
        const resp = await postPermission(values);
        const p = true === notEmptyArray(permissions) ? [...permissions] : [];
        p.unshift({ ...resp, justCreated: true });
        setPermissions(p);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'New permission has been created',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handlePernamentlyDelete = async (permission) => {
    console.log('handlePernamentlyDelete', permission);
    executeAsyncProcess(async () => {
      try {
        await deletePermission(permission);
        const o = permissions.filter((x) => x.permission !== permission);
        setPermissions(o);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Permission has been deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <>
      <PermissionCreate open={newOpen} onClose={() => setNewOpen(false)} onSubmit={handlePermissionCreateOnSubmit} />
      {contextHolder}
      <TitleBar
        isSticky={false}
        busyIndicator={false}
        isLoading={loading}
        colorBackground={theme.backgroundBase}
        title={
          <Space>
            <StyledButton color={theme.textLight} onClick={() => setNewOpen(true)} size='small'>
              <Space className='btn'>
                <FaPlus />
                <WhenDesktop>Create permission</WhenDesktop>
              </Space>
            </StyledButton>
          </Space>
        }
      >
        <Input
          loading
          style={{ color: theme.textLight, borderColor: theme.textLight }}
          className='w-44 lg:w-60'
          placeholder='Search...'
          prefix={loading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
          allowClear
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </TitleBar>
      {!rerenderTable && (
        <Table
          loading={thereIsMore && loading}
          pagination={{
            pageSize,
            total: thereIsMore ? permissions?.length + 1 : permissions?.length ?? 0,
            onChange: (page, pageSize) => (thereIsMore && page > permissions?.length / pageSize ? loadData(true) : null),
          }}
          style={{ marginTop: '1px' }}
          size='small'
          columns={columns}
          dataSource={permissions}
          rowKey={'permission'}
        />
      )}
    </>
  );
};

Permissions.propTypes = {};

export default Permissions;
