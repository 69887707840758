import React, { useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Space } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from '../Misc/misc';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import DashboardFilters from './components/DashboardFilters';
import DashboardData from './components/DashboardData';

//import PropTypes from 'prop-types';

const Dashboard = () => {
  const { dashboardLayoutItemsSelector } = useContext(TeacketContext);

  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Dashboard',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.stats.view']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space className='btn'>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space>
          }
          afterTitleExtras={
            <div>
              <PageSwitch pageList={pageList} />
            </div>
          }
        >
          {dashboardLayoutItemsSelector}
        </TitleBar>
        <div className='p-4 flex flex-col gap-4'>
          <div className='p-4 rounded-lg bg-blue-300/50 dark:bg-sky-700/50'>
            <DashboardFilters />
          </div>
          <DashboardData />
        </div>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

export default Dashboard;
