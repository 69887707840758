import React from 'react';
import BoxType1 from './BoxType1';
import { Space } from 'antd';
import { ReactComponent as WelcomeSvg } from 'src/components/svg/spc-welcome.svg';
import CorporateColors from 'src/misc/CorporateColors';

const SectionNoPermissions = () => {
  return (
    <BoxType1 closable={true}>
      <div className='flex flex-row gap-8'>
        <div>
          <WelcomeSvg className='h-[300px]' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.aqua})` }} />
        </div>
        <div className='flex flex-col justify-center'>
          <div>
            <div className='text-3xl font-bold'>
              <Space className='btn'>Welcome to SPC Center</Space>
            </div>

            <p>The administrator has not yet assigned you any permissions.</p>
            <p>
              {/* Please contact to <a href={`mailto:spc.portal@genesys.com?subject=SPC permissions request for ${userInfo?.name ?? 'name'} ( ${userInfo?.id ?? 'id'} / ${userInfo?.tenant_id ?? 'tenant_id'} )`}>spc.portal@genesys.com</a> */}
              Please contact your administrator.
            </p>
          </div>
        </div>
      </div>
    </BoxType1>
  );
};

SectionNoPermissions.propTypes = {};

export default SectionNoPermissions;
