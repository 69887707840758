import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';
//import PropTypes from 'prop-types';

const StyledSkeleton = styled.div`
  .ant-skeleton.ant-skeleton-element {
    width: 100% !important;
  }

  .ant-skeleton-input {
    width: 100% !important;
  }
`;

const SkeletonTable = () => {
  return (
    <StyledSkeleton>
      <div className='flex flex-col gap-1 mt-1 items-center'>
        <Skeleton.Input active size={24} />
        <Skeleton.Input active size={18} />
        <Skeleton.Input active size={18} />
        <Skeleton.Input active size={18} />
        <Skeleton.Input active size={18} />
        <Skeleton.Input active size={18} />
      </div>
    </StyledSkeleton>
  );
};

SkeletonTable.propTypes = {};

export default SkeletonTable;
