import React from 'react';
import BoxType1 from './BoxType1';
import { Space } from 'antd';
import { ReactComponent as WelcomeSvg } from 'src/components/svg/spc-welcome.svg';
import CorporateColors from 'src/misc/CorporateColors';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';

const SectionWelcome = () => {
  const handleOnClose = () => {
    console.log('handleOnClose()');
    localStorage.setItem(LOCAL_STORAGE_KEYS.showWelcome, 'false');
  };

  return (
    <BoxType1 closable={true} onClose={handleOnClose} customStyling='bg-gradient-to-r from-blue-700 to-purple-700'>
      <div className='flex flex-row mt-4 gap-8 p-6 '>
        <div>
          <WelcomeSvg className='h-[200px] transition-transform duration-300 hover:scale-105' style={{ filter: `drop-shadow(0px 0px 15px ${CorporateColors.aqua})` }} />
        </div>
        <div className='flex flex-col justify-center text-white'>
          <div>
            <div className='text-4xl font-extrabold mb-4'>
              <Space className='btn'>Welcome to the SPC Center</Space>
            </div>

            <p className='text-lg mb-2'>A gateway to Genesys Cloud security, privacy, and compliance experience.</p>
            <p className='text-lg mb-2'>Created by the Genesys COE Security, Privacy & Compliance team, our portal is designed to empower you with the resources and tools you need to navigate the complex landscape of security and compliance with confidence.</p>
            <p className='text-lg mb-2'>Here, you'll find a comprehensive collection of documents and cutting-edge tools tailored to enhance your Genesys Cloud journey.</p>
            <p className='text-lg mb-2'>
              Whether you're leveraging the <strong className='text-yellow-300'>Security Advisor</strong> to proactively scan your organization for potential security vulnerabilities, our portal is your trusted partner in safeguarding your digital environment.
            </p>
            <p className='text-lg mb-2'>
              Explore the <strong className='text-yellow-300'>Audit Viewer</strong> to gain insights from your audit logs, or manage GDPR data subject requests seamlessly with our intuitive <strong className='text-yellow-300'>Data Subject Requests</strong> interface.
            </p>
            <p className='text-lg mb-2'>Our suite of tools is crafted to support your compliance needs, ensuring you stay ahead in a rapidly evolving digital world. We are thrilled to have you on board and are committed to providing you with a modern, user-friendly experience that reflects the innovative spirit of a startup.</p>
            <p className='text-lg'>Welcome to the future of security, privacy, and compliance with Genesys Cloud. Scroll down to find out more.</p>
          </div>
        </div>
      </div>
    </BoxType1>
  );
};

SectionWelcome.propTypes = {};

export default SectionWelcome;
