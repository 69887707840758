import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Space, Spin, Dropdown } from 'antd';
import { searchUsers } from 'src/api/users';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { notEmptyArray } from 'src/misc/Misc';
import UserBadge from './UserBadge';
import UserBadgeSkeleton from './UserBadgeSkeleton';
import { TbUsersGroup } from 'react-icons/tb';
import useMasterAdmin from 'src/hooks/useMasterAdmin';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import { FaChevronDown } from 'react-icons/fa';

var usersCurrentPage = 1;

const UserSelector = ({ selected, onUserSearch, onUserSelected, userId }) => {
  const navigate = useNavigate();

  const items = [
    {
      key: 10,
      label: <>10</>,
    },
    {
      key: 20,
      label: <>20</>,
    },
    {
      key: 30,
      label: <>30</>,
    },
  ];

  const { users, setUsers } = useMasterAdmin();

  const [query, setQuery] = useState(userId ?? null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(parseInt(items[0].key));
  const [thereIsMore, setThereIsMore] = useState(false);

  useEffect(() => {
    console.log('users', users);
  }, [users]);

  useEffect(() => {
    usersCurrentPage = 1;
    setUsers(null);
    setThereIsMore(false);
    loadData();
  }, [query, pageSize]);

  const loadData = async (loadMore = false) => {
    setLoading(true);
    if (onUserSearch) onUserSearch(query);
    try {
      const q = query?.trim() ?? null;
      const resp = await searchUsers(q?.length > 0 ? q : null, usersCurrentPage, pageSize);
      usersCurrentPage++;
      setThereIsMore(resp?.length === pageSize ? true : false);
      if (true === notEmptyArray(resp)) {
        if (loadMore) setUsers([...users, ...resp]);
        else setUsers(resp);
      }
      if (userId) {
        onUserSelected(userId);
      }
    } catch (error) {
      if (error?.toString().includes('The user aborted a request')) return;
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <DraggableLayoutItem
      title='User list'
      titleExtras={
        <Space>
          {!userId && (
            <div>
              <Dropdown
                trigger={['click']}
                menu={{
                  activeKey: pageSize,
                  items,
                  onClick: (e) => {
                    console.log(e);
                    setPageSize(parseInt(e.key));
                  },
                }}
                className='text-xs cursor-pointer'
              >
                <Space className='opacity-40 hover:opacity-100 btn'>
                  <span>page size {pageSize}</span>
                  <FaChevronDown />
                </Space>
              </Dropdown>
            </div>
          )}
          <Input
            size='small'
            disabled={userId && loading}
            className='w-36'
            placeholder='Search...'
            prefix={loading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
            allowClear
            value={query}
            onChange={(e) => {
              if (userId) navigate('/master-admin/users');
              setQuery(e.target.value);
            }}
          />
        </Space>
      }
    >
      <div className='flex flex-col justify-start items-center'>
        <div className='flex flex-row gap-4 p-4 flex-wrap max-w-2xl content-center justify-center'>
          {users?.map((u) => (
            <UserBadge
              key={u.user_id}
              id={u.user_id}
              name={u.user_name}
              tenantName={u.tenant_name}
              email={u.user_email}
              picture={u.user_pic}
              onClick={(userId) => {
                if (onUserSelected) onUserSelected(userId);
              }}
              isSelected={selected === u.user_id}
              isDisabled={u.user_disabled}
              newCreated={u.newCreated}
            />
          ))}
          {loading && (
            <>
              <UserBadgeSkeleton />
              <UserBadgeSkeleton />
              <UserBadgeSkeleton />
              <UserBadgeSkeleton />
              <UserBadgeSkeleton />
            </>
          )}
        </div>
        {!loading && thereIsMore && (
          <div>
            <Button
              onClick={() => {
                loadData(true);
              }}
            >
              <Space className='btn'>
                <TbUsersGroup />
                Load more
              </Space>
            </Button>
          </div>
        )}
      </div>
    </DraggableLayoutItem>
  );
};

UserSelector.propTypes = {
  selected: PropTypes.string,
  onUserSearch: PropTypes.func,
  onUserSelected: PropTypes.func,
  userId: PropTypes.string,
};

export default UserSelector;
