import { objToQueryStringParams } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

var controller;

export const getAccountsByName = async (name, limit, onlyWithOrganization = false) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts?name=${name}&limit=${limit || 50}&onlyWithOrganization=${onlyWithOrganization}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getOpportunititesByName = async (name, active, limit) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/opportunities?name=${name}&active=${active || false}&limit=${limit || 50}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getOpportunityDSR = async (id) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/opportunities/${id}/dsr`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getAccOrgs = async (accId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts/${accId}/organizations`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAccountForOrgId = async (deployments) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/organization/account${objToQueryStringParams({ deployments })}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAccountInformation = async (acc_id) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts/${acc_id}/information`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getAccountDeployments = async (acc_id) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts/${acc_id}/deployments`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getOrgProductLine = async (orgId) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/organizations/${orgId}/product-line`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getDeployments = async (query, limit = 25, type, search) => {
  controller?.abort('cancelled');
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/deployments${objToQueryStringParams({ query, limit, type, search })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};
