import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import PropTypes from 'prop-types';
import { notArrayOrEmpty } from 'src/misc/Misc';
import useTheme from 'src/hooks/useTheme';

const TicketPriorityColor = ({ serviceId, priority, size = '10px' }) => {
  const { getServicePropsForDerviceId } = useContext(TeacketContext);
  const { theme } = useTheme();

  const [priorityColor, setPriorityColor] = useState('black');

  useEffect(() => {
    const priorityList = getServicePropsForDerviceId(serviceId, 'priorityList');
    if (true === notArrayOrEmpty(priorityList)) return;
    const priorityConfig = priorityList.find((x) => x.priority === priority);
    if (!priorityConfig) return;
    setPriorityColor(priorityConfig.color);
  }, [serviceId, priority]);

  return <div style={{ height: size, width: size, borderRadius: size, backgroundColor: priorityColor, border: `1px solid ${theme.light}` }}></div>;
};

TicketPriorityColor.propTypes = {
  serviceId: PropTypes.string,
  priority: PropTypes.string,
  size: PropTypes.string,
};

export default TicketPriorityColor;
