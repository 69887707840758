import React, { useState, useContext } from 'react';
import useScreen from 'src/hooks/useScreen';
import { useNavigate } from 'react-router-dom';
import useUserInfo from 'src/hooks/useUserInfo';
import PropTypes from 'prop-types';
import { HomeOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { MdDashboard, MdManageAccounts, MdSecurity } from 'react-icons/md';
import { BsPersonRaisedHand } from 'react-icons/bs';
import { BsRobot } from 'react-icons/bs';
import { Layout, Menu, Button, theme, Breadcrumb } from 'antd';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useTheme from 'src/hooks/useTheme';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import AppLogo from './AppLogo';
import { AiOutlineAudit } from 'react-icons/ai';
import { BsCupHotFill } from 'react-icons/bs';
import { TbReportMoney } from 'react-icons/tb';
import GlobalContext from 'src/context/GlobalContext';
import TokenRefreshBanner from './TokenRefreshBanner';

const { Header, Sider } = Layout;

const AppLayout = ({ children, headerExtras }) => {
  const { permissions: userPermissions } = useUserInfo();
  const globalContext = useContext(GlobalContext);
  const { isMobile } = useScreen();

  const getDefaultKey = () => {
    const { pathname } = window.location;
    const path = pathname.split('/');
    const key = `/${path[1]}`;
    return key;
  };

  const [collapsed, setCollapsed] = useState(false);
  const { breadcrumbItems } = useBreadcrumb();
  const navigate = useNavigate();
  const { isBusy } = useAsyncProcesses();
  const { isDarkMode } = useTheme();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const breadcrumbItemRender = (route) => {
    if (!route.path) return <div className='font-light'>{route.title}</div>;
    return (
      <div
        className='font-light cursor-pointer'
        onClick={() => {
          navigate(route.path);
        }}
      >
        {route.title}
      </div>
    );
  };

  const handleMenuOnClick = (e) => {
    console.log('handleMenuOnCklick()', e);
    navigate(e.key);
  };

  return (
    <Layout style={{ height: '100vh' }} className={isBusy ? 'disabled' : null}>
      {/* Sider */}
      {!globalContext.thinMode && (
        <Sider collapsedWidth={isMobile ? 0 : undefined} trigger={null} collapsible collapsed={collapsed} theme={isDarkMode ? 'dark' : 'light'}>
          <div className='flex flex-col h-full'>
            <div className='h-16 min-h-[4rem] border-b flex flex-row justify-center items-center gap-2' style={{ borderRight: `0.5px solid ${isDarkMode ? '#222222' : '#eeeeee'}` }}>
              <AppLogo minimal={collapsed} />
            </div>
            <Menu
              theme={isDarkMode ? 'dark' : 'light'}
              mode='inline'
              selectedKeys={getDefaultKey()}
              onClick={handleMenuOnClick}
              items={[
                {
                  key: '/home',
                  icon: <HomeOutlined />,
                  label: 'Home',
                },
                {
                  key: '/COEAIAssistant',
                  icon: <BsRobot />,
                  label: 'COE AI Assistant',
                  permissions: ['apps.copilot.view'],
                },
                {
                  key: '/security-advisor',
                  icon: <MdSecurity />,
                  label: 'Security Advisor',
                  permissions: ['apps.securityadvisor.view'],
                },
                {
                  key: '/data-subject-requests',
                  icon: <BsPersonRaisedHand />,
                  label: 'Subject Requests',
                  permissions: ['apps.datasubjectrequests.view'],
                },
                {
                  key: '/audit-viewer',
                  icon: <AiOutlineAudit />,
                  label: 'Audit Viewer',
                  permissions: ['apps.auditviewer.view'],
                },
                {
                  key: '/teacket',
                  icon: <BsCupHotFill />,
                  label: 'Green Teacket',
                  permissions: ['apps.teacket.view'],
                },
                {
                  key: '/fsi-report',
                  icon: <TbReportMoney />,
                  label: 'FSI Report',
                  permissions: ['apps.fsireport.view'],
                },
                {
                  key: '/dashboards',
                  icon: <MdDashboard />,
                  label: 'Dashboards',
                  permissions: ['apps.dashboards.view'],
                },
                {
                  key: '/master-admin',
                  icon: <MdManageAccounts />,
                  label: 'Admin',
                  permissions: ['admin.master', 'admin.tenant'],
                },
              ].filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))}
            />
            <div className='flex-auto flex flex-col justify-end items-end overflow-hidden'>
              <img src='/images/misc/threads-1.png' alt='Threads' className='w-[200px] opacity-20' />
            </div>
          </div>
        </Sider>
      )}
      <Layout id='layout-root-container' style={{ overflow: 'auto' }}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className='flex flex-row'>
            <div className='flex-initial flex flex-row justify-start items-center'>
              {!globalContext.thinMode ? (
                <Button
                  type='text'
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                  }}
                />
              ) : (
                // Need a bit of space to make the thin mode look better
                <span className='ml-5' />
              )}
              <Breadcrumb items={breadcrumbItems} itemRender={breadcrumbItemRender} />
            </div>
            <div className='flex-auto flex flex-row gap-1 justify-end items-<center> px-4'>{headerExtras}</div>
          </div>
        </Header>
        <TokenRefreshBanner />
        {children}
      </Layout>
    </Layout>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
  headerExtras: PropTypes.node,
};

export default AppLayout;
