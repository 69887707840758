import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as GenesysLogo } from 'src/components/svg/genesys-logo.svg';
import { Space, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import DarkModeSwitch from 'src/components/layout/DarkModeSwitch';
import { getInvitationLink } from 'src/api/accesscontrol';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { loginGenesysCloud } from 'src/misc/Session';
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS, gcRegionList } from 'src/misc/Config';
import dayjs from 'dayjs';
import useUserInfo from 'src/hooks/useUserInfo';
import { logout } from 'src/misc/Session';

//import PropTypes from 'prop-types';

const WelcomeToSpcPortal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [invitationLink, setInvitationLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const userInfo = useUserInfo();

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.invitationLinkId);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.authTargetPath);
    if (userInfo) {
      logout(null, window.location.pathname);
      return;
    }
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await getInvitationLink(id);
      console.log(resp);

      // <validate dates>
      if (resp.used_at) navigate('/410');
      if (dayjs().isAfter(dayjs(resp.expires_at))) navigate('/410');
      // </validate dates>

      setInvitationLink(resp);
      localStorage.setItem(LOCAL_STORAGE_KEYS.invitationLinkId, resp.id);
      localStorage.setItem(LOCAL_STORAGE_KEYS.selectedGcRegion, resp.env);
    } catch (error) {
      console.log(error);
      navigate('/404');
    }
    setLoading(false);
  };

  if (loading || !invitationLink) return <FullScreenSpin title='Please wait' />;

  return (
    <div className='absolute w-[100vw] h-[100vh] overflow-hidden flex flex-row justify-center items-center bg-gradient-to-b to-stone-50 from-sky-100 dark:to-black dark:from-sky-950'>
      <div className='fixed top-4 right-4 z-50'>
        <DarkModeSwitch />
      </div>
      <div className='absolute w-full h-full xl:w-[900px] xl:h-[400px] xl:rounded-2xl xl:p-4 bg-white dark:bg-black shadow-xl shadow-shadow-stone-300 dark:shadow-sky-950'>
        <div className='flex flex-col xl:flex-row gap-12 h-full'>
          <div className='h-1/2 xl:h-full w-fill xl:w-1/2 flex flex-col items-center justify-end xl:items-end xl:justify-center'>
            <GenesysLogo style={{ width: '40px', height: '60px' }} className='text-genesys-warmred-base' />
            <div className='text-2xl my-2'>
              <span className='font-bold'>Welcome!</span>
            </div>
            <div className='font-thin mb-6'>
              <span className='font-light opacity-60'>You have been invited to the SPC Center</span>
            </div>
            <div className='font-thin mb-2'>
              <span className='font-light opacity-60'>Please use your</span> <span className='font-bold opacity-100'>Genesys Cloud</span> <span className='font-light opacity-60'>credentials to sign in:</span>
            </div>
            <div>
              <Space>
                <span className='font-thin opacity-60'>Email:</span>
                <span className='font-bold'>{invitationLink.email}</span>
              </Space>
            </div>
            <div>
              <Space>
                <span className='font-thin opacity-60'>Password:</span>
                <span className='font-bold'>Use your own password</span>
              </Space>
            </div>
            <div>
              <Space>
                <span className='font-thin opacity-60'>Organization:</span>
                <span className='font-bold'>{invitationLink.tenant_name}</span>
              </Space>
            </div>
            <div>
              <Space>
                <span className='font-thin opacity-60'>Region:</span>
                <span className='font-bold'>{invitationLink.env}</span>
              </Space>
            </div>
            <div className='mt-4'>
              <Button
                style={{ backgroundColor: '#ff4f1f', borderColor: '#ff4f1f' }}
                type='primary'
                size='large'
                shape='round'
                disabled={false}
                className='hover:scale-105 transition-transform duration-300 ease-in-out group font-bold'
                onClick={() => {
                  const gcRegion = gcRegionList.find((region) => region.baseUrl === invitationLink.env);
                  loginGenesysCloud(gcRegion.baseUrl, gcRegion.clientId);
                }}>
                Sign in
                <ArrowRightOutlined />
              </Button>
            </div>
          </div>
          <div className='h-1/2 xl:h-full w-fill xl:w-1/2 flex flex-col items-center justify-start xl:items-start xl:justify-center'>
            <img src='/images/landing/21743724_6505026.svg' alt='SPC' style={{ maxHeight: '60%' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

// WelcomeToSpcPortal.propTypes = {};

export default WelcomeToSpcPortal;
