import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserInfo from 'src/hooks/useUserInfo';
import { DEFAULT_CALLBACK_URL_SIGNED_IN } from 'src/misc/Config';
import SignIn from 'src/components/controls/SignIn/SignIn';
//import DarkModeSwitch from 'src/components/layout/DarkModeSwitch';
import Header from 'src/components/pages/Landing/components/ui/Header';

//import PropTypes from 'prop-types';

const SignInPage = () => {
  const navigate = useNavigate();
  const userInfo = useUserInfo();

  useEffect(() => {
    if (userInfo) {
      navigate(DEFAULT_CALLBACK_URL_SIGNED_IN);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <>
      {/* <div className='z-0 bg-genesys-arctic-50 dark:bg-genesys-arctic-950' style={{ position: 'fixed', top: '0', right: '0', bottom: '0', left: '0', backgroundImage: 'url(/images/misc/threads-2.png)', backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat' }}></div> */}
      <div className='z-0 bg-genesys-arctic-50 dark:bg-genesys-arctic-950' style={{ position: 'fixed', top: '0', right: '0', bottom: '0', left: '0' }}></div>
      <Header hideSignInBtn={true} />
      <div className='z-10 relative w-full min-h-[100vh] flex flex-col items-center justify-center'>
        <div className='h-12' />
        <SignIn />
        <div className='h-12' />
      </div>
    </>
  );
};

SignInPage.propTypes = {};

export default SignInPage;
