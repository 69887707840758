import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BarLoader from 'react-bar-loader';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useTheme from 'src/hooks/useTheme';
import GlobalContext from 'src/context/GlobalContext';

const TitleBar = ({ isSticky, title, afterTitleExtras, children, busyIndicator, colorBackground, colorForeground, colorLoaderBackground, colorLoaderForeground, isLoading }) => {
  const { isBusy } = useAsyncProcesses();
  const { theme } = useTheme();
  const { tokenExpiring } = useContext(GlobalContext);

  if (!colorBackground) colorBackground = theme.backgroundMedium;
  if (!colorForeground) colorForeground = theme.textBase;
  if (!colorLoaderBackground) colorLoaderBackground = theme.light;
  if (!colorLoaderForeground) colorLoaderForeground = theme.primary;

  return (
    <div className={`${isSticky && 'sticky'} ${true === tokenExpiring ? 'top-[50px]' : 'top-0'} z-50 shadow-md ${isLoading ? 'disabled' : null}`} style={{ backgroundColor: colorBackground }}>
      <div className='flex flex-row py-1 px-4 items-center'>
        <div className='h-10 flex-initial flex flex-row justify-start items-center text-xl font-bold' style={{ color: colorForeground, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {title}
          <div className='ml-4 flex flex-row gap-1 overflow-hidden'>{afterTitleExtras}</div>
        </div>
        <div className='h-10 flex-auto flex flex-row gap-1 justify-end items-center overflow-hidden'>{children}</div>
      </div>
      {(busyIndicator && isBusy) || isLoading ? <BarLoader color={colorLoaderForeground} backgroundColor={colorLoaderBackground} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: colorLoaderForeground }}></div>}
    </div>
  );
};

TitleBar.propTypes = {
  title: PropTypes.any,
  afterTitleExtras: PropTypes.any,
  children: PropTypes.node,
  busyIndicator: PropTypes.bool,
  colorBackground: PropTypes.string,
  colorForeground: PropTypes.string,
  colorLoaderBackground: PropTypes.string,
  colorLoaderForeground: PropTypes.string,
  isLoading: PropTypes.bool,
  isSticky: PropTypes.bool,
};

TitleBar.defaultProps = {
  title: 'No title',
  busyIndicator: true,
  isLoading: false,
  isSticky: true,
};

export default TitleBar;
