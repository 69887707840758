import React, { useContext, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import LoaderCurrentTicket from '../Loaders/LoaderCurrentTicket';
import TicketModal from './TicketModal';
import { Button, Drawer, message } from 'antd';
import { MdOutlineExitToApp, MdContentCopy } from 'react-icons/md';
import { Tooltip, Tag } from 'antd';
import { FaTimes } from 'react-icons/fa';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useTheme from 'src/hooks/useTheme';
import { uuid } from 'short-uuid';

import SubscribeForLiveEvents from './TicketSubscribeForNotifiactions';

//import PropTypes from 'prop-types';

const ItemDetails = () => {
  const { isDarkMode } = useTheme();
  const { ticketListSelectedTicket, setTicketListSelectedTicket, currentTicketReadOnly, myTicketsSelectedTicket, currentTicket, setMyTicketsLayoutItemsSelector, myTicketsLayoutItemsSelector } = useContext(TeacketContext);
  const { isBusy } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => { }, [myTicketsSelectedTicket]);

  const handleOnClose = () => setTicketListSelectedTicket(null);

  const handleCopyUrl = () => {
    console.log('handleCopyUrl()');
    navigator.clipboard.writeText(getTicketUrl());
    messageApi.open({
      key: uuid(),
      type: 'success',
      content: 'Copied',
      duration: 3,
    });
  };

  const handleOpenInNewTab = () => {
    console.log('handleOpenInNewTab()');
    window.open(getTicketUrl(), '_blank');
  };

  const getTicketUrl = () => `${window.location.origin}/teacket/tickets/${currentTicket.id}`;

  const startExtras = (
    <div className='flex flex-row items-center gap-4 text-xl'>
      {currentTicket && (
        <>
          <Tooltip title='Selected ticket ID' mouseEnterDelay={1}>
            <div className='font-light'>{currentTicket.id}</div>
          </Tooltip>
          <Tooltip title='Copy ticket URL' mouseEnterDelay={1}>
            <MdContentCopy className='hover:scale-110 cursor-pointer' onClick={handleCopyUrl} />
          </Tooltip>
          <Tooltip title='Open in a new tab' mouseEnterDelay={1}>
            <MdOutlineExitToApp className='hover:scale-110 cursor-pointer' onClick={handleOpenInNewTab} />
          </Tooltip>
          {currentTicket && currentTicketReadOnly && <Tag color='volcano'>Read only</Tag>}
        </>
      )}
    </div>
  );

  const endExtras = (
    <div className='flex flex-row items-center gap-2'>
      <SubscribeForLiveEvents />
      {myTicketsLayoutItemsSelector}
      <div>
        <Button onClick={handleOnClose} type='text' icon={<FaTimes />} />
      </div>
    </div>
  );

  return (
    <Drawer open={null !== ticketListSelectedTicket} width={'90%'} placement='right' closable={false} maskClosable={false} title={startExtras} extra={endExtras} styles={{ body: { backgroundColor: true === isDarkMode ? '#000000' : '#f0f0f0', padding: '12px' } }}>
      <div id='drawer-root-container' className={`${isBusy ? 'disabled' : ''} h-full overflow-auto`}>
        {contextHolder}
        {ticketListSelectedTicket && <LoaderCurrentTicket ticketId={ticketListSelectedTicket}>{currentTicket && <TicketModal setLayoutItemsSelector={setMyTicketsLayoutItemsSelector} rootComponentId='drawer-root-container' extraOffsetX={document.getElementById('drawer-root-container')?.getBoundingClientRect()?.left ? document.getElementById('drawer-root-container')?.getBoundingClientRect()?.left - 12 : 0} />}</LoaderCurrentTicket>}
      </div>
    </Drawer>
  );
};

ItemDetails.propTypes = {};

export default ItemDetails;
