import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import AiModelConnectorsModal from './AiModelConnectorsModal';
import AIModelsContext from 'src/context/AIModelsContext';
import { TbWorldDownload } from 'react-icons/tb';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftsharepoint } from 'react-icons/si';
import { MdOutlineQuestionMark } from 'react-icons/md';
import { ImLibrary } from 'react-icons/im';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* or any specific height you need */
  }

  .status {
    margin-top: auto;
  }
`;

const AiModelConnectorListCard = ({ aiModelId, connector }) => {
  const [connectorsModalOpen, setConnectorsModalOpen] = useState(false);

  const { selectedModel, updateConnectorDef, getConnectorNameById } = useContext(AIModelsContext);

  const disabledRibbon = (control, isDisabled) => {
    if (isDisabled)
      return (
        <Badge.Ribbon text='Disabled' color='red'>
          {control}
        </Badge.Ribbon>
      );
    return control;
  };

  const handleEditConnector = (values) => {
    console.log('[AiModelConnectorListCard] Success:', values);
    console.log('[AiModelConnectorListCard] SelectedModel:', selectedModel);

    updateConnectorDef(values.id, values);

    setConnectorsModalOpen(false);
  };

  const setPicture = (picId) => {
    // console.log('getConnectorNameById(picId):', getConnectorNameById(picId));
    switch (getConnectorNameById(picId)) {
      case 'Web':
        return <TbWorldDownload className='scale-125 text-green-400' />;
      case 'Genesys S3':
        return <FaAws className='scale-125 text-yellow-400' />;
      case 'Genesys Sharepoint':
        return <SiMicrosoftsharepoint className='scale-125 text-blue-400' />;
      case 'RFPIO':
        return <ImLibrary className='scale-125 text-blue-400' />;
      default:
        return <MdOutlineQuestionMark />;
    }
  };

  return (
    <StyledDiv>
      <div className={'container mt-4 hover:scale-105 hover:opacity-100 hover:shadow-md transition-all '}>
        {disabledRibbon(
          <div
            className={'flex flex-col gap-1 justify-start items-center cursor-pointer opacity-80 min-h-28 w-28 h-48 break-all px-2 py-4 dark:shadow-indigo-800  dark:border-indigo-900 border-solid border-2 rounded-md'}
            onClick={() => {
              setConnectorsModalOpen(true);
            }}
          >
            <div className='text-center'>{setPicture(connector.connector_id)}</div>
            <div className='pt-2 text-xs text-center overflow-hidden w-16'>{connector.name}</div>
            {/* <div className='flex-auto text-xs text-center'>{connector.description}</div> */}
            <div className='status'>
              <hr className='w-full text-gray-700' />
              <div className='text-gray-500 text-xs text-center'>Status: {connector?.aws_config?.enabled ? <span className='text-green-500'>ON</span> : <span className='text-red-500'>OFF</span>}</div>
            </div>
          </div>,
          connector.isDisabled
        )}
      </div>
      {connectorsModalOpen && <AiModelConnectorsModal aiModelId={aiModelId} connector={connector} open={connectorsModalOpen} onSubmit={handleEditConnector} onClose={() => setConnectorsModalOpen(false)} />}
    </StyledDiv>
  );
};

AiModelConnectorListCard.propTypes = {
  aiModelId: PropTypes.number.isRequired,
  connector: PropTypes.object.isRequired,
};

export default AiModelConnectorListCard;
