import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Tooltip } from 'antd';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import NoData from 'src/components/layout/NoData';

const ComplianceItem = ({ image, title, onClick, hasFiles }) => {
  const setPicture = (picId) => {
    const base64Image = 'data:image/png;base64,' + picId;
    return picId ? <img src={base64Image} alt='logo' className='w-16 h-16 object-contain' /> : <NoData label='' />;
  };

  return (
    <Tooltip title={'Click to view more'}>
      <div
        className={`flex flex-col justify-start gap-2 ${onClick && 'cursor-pointer hover:scale-105'}`}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <div className='w-full h-[64px] flex flex-row justify-center items-center'>{setPicture(image)}</div>

        <div className='break-words'>
          <Badge count={hasFiles ? <FaCloudDownloadAlt className='text-genesys-teal-base' /> : 0} offset={[6, 0]}>
            {title}
          </Badge>
        </div>
      </div>
    </Tooltip>
  );
};

ComplianceItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  hasFiles: PropTypes.bool,
};

export default ComplianceItem;
