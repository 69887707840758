import React, { useEffect, useState } from 'react';
import { Table, Spin, Button, Space, Tooltip } from 'antd';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { getCopilotUsageData2 } from 'src/api/securityqa';
import UserImage from 'src/components/layout/UserImage';
import { numberSorter, textSorterNested } from 'src/misc/Misc';
import { MdSupervisorAccount } from 'react-icons/md';
import { HiOutlineChip } from 'react-icons/hi';
import { CiUser } from 'react-icons/ci';
import { FaLayerGroup } from 'react-icons/fa';
import { IoCalendarOutline } from 'react-icons/io5';
import uuid from 'short-uuid';

//import dayjs from 'dayjs';

const CopilotUsage2 = () => {
  const { isDarkMode } = useTheme();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState('bySupervisor');
  const [activeDays, setActiveDays] = useState(30);
  const [days, setDays] = useState(30);

  //#region columnsDef

  const columnsDefBySupervisor = [
    {
      title: 'Supervisor',
      dataIndex: 'supervisor',
      key: 'supervisor',
      render: (record) =>
        record?.supervisor && (
          <Tooltip title={record?.supervisor}>
            <Space>
              <UserImage image={record?.supervisor_pic} size='26px' />
              {record?.supervisor || 'Unknown'}
            </Space>
          </Tooltip>
        ),
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (record) =>
        record?.user_name && (
          <Tooltip title={record?.user_name}>
            <Space>
              <UserImage image={record?.user_pic} size='26px' />
              {record?.user_name || 'Unknown'}
            </Space>
          </Tooltip>
        ),
    },
    {
      title: '#',
      dataIndex: 'count',
      key: 'count',
      width: 60,
      sorter: (a, b) => numberSorter(a, b, 'count'),
      defaultSortOrder: 'descend',
    },
  ];

  const columnsDefByModel = [
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (record) =>
        record?.model && (
          <Tooltip title={record?.user_name}>
            <Space>
              <UserImage image={record?.user_pic} size='26px' />
              {record?.user_name || 'Unknown'}
            </Space>
          </Tooltip>
        ),
    },
    {
      title: '#',
      dataIndex: 'count',
      key: 'count',
      width: 60,
      sorter: (a, b) => numberSorter(a, b, 'count'),
      defaultSortOrder: 'descend',
    },
  ];

  const columnsDefByUser = [
    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: (a, b) => textSorterNested(a, b, 'user_name', 'user_name'),
      render: (record) => (
        <Tooltip title={record?.user_name}>
          <Space>
            <UserImage image={record?.user_pic} size='26px' />
            {record?.user_name || 'Unknown'}
          </Space>
        </Tooltip>
      ),
    },
    {
      title: '#',
      dataIndex: 'count',
      key: 'count',
      width: 60,
      sorter: (a, b) => numberSorter(a, b, 'count'),
      defaultSortOrder: 'descend',
    },
  ];

  const columnsDefByDepartment = [
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'User Name',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (record) =>
        record?.department && (
          <Tooltip title={record?.user_name}>
            <Space>
              <UserImage image={record?.user_pic} size='26px' />
              {record?.user_name || 'Unknown'}
            </Space>
          </Tooltip>
        ),
    },
    {
      title: '#',
      dataIndex: 'count',
      key: 'count',
      width: 60,
      sorter: (a, b) => numberSorter(a, b, 'count'),
      defaultSortOrder: 'descend',
    },
  ];

  //#endregion

  const [columns, setColumns] = useState(columnsDefBySupervisor);

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const countConversations = (children) => {
    return children.reduce((acc, item) => {
      return acc + item.count;
    }, 0);
  };

  const generateModelsBySupervisor = (supervisor, uniqueModels, resp) => {
    try {
      let retArray = [];
      uniqueModels.forEach((model) => {
        retArray.push({
          key: uuid.generate(),
          model: model,
          count: countConversations(resp.filter((z) => z.supervisor === supervisor && z.model === model)),
          children: resp
            .filter((y) => y.model === model && y.supervisor === supervisor)
            .map((userLvl) => {
              return {
                key: uuid.generate(),
                user_name: userLvl?.user_name ? userLvl : { user_name: 'Unknown' },
                count: userLvl.count,
              };
            }),
        });
      });

      return retArray;
    } catch (error) {
      console.error(error);
    }
  };

  const loadDataBySupervisor = async () => {
    try {
      setLoading(true);
      setData([]);
      setColumns(columnsDefBySupervisor);
      setActiveButton('bySupervisor');
      const resp = await getCopilotUsageData2('supervisor,user_id,model_id', days);

      let myData = [];
      const supervisors = Array.from(new Set(resp.map((item) => item.supervisor)));
      console.log('@', supervisors);

      supervisors.forEach((supervisor) => {
        const uniqueModels = Array.from(new Set(resp.filter((item) => item.supervisor === supervisor).map((item) => item.model)));
        const modelsBySupervisor = generateModelsBySupervisor(supervisor, uniqueModels, resp);

        myData.push({
          key: uuid.generate(),
          supervisor: supervisor === 'unknown' ? { supervisor: 'Unknown' } : resp.find((item) => item.supervisor === supervisor),
          count: countConversations(resp.filter((item) => item.supervisor === supervisor)),
          children: modelsBySupervisor,
        });
      });

      setData(myData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadDataByModel = async () => {
    try {
      setLoading(true);
      setData([]);
      setColumns(columnsDefByModel);
      setActiveButton('byModel');
      const resp = await getCopilotUsageData2('user_id,model_id', days);

      let myData = [];
      const models = Array.from(new Set(resp.map((item) => item.model)));

      models.forEach((model) => {
        myData.push({
          key: uuid.generate(),
          model: model,
          count: countConversations(resp.filter((item) => item.model === model)),
          children: resp
            .filter((item) => item.model === model)
            .map((item) => {
              return {
                key: uuid.generate(),
                user_name: item,
                count: item.count,
              };
            }),
        });
      });

      setData(myData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadDataByUser = async () => {
    try {
      setLoading(true);
      setData([]);
      setColumns(columnsDefByUser);
      setActiveButton('byUser');
      const resp = await getCopilotUsageData2('user_id', days);

      let myData = [];
      resp.forEach((item) => {
        myData.push({
          key: uuid.generate(),
          user_name: item,
          count: item.count,
        });
      });

      setData(myData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const loadDataByDepartment = async () => {
    try {
      setLoading(true);
      setData([]);
      setColumns(columnsDefByDepartment);
      setActiveButton('byDepartment');
      const resp = await getCopilotUsageData2('department,user_id', days);

      let myData = [];
      const dep = Array.from(new Set(resp.map((item) => item.department)));

      dep.forEach((depart) => {
        myData.push({
          key: uuid.generate(),
          department: depart === 'unknown' ? 'Unknown' : depart,
          count: countConversations(resp.filter((item) => item.department === depart)),
          children: resp
            .filter((item) => item.department === depart)
            .map((item) => {
              return {
                key: uuid.generate(),
                user_name: item,
                count: item.count,
              };
            }),
        });
      });

      setData(myData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!data) loadDataBySupervisor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (activeButton) {
      case 'bySupervisor':
        loadDataBySupervisor();
        break;
      case 'byModel':
        loadDataByModel();
        break;
      case 'byDepartment':
        loadDataByDepartment();
        break;
      case 'byUser':
        loadDataByUser();
        break;
      default:
        loadDataBySupervisor();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <ThemeProvider theme={themeMode}>
      <div>
        {data === undefined ? (
          <div className='text-center'>
            <Spin />
          </div>
        ) : (
          <>
            <p className='text-right mr-4 mt-0'>
              <Space>
                <div className='ml-8 mb-1'>Last</div>
                <Button
                  size='small'
                  className={`${activeDays === 7 ? 'border-solid border-2 border-sky-500' : ''} w-10`}
                  onClick={() => {
                    setDays(7);
                    setActiveDays(7);
                  }}
                >
                  7
                </Button>
                <Button
                  size='small'
                  className={`${activeDays === 30 ? 'border-solid border-2 border-sky-500' : ''} w-10`}
                  onClick={() => {
                    setDays(30);
                    setActiveDays(30);
                  }}
                >
                  30
                </Button>
                <Button
                  size='small'
                  className={`${activeDays === 60 ? 'border-solid border-2 border-sky-500' : ''} w-10`}
                  onClick={() => {
                    setDays(60);
                    setActiveDays(60);
                  }}
                >
                  60
                </Button>
                <Button
                  size='small'
                  className={`${activeDays === 90 ? 'border-solid border-2 border-sky-500' : ''} w-10`}
                  onClick={() => {
                    setDays(90);
                    setActiveDays(90);
                  }}
                >
                  90
                </Button>

                <Space>
                  <IoCalendarOutline />
                  <div className='mb-1'>days</div>
                </Space>
              </Space>
            </p>
            <Space>
              <Button className={`${activeButton === 'bySupervisor' ? 'border-solid border-2 border-sky-500' : ''}`} onClick={loadDataBySupervisor}>
                <Space>
                  <MdSupervisorAccount />
                  <div className='mb-1'>By Supervisor</div>
                </Space>
              </Button>
              <Button className={`${activeButton === 'byModel' ? 'border-solid border-2 border-sky-500' : ''}`} onClick={loadDataByModel}>
                <Space>
                  <HiOutlineChip />
                  <div className='mb-1'>By Model</div>
                </Space>
              </Button>

              <Button className={`${activeButton === 'byDepartment' ? 'border-solid border-2 border-sky-500' : ''}`} onClick={loadDataByDepartment}>
                <Space>
                  <FaLayerGroup />
                  <div className='mb-1'>By Department</div>
                </Space>
              </Button>

              <Button className={`${activeButton === 'byUser' ? 'border-solid border-2 border-sky-500' : ''}`} onClick={loadDataByUser}>
                <Space>
                  <CiUser />
                  <div className='mb-1'>By User</div>
                </Space>
              </Button>
            </Space>
            <div className='p-4 h-full max-h-full w-full overflow-hidden'>
              <Table loading={loading} pagination={true} size='small' dataSource={data} columns={columns} />
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default CopilotUsage2;
