import { objToQueryStringParams } from 'src/misc/Misc';
import { handleResponse, getAccessToken, getAccessHeaders } from './common';
import { ENV, LOCAL_STORAGE_KEYS, IDENTITY_PROVIDERS } from 'src/misc/Config';

//#region "USER INFO"

export const getAuthUserInfo = async () => {
  // <get sign-in provider>
  const provider = localStorage.getItem(LOCAL_STORAGE_KEYS.authProvider);
  if (!provider) throw new Error('No auth provider');
  // </get sign-in provider>

  // <get access token>
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');
  // </get access token>

  // <set common headers>
  let headers = {
    'Content-Type': 'application/json',
    token: access_token,
    provider,
  };
  // </set common headers>

  // <set GC headers>
  if (IDENTITY_PROVIDERS.genesysCloud === provider) {
    const env = localStorage.getItem(LOCAL_STORAGE_KEYS.selectedGcRegion);
    if (!env) throw new Error('No Genesys Cloud region');
    headers = {
      ...headers,
      env,
    };
  }
  // </set GC headers>

  // <handle invitation links>
  const invitationLinkId = localStorage.getItem(LOCAL_STORAGE_KEYS.invitationLinkId);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.invitationLinkId);
  // </handle invitation links>

  const queryString = objToQueryStringParams({ invitationLinkId });

  return handleResponse(
    await fetch(`${ENV.apiUrl}/auth/userinfo${queryString}`, {
      method: 'GET',
      headers,
    })
  );
};

//#endregion

//#region "CLIENT CREDENTIALS"

export const getClientCredentialsConfig = async (appId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auth/clientcredentials/${appId}/config`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getClientCredentialsVerify = async (appId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auth/clientcredentials/${appId}/verify`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postClientCredentialsSetup = async (appId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auth/clientcredentials/${appId}/setup`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};
//#endregion

//#region "GC PERMISSIONS"

export const postGcPermissions = async (requiredPermissions) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/auth/gc/permissions`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(requiredPermissions),
    })
  );
};

//#endregion
