import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { searchTenants } from 'src/api/tenants';
import PropTypes from 'prop-types';

const { Option } = Select;

const TenantPicker = ({ width, onPick }) => {
  const [tenantList, setTenantList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    console.log('handleClear');
    setTenantList([]);
    onPick(null);
  };

  const handleSearchForTenants = async (v) => {
    setTenantList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await searchTenants(v);
      // console.log('Tenants:', resp);
      if (resp) {
        setTenantList(resp?.entities ?? []);
      }
      setLoading(false);
    } catch (error) {
      if (error?.toString().includes('The user aborted a request')) return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnSelect = (v) => {
    console.log('TenantPicker onSelect:', v);
    const tenant = tenantList.find((t) => t.id === v);
    onPick(tenant);
  };

  return (
    <Select showSearch allowClear placeholder='type to search tenant...' notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null} filterOption={false} onSearch={handleSearchForTenants} onClear={handleClear} onSelect={handleOnSelect} style={{ width }} autoComplete='off'>
      {tenantList.map((d) => (
        <Option key={d.id}>
          {d.name} <span className='text-[0.6rem] font-light opacity-60'>({d.id})</span>
        </Option>
      ))}
    </Select>
  );
};

TenantPicker.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
};

TenantPicker.defaultProps = {
  width: '220px',
};

export default TenantPicker;
