import React, { useState, useEffect, useContext } from 'react';
import DroppableWrapper from './DroppableWrapper';
import useTheme from 'src/hooks/useTheme';
import { Progress, Space, Popconfirm, Button, Tooltip, message } from 'antd';
import { FaRegTrashCan } from 'react-icons/fa6';
import TeacketContext from 'src/context/TeacketContext';
import { postUploadSession, getFiles, deleteFile } from 'src/api/teacket';
import CondensedTable from 'src/components/layout/CondensedTable';
import { InboxOutlined, WarningOutlined } from '@ant-design/icons';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import { uuid } from 'short-uuid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { notEmptyArray } from 'src/misc/Misc';

dayjs.extend(relativeTime);

const S3Uploader = () => {
  const { currentTicket, currentTicketS3FilesList, setCurrentTicketS3FilesList, currentTicketReadOnly, servicesForTenant } = useContext(TeacketContext);
  const { theme } = useTheme();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadMsg, setUploadMsg] = useState('Loading files...');

  const [progress, setProgress] = useState(0);
  const [closedStatus, setClosedStatus] = useState(false);

  let totalParts;
  let currentPart;

  useEffect(() => {
    console.log('s3Uploader initialized');
    loadFiles();
  }, []);

  useEffect(() => {
    const isClosed = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.find((s) => s.status === currentTicket?.status)?.isClosed;
    console.log('Check if status isClosed:', isClosed);
    setClosedStatus(isClosed);

  }, [currentTicket?.status]);

  const loadFiles = async () => {
    try {
      setLoading(true);
      let files = await getFiles(currentTicket.id);
      if (true === notEmptyArray(files)) files = files.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
      setCurrentTicketS3FilesList(files);
    } catch (error) {
      console.error('loadFiles', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteFileHandle = async (fileName) => {
    console.log('deleteFileHandle()', fileName);
    try {
      setLoading(true);
      await deleteFile(currentTicket.id, fileName);
      console.log('File deleted successfully.');
      setCurrentTicketS3FilesList(currentTicketS3FilesList.filter((file) => file.name !== fileName));
    } catch (error) {
      console.error('deleteFile', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDrop = async (e) => {
    console.log('handleDrop', e);
    if (e.length === 0) return;
    if (currentTicketReadOnly) {
      messageApi.open({
        key: uuid(),
        type: 'warning',
        content: 'The current ticket is in read-only mode, file uploads are not allowed.',
        duration: 5,
      });
      return;
    };
    if (closedStatus) {
      messageApi.open({
        key: uuid(),
        type: 'warning',
        content: 'The current ticket is closed, file uploads are not allowed.',
        duration: 5,
      });
      return;
    };
    if (uploading) {
      messageApi.open({
        key: uuid(),
        type: 'warning',
        content: 'Please wait for the current upload to complete before uploading another file.',
        duration: 5,
      });
      return;
    }
    setUploading(true);
    setLoading(true);
    setProgress(1);
    totalParts = 0;
    currentPart = 0;

    const promises = [];

    for (let i = 0; i < e.length; i++) {
      try {
        const file = e[i];
        promises.push(uploadFileInChunks(file));
      } catch (error) {
        console.error('handleDrop', error);
      }
    }

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error('Error in file upload', error);
    } finally {
      setUploading(false);
      setLoading(false);
      setUploadMsg('Upload completed.');
    }
  };

  const uploadFileInChunks = async (file) => {
    return new Promise((resolve, reject) => {
      try {
        const partSize = 50 * 1024 * 1024; // 50 MB
        const parts = [];

        const fileSize = file.size;
        let offset = 0;

        const readNextChunk = async () => {
          const reader = new FileReader();

          reader.onload = async (e) => {
            const result = e.target?.result;
            if (result) {
              parts.push(result);

              offset += partSize;
              if (offset < fileSize) {
                readNextChunk();
              } else {
                console.log('File divided into parts successfully.');
                console.log(`Total parts: ${parts.length}`);
                totalParts += parts.length;
                // Do something with the parts array

                // Upload parts to S3
                setUploadMsg('Initiate upload session...');
                const sessionResp = await postUploadSession(currentTicket.id, {
                  fileName: file.name,
                  parts: parts.length,
                });
                console.log('sessionResp', sessionResp);
                setUploadMsg('Uploading document(s)...');

                const eTags = [];
                for (let i = 0; i < parts.length; i++) {
                  currentPart += 1;
                  const eTag = await uploadPart(parts[i], sessionResp.signedUrls[i]);
                  eTags.push({ PartNumber: i + 1, ETag: eTag.slice(1, -1) });
                  setProgress(Math.round((currentPart / totalParts) * 100));
                  //setUploadMsg(`Uploading part ${currentPart} of ${totalParts}...`);
                }

                console.log('Upload completed. CompleteMultipartUpload with eTags.');
                console.log('eTags', eTags);
                if (currentPart === totalParts) {
                  setUploadMsg('Finalizing upload...');
                }

                const sessionCompleteResp = await postUploadSession(currentTicket.id, {
                  fileName: file.name,
                  uploadId: sessionResp.uploadId,
                  eTags,
                });
                console.log('sessionCompleteResp', sessionCompleteResp);

                if (currentPart === totalParts) loadFiles();
                resolve();
              }
            }
          };

          const chunk = file.slice(offset, offset + partSize);
          reader.readAsArrayBuffer(chunk);
        };

        readNextChunk();
      } catch (error) {
        reject(error);
      }
    });
  };

  const uploadPart = async (part, url) => {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: part,
    });

    if (!response.ok) {
      throw new Error(`Failed to upload part: ${response.statusText}`);
    }

    const eTag = response.headers.get('ETag');
    return eTag;
  };

  const downloadFileHandler = async (fileName) => {
    console.log('downloadFileHandler', fileName);
    try {
      const url = await getFiles(currentTicket.id, fileName);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.pdf');
      link.click();
    } catch (error) {
      console.error('downloadFileHandler', error);
    }
  };

  if (!currentTicketS3FilesList) return <SkeletonTable />;

  const handleOnWsMessageReceived = async () => {
    try {
      console.log('[TicketProperties] TICKET_FILES_ADDED or TICKET_FILES_DELETED');
      // Reload Files
      await loadFiles();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_FILES_ADDED', 'TICKET_FILES_DELETED']}></GlobalWsMessenger>
      <div>
        {contextHolder}
        <DroppableWrapper handleDrop={handleDrop}>

          <CondensedTable
            noDataMessage={'No files uploaded'}
            className={loading ? 'opacity-50' : 'pointer-events-auto opacity-1'}
            loading={loading}
            columns={[
              {
                title: 'Name',
                ellipsis: true,
                // width: '50%',
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (record) => (
                  <div>
                    {/* <div className='mt-1'>
                    <Tooltip title={record?.user?.name}>
                      <Space>
                        <UserImage image={record?.user?.user_pic} size='24px' />
                      </Space>
                    </Tooltip>
                  </div> */}
                    <div className='text-xs font-semibold text-blue-700 dark:text-blue-500 cursor-pointer' onClick={() => downloadFileHandler(record.name)}>
                      {dayjs(record.lastModified).add(25, 'days').isBefore(dayjs()) && (
                        <Tooltip title='File will soon expire.'>
                          <span className='text-orange-500 mr-2'>
                            <WarningOutlined />
                          </span>
                        </Tooltip>
                      )}
                      {record.name}
                    </div>
                    <div className='text-xs opacity-60'>uploaded {dayjs(record?.lastModified).fromNow()}</div>
                    <div className='text-xs opacity-60'>by {record?.user?.name}</div>
                  </div>
                ),
              },

              // {
              //   title: 'Uploaded',
              //   dataIndex: 'lastModified',

              //   ellipsis: true,
              //   render: (text) => <span className='text-xs'>{dayjs(text).fromNow()}</span>,
              //   sorter: (a, b) => new Date(a.lastModified) - new Date(b.lastModified),
              //   defaultSortOrder: 'descend',
              // },
              {
                title: 'Size',
                width: 80,
                dataIndex: 'size',
                render: (text) => <p className='text-right m-0 mb-1 mt-1 text-xs'>{Math.round(text / 1024 / 1024) > 0 ? `${Math.round(text / 1024 / 1024)} Mb` : `${Math.round(text / 1024)} KB`} </p>,
                sorter: (a, b) => a.size - b.size,
              },
              {
                title: 'Action',
                key: 'action',
                width: 80,
                align: 'center',
                render: (record) => (
                  <Space className='btn'>
                    <Popconfirm placement='topLeft' title='Delete' description='Do you want to delete this file?' okText='Yes' cancelText='No' onConfirm={() => deleteFileHandle(record.name)}>
                      <Button disabled={loading || currentTicketReadOnly || closedStatus} type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} className='hover:scale-125'>
                        <FaRegTrashCan />
                      </Button>
                    </Popconfirm>
                    {/* <Button disabled={loading} type='text' style={{ color: theme.textLight, padding: '0px 4px', height: '26px', width: '26px' }} className='hover:scale-125'>
                    <CloudDownloadOutlined />
                  </Button> */}
                  </Space>
                ),
              },
            ]}
            dataSource={currentTicketS3FilesList}
            size='small'
            pagination={{
              pageSize: 5,
              size: 'small',
            }}
          //scroll={{ y: 240 }}
          />
          {!uploading && !currentTicketReadOnly && !closedStatus && (
            <div className='text-center text-xs mb-1 text-blue-700 dark:text-blue-500'>
              <Space>
                <InboxOutlined />
                Drag & Drop files to upload them
              </Space>
            </div>
          )}
          {uploading && (
            <Space className='w-full justify-between mt-2'>
              <span className='text-xs text-left ml-2 mb-2'>{uploadMsg}</span>
              <div className='w-full flex justify-end'>
                <Progress
                  steps={5}
                  className='p-2'
                  percent={progress}
                  percentPosition={{
                    align: 'center',
                    type: 'inner',
                  }}
                  size='default'
                />
              </div>
            </Space>
          )}
          <div className='text-center text-xs opacity-60'>
            Files will be automatically deleted after 30 days.

          </div>
        </DroppableWrapper>
      </div>
    </>
  );
};

export default S3Uploader;
