import React, { useState, useEffect } from 'react';
import { Modal, Button, Space, Form, Input, notification, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { searchTenants } from 'src/api/tenants';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Option } = Select;

const UserCreate = ({ open, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [orgList, setOrgList] = useState([]);

  useEffect(() => {
    setOrgList([]);
  }, [open]);

  const handleClear = async () => {
    console.log('handleClear');
    setOrgList([]);
  };

  const handleSearchForTenants = async (v) => {
    setOrgList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await searchTenants(v);
      // console.log('Tenants:', resp);
      if (resp) {
        setOrgList(resp?.entities);
      }
      setLoading(false);
    } catch (error) {
      if (error?.toString().includes('The user aborted a request')) return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnFinish = (values) => {
    console.log('Success:', values);
    if (onSubmit) onSubmit(values);
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: <>Provided data is invalid</>,
      duration: 5,
    });
  };

  const handleOnReset = () => {
    //setModified(false);
  };

  return (
    open && (
      <Modal
        open={open}
        title='Create user'
        footer={null}
        onCancel={() => {
          if (onClose) onClose();
        }}
      >
        <Form name='userForm' style={{ maxWidth: 600 }} layout='vertical' initialValues={{}} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} autoComplete='off'>
          <Form.Item label='Tenant' name='tenant_id' rules={[{ required: true, message: 'Select a tenant' }]}>
            <Select showSearch allowClear placeholder='type to search...' notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null} filterOption={false} onSearch={handleSearchForTenants} onClear={handleClear} style={{ width: '100%' }} autoComplete='off'>
              {orgList.map((d) => (
                <Option key={d.id} disabled={d.third_party_provider}>
                  {d.name} ({d.id})
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Enter a name' }]}>
            <Input placeholder='enter name' autoComplete='off' />
          </Form.Item>
          <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Enter a valid email address', type: 'email' }]}>
            <Input placeholder='enter email' autoComplete='off' />
          </Form.Item>
          <Form.Item label='Temporary password' name='temporaryPassword' rules={[{ required: true, message: 'Enter a password' }]}>
            <Input.Password placeholder='enter password' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} autoComplete='off' />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    )
  );
};

UserCreate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

UserCreate.defaultProps = {
  open: false,
};

export default UserCreate;
