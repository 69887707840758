import React, { useContext, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import MasterAdminContext from 'src/context/MasterAdminContext';
import { getAdminServicePropList } from 'src/api/teacket';
import TeacketServiceEditorConfigList from './TeacketServiceEditorConfigList';
import PropTypes from 'prop-types';

const TeacketServiceEditorConfiguration = ({ serviceId }) => {
  const { teacketServicePropList, setTeacketServicePropList } = useContext(MasterAdminContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  useEffect(() => {
    if (!teacketServicePropList) loadServiceProps();
  }, []);

  const loadServiceProps = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getAdminServicePropList();
        setTeacketServicePropList(resp);
      } catch (error) {
        if (error?.toString().includes('The user aborted a request')) return;
        console.log(error);
      }
    });
  };

  if (!teacketServicePropList) return null;

  return (
    <div className='flex flex-col gap-4'>
      {teacketServicePropList.map((prop, index) => (
        <TeacketServiceEditorConfigList key={index} prop={prop} serviceId={serviceId} />
      ))}
    </div>
  );
};

TeacketServiceEditorConfiguration.propTypes = {
  serviceId: PropTypes.string.isRequired,
};

export default TeacketServiceEditorConfiguration;
