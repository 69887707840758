import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useTheme from 'src/hooks/useTheme';
// import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import CondensedTable from 'src/components/layout/CondensedTable';
import { getTickets } from 'src/api/teacket';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import SkeletonTable from 'src/components/layout/SkeletonTable';
import Highlighter from 'react-highlight-words';
import UserImage from 'src/components/layout/UserImage';
import BarLoader from 'react-bar-loader';
import TicketStatusColor from '../../Components/TicketStatusColor';
import TicketPriorityColor from '../../Components/TicketPriorityColor';
import TicketDrawer from '../../Components/TicketDrawer';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
//import PropTypes from 'prop-types';

const TicketListControl = () => {
  // const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  // const navigate = useNavigate();
  const { ticketList, setTicketList, ticketListUsers, ticketListServices, ticketListPriorities, ticketListStatuses, ticketListQuery, ticketListStartDate, ticketListEndDate, ticketListQuerying, setTicketListQuerying, setTicketListSelectedTicket, currentTicket, currentTicketMembers, ticketListTotal, setTicketListTotal, ticketListCurrentPage, setTicketListCurrentPage, ticketAfterUpdateSync, setTicketAfterUpdateSync } = useContext(TeacketContext);

  const [pageSize] = useState(parseInt(25));

  useEffect(() => {
    setTicketListSelectedTicket(null);
    if (!ticketList) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setTicketListSelectedTicket(null);
    };
  }, []);

  useEffect(() => {
    loadData();
  }, [ticketListCurrentPage]);

  useEffect(() => {
    //console.log({ ticketListUsers, ticketListPriorities, ticketListStatuses, ticketListQuery, ticketListServices, ticketListStartDate, ticketListEndDate });
    setTicketListCurrentPage(1);

    // setTicketList(null);
    loadData();
  }, [ticketListUsers, ticketListPriorities, ticketListStatuses, ticketListQuery, ticketListServices, ticketListStartDate, ticketListEndDate]);

  useEffect(() => {
    if (!ticketAfterUpdateSync) return;
    ticketSyncAfterUpdate(ticketAfterUpdateSync);
  }, [ticketAfterUpdateSync]);

  useEffect(() => {
    if (!currentTicket) return;
    if (ticketAfterUpdateSync) ticketSyncAfterUpdate();
  }, [currentTicket, currentTicketMembers]);

  const ticketSyncAfterUpdate = async () => {
    console.log('ticketSyncAfterUpdate()');
    try {
      const ticket = currentTicket;

      let rearrangedMembers;
      if (currentTicketMembers) {
        rearrangedMembers = [];
        currentTicketMembers.forEach((member) => {
          rearrangedMembers.push({
            live_notifications: member.live_notifications,
            role: member.role,
            ticket_id: member.ticket_id,
            user_id: member.user_id,
            user: {
              name: member.user_name,
              user_pic: member.user_pic,
            },
          });
        });
      }

      console.log('Check if ticket should be still on the list, or should be removed (filters)');
      let bShouldBeVisible = true;

      if (ticketListUsers?.length > 0) {
        // check if rearrangedMembers contains any of the ticketListUsers ids
        bShouldBeVisible = rearrangedMembers.some((x) => ticketListUsers.includes(x.user_id));
        if (!bShouldBeVisible) {
          setTicketList(ticketList.filter((x) => x.id !== currentTicket.id));
          return;
        }
      }

      if (ticketListPriorities?.length > 0) {
        bShouldBeVisible = ticketListPriorities.includes(ticket.priority);
        if (!bShouldBeVisible) {
          setTicketList(ticketList.filter((x) => x.id !== currentTicket.id));
          return;
        }
      }

      if (ticketListStatuses?.length > 0) {
        bShouldBeVisible = ticketListStatuses.includes(ticket.status);
        if (!bShouldBeVisible) {
          setTicketList(ticketList.filter((x) => x.id !== currentTicket.id));
          return;
        }
      }

      console.log('Looks like ticket should be still visible, so update it in the list');
      ticket.teacket_ticket_members = rearrangedMembers;
      const index = ticketList.findIndex((x) => x.id === currentTicket.id);
      ticketList[index] = ticket;
      setTicketList([...ticketList]);
    } catch (error) {
      console.error(error);
    } finally {
      setTicketAfterUpdateSync(null);
    }
  };

  const columns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      width: 140,
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (text) => (
        <Button
          type='link'
          style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}
          onClick={async () => {
            setTicketListSelectedTicket(text);
          }}
        >
          <strong>
            <Highlighter textToHighlight={text ?? ''} searchWords={[`${ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </strong>
        </Button>
      ),
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: 420,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text) => (
        <div style={{ maxWidth: '400px', wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'acc_name',
      sorter: (a, b) => a.acc_name?.localeCompare(b.acc_name),
      render: (text) => (
        <div>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </div>
      ),
    },
    {
      title: 'Members',
      key: 'members',
      dataIndex: 'teacket_ticket_members',
      sorter: (a, b) => a.teacket_ticket_members?.length - b.teacket_ticket_members?.length,
      align: 'left',
      width: 220,
      render: (text) => (
        <div className='flex flex-col gap-1 m-2 flex-wrap'>
          {text?.map((x, i) => {
            return (
              <div key={i} className='flex flex-row gap-2 items-center'>
                <UserImage image={x?.user?.user_pic} size='25px' />
                <div style={{ lineHeight: '0.5rem' }}>
                  <div className='text-sm'>{x?.user?.name}</div>
                  <div className='text-[0.6rem] font-light opacity-60'>{x?.role}</div>
                </div>

              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'category',
      align: 'center',
      sorter: (a, b) => a.category.localeCompare(b.category),
      render: (text, record) => (
        <div style={{ wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Highlighter textToHighlight={text ?? ''} searchWords={[`${ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          <div className='text-xs font-light opacity-60'>
            <Highlighter textToHighlight={record?.sub_category ?? ''} searchWords={[`${ticketListQuery ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </div>
        </div>
      ),
    },
    {
      title: 'Priority',
      key: 'priority',
      dataIndex: 'priority',
      align: 'center',
      sorter: (a, b) => a.priority?.localeCompare(b.priority),
      render: (text, record) => (
        <div className='flex flex-row gap-2 items-center justify-center'>
          <TicketPriorityColor serviceId={record?.service_id} priority={text} />
          {text}
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      sorter: (a, b) => a.status?.localeCompare(b.status),
      render: (text, record) => (
        <div className='flex flex-row gap-2 items-center justify-center'>
          <TicketStatusColor serviceId={record?.service_id} status={text} />
          {text}
        </div>
      ),
    },
    {
      title: 'Created',
      key: 'created_at',
      dataIndex: 'created_at',
      align: 'center',
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
    {
      title: 'Closed',
      key: 'closed_at',
      dataIndex: 'closed_at',
      align: 'center',
      sorter: (a, b) => a.closed_at?.localeCompare(b.closed_at),
      render: (text) =>
        text ? (
          <>
            <div>{dayjs(text).fromNow()}</div>
            <div className='font-thin text-xs'>at {dayjs(text).format('LLL')}</div>
          </>
        ) : (
          <>n/a</>
        ),
    },
  ];

  const loadData = async (loadMore = false) => {
    try {
      setTicketListQuerying(true);
      const resp = await getTickets(ticketListCurrentPage, pageSize, ticketListQuery, ticketListServices, ticketListPriorities, ticketListStatuses, ticketListUsers, ticketListStartDate, ticketListEndDate);
      setTicketList(resp?.entities ?? []);
      setTicketListTotal(resp?.total ?? 0);
      // if (true === notEmptyArray(resp?.entities)) {
      //   if (loadMore) setTicketList([...ticketList, ...resp]);
      //   else setTicketList(resp?.entities);
      // } else {
      //   setTicketList([]);
      // }
      setTicketListQuerying(false);
    } catch (error) {
      console.log(error);
    }
    //setEventWidgetItemsForceReload(false);
  };

  return (
    <div className={ticketListQuerying ? 'disabled' : null}>
      {!ticketList ? (
        <SkeletonTable />
      ) : (
        <>
          <TicketDrawer />

          <>
            {ticketListQuerying ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '2px' }} />}
            <CondensedTable
              pagination={{
                current: ticketListCurrentPage,
                showSizeChanger: false,
                pageSize,
                size: 'small',
                total: ticketListTotal,
                onChange: (page) => setTicketListCurrentPage(page),
              }}
              columns={columns}
              dataSource={ticketList}
              rowKey={'id'}
            />
          </>
        </>
      )}
    </div>
  );
};

TicketListControl.propTypes = {};

export default TicketListControl;
