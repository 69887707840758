import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import PropertyItem from 'src/components/layout/PropertyItem';
import { Button, Modal, Input } from 'antd';
import { putTicketCustomField } from 'src/api/teacket';
import PropTypes from 'prop-types';

const CustomFieldString = ({ ticketId, field, onUpdate }) => {
  const { currentTicketReadOnly } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(null);

  useEffect(() => {
    setNewValue(field.value);
  }, [isEditing, field.value]);

  //#region "EVENT HANDLERS"

  const handlePropertyItemOnClick = () => {
    console.log('CustomFieldString:', field);
    setIsEditing(true);
  };

  const handleModalOnSave = async () => {
    setIsEditing(false);
    await executeAsyncProcess(async () => {
      try {
        const result = await putTicketCustomField(ticketId, field.field, newValue);
        if (onUpdate) onUpdate({ ticketId, field, result });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleModalOnCancel = () => {
    setIsEditing(false);
  };

  //#endregion

  //#region "MISC"

  const isSaveDisabled = () => {
    return newValue === field.value;
  };

  const isCancelDisabled = () => {
    return false;
  };

  const getLabelExtras = () => {
    if (true === field.isRequired && (!field.value || field.value === '')) {
      return <span className='text-red-500 animate-pulse'>is required!</span>;
    }
    return null;
  };

  //#endregion

  return (
    <>
      <Modal
        closable={false}
        open={isEditing}
        title={field.field}
        footer={[
          <Button key='Save' onClick={handleModalOnSave} type='primary' disabled={isSaveDisabled()}>
            Save
          </Button>,
          <Button key='Cancel' onClick={handleModalOnCancel} disabled={isCancelDisabled()}>
            Cancel
          </Button>,
        ]}
      >
        <Input
          placeholder='Enter a value'
          value={newValue}
          showCount
          onChange={(e) => {
            setNewValue(e.target.value);
          }}
          maxLength={50}
        />
      </Modal>
      <PropertyItem label={field.field} value={field.value} onClick={!currentTicketReadOnly ? handlePropertyItemOnClick : null} labelExtras={getLabelExtras()} isHighlighted={null !== getLabelExtras()} />
    </>
  );
};

CustomFieldString.propTypes = {
  ticketId: PropTypes.string,
  field: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default CustomFieldString;
