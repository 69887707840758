import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getUser, patchUser, postUsersCognitoResetPassword, deleteUsersCognito } from 'src/api/users';
import { getTenant } from 'src/api/tenants';
import { Button, Space, Form, Input, message, Tabs, Popover, Checkbox } from 'antd';
import ThirdPartyProvider from 'src/components/controls/ThirdPartyProvider/ThirdPartyProvider';
import { LuUser2, LuBuilding, LuMenu, LuShieldCheck } from 'react-icons/lu';
import { TbSum } from 'react-icons/tb';
import { BsChevronRight } from 'react-icons/bs';
import { Skeleton } from 'antd';
import UserResetPassword from './UserResetPassword';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import UserPermissions from './UserPermissions';
import RolesEditor from '../../Components/RolesEditor';
import { MdDelete } from 'react-icons/md';
import { uuid } from 'short-uuid';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import NoData from 'src/components/layout/NoData';

const UserEditor = ({ userId, onSave, onDelete }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();
  const [messageApi, contextHolder] = message.useMessage();
  const [user, setUser] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [modified, setModified] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (!userId) {
      setUser(null);
      setTenant(null);
      return;
    }
    if (user?.id === userId) return;
    setUser(null);
    setTenant(null);
    loadData();
  }, [userId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        setModified(false);
        const resp1 = await getUser(userId);
        setUser(resp1);
        const tenantId = resp1?.tenant_id;
        if (tenantId) {
          const resp2 = await getTenant(tenantId);
          setTenant(resp2);
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnChange = (values) => {
    setModified(true);
  };

  const handleOnFinish = (values) => {
    console.log('Success:', values);
    executeAsyncProcess(async () => {
      try {
        const resp = await patchUser(userId, { name: values.name, disabled: values.disabled });
        if (onSave) onSave(resp);
        setModified(false);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Data has been saved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    messageApi.open({
      key: uuid(),
      type: 'success',
      content: 'Provided data is invalid',
      duration: 3,
    });
  };

  const handleOnReset = () => {
    setModified(false);
  };

  const handleResetPasswordOnSubmit = (values) => {
    setResetPasswordOpen(false);
    executeAsyncProcess(async () => {
      try {
        await postUsersCognitoResetPassword(user.email, values.password);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Password has been reset',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handlePernamentlyDelete = () => {
    console.log('handlePernamentlyDelete');
    executeAsyncProcess(async () => {
      try {
        await deleteUsersCognito(user.id);
        if (onDelete) onDelete(user.id);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'User has been deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <DraggableLayoutItem
      title={
        <Space className='btn'>
          {tenant ? (
            <Space className='whitespace-nowrap'>
              <ThirdPartyProvider provider={tenant?.third_party_provider ?? 'cognito'} />
              <BsChevronRight />
              <LuBuilding className='opacity-60' />
              <div className='overflow-hidden overflow-ellipsis'>{tenant?.name ?? 'unknown'}</div>
              <BsChevronRight />
              <LuUser2 className='opacity-60' />
              <div className='overflow-hidden overflow-ellipsis'>{user?.name ?? 'unknown'}</div>
            </Space>
          ) : (
            <>
              {user && (
                <Space className='btn'>
                  <Skeleton.Input active={true} size={28} />
                  <BsChevronRight />
                  <Skeleton.Input active={true} size={28} />
                  <BsChevronRight />
                  <Skeleton.Input active={true} size={28} />
                </Space>
              )}
            </>
          )}
        </Space>
      }
      titleExtras={
        user && (
          <Popover
            content={
              <Space direction='vertical'>
                <Button className='w-[200px]' color={theme.textBase} disabled={!tenant || tenant.third_party_provider} onClick={() => setResetPasswordOpen(true)}>
                  Reset password
                </Button>

                <YesNoDialog
                  title='Pernamently delete'
                  body={
                    <>
                      Do you want to delete user <strong>{user.name}</strong>?
                    </>
                  }
                  onYesClick={handlePernamentlyDelete}
                  iconYes={<MdDelete />}
                  showRed={true}
                  labelYes='Yes, delete user'
                  labelNo='Cancel'
                >
                  <Button className='w-[200px]' color={theme.textBase} disabled={false}>
                    Pernamently delete
                  </Button>
                </YesNoDialog>
              </Space>
            }
            title='Actions'
            trigger='click'
            placement='bottomRight'
          >
            <Button type='text' icon={<LuMenu />} />
          </Popover>
        )
      }
    >
      {contextHolder}
      <UserResetPassword open={resetPasswordOpen} onClose={() => setResetPasswordOpen(false)} onSubmit={handleResetPasswordOnSubmit} />

      {!user ? (
        <NoData />
      ) : (
        <Tabs
          onChange={(key) => setActiveTab(key)}
          type='card'
          defaultActiveKey='1'
          items={[
            {
              key: '1',
              label: (
                <Space className='btn'>
                  <LuUser2 />
                  User
                </Space>
              ),
              children: (
                <div>
                  <Form name='userEditor' style={{ maxWidth: 600 }} layout='vertical' initialValues={user} onChange={handleOnChange} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} onReset={handleOnReset} autoComplete='off'>
                    <Form.Item name='disabled' valuePropName='checked'>
                      <Checkbox>Is disabled?</Checkbox>
                    </Form.Item>
                    <Form.Item label='Id' name='id'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Name' name='name' rules={[{ required: true, message: 'Name name is required' }]}>
                      <Input disabled={!tenant || tenant.third_party_provider} />
                    </Form.Item>

                    <Form.Item label='Department' name='department' rules={[{ required: true, message: 'Name name is required' }]}>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Country' name='country' rules={[{ required: true, message: 'Name name is required' }]}>
                      <Input disabled />
                    </Form.Item>

                    <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Email is required' }]}>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Picture URL' name='user_pic'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Created at' name='created_at'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item label='Updated at' name='updated_at'>
                      <Input disabled />
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Button htmlType='reset' disabled={!modified}>
                          Reset
                        </Button>
                        <Button type='primary' htmlType='submit' disabled={!modified}>
                          Submit
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
              ),
            },
            {
              key: '2',
              label: (
                <Space className='btn'>
                  <LuBuilding />
                  User's Tenant
                </Space>
              ),
              children: (
                <>
                  {tenant && (
                    <div>
                      <Form name='userEditor2' style={{ maxWidth: 600 }} layout='vertical' initialValues={tenant} autoComplete='off'>
                        <Form.Item label='Id' name='id'>
                          <Input disabled />
                        </Form.Item>
                        <Form.Item label='Name' name='name'>
                          <Input disabled />
                        </Form.Item>
                        <Form.Item label='Account type' name='acc_type'>
                          <Input disabled />
                        </Form.Item>
                        <Form.Item label='3rd party provider' name='third_party_provider'>
                          <Input disabled />
                        </Form.Item>
                        <Form.Item label='3rd party id' name='third_party_id'>
                          <Input disabled />
                        </Form.Item>
                        <Form.Item label='3rd party env' name='third_party_env'>
                          <Input disabled />
                        </Form.Item>
                        <Form.Item label='Created at' name='created_at'>
                          <Input disabled />
                        </Form.Item>
                        <Form.Item label='Updated at' name='updated_at'>
                          <Input disabled />
                        </Form.Item>
                      </Form>
                    </div>
                  )}
                </>
              ),
            },
            {
              key: '3',
              label: (
                <Space className='btn'>
                  <LuShieldCheck />
                  Roles
                </Space>
              ),
              children: (
                <>
                  <RolesEditor objectType='user' id={userId} />
                </>
              ),
            },
            {
              key: '4',
              label: (
                <Space className='btn'>
                  <TbSum />
                  Resultant Permissions
                </Space>
              ),
              children: <>{'4' === activeTab && <UserPermissions userId={userId} />}</>,
            },
          ]}
        />
      )}
    </DraggableLayoutItem>
  );
};

UserEditor.propTypes = {
  userId: PropTypes.string,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default UserEditor;
