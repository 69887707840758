import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postGcPermissions } from 'src/api/auth';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { Result, Tag, Space } from 'antd';
import GlobalContext from '../context/GlobalContext';

const GcPermissionsProvider = ({ children, requiredPermissions, appId, helpPage }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const [result, setResult] = useState(null);

  const { oAuthClientCredentialsConfig } = useContext(GlobalContext);

  useEffect(() => {
    verifyPermissions();
  }, []);

  const verifyPermissions = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await postGcPermissions(requiredPermissions);
        setResult(resp);
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (!result) return <FullScreenSpin title='Please wait' subtitle='Verifying Genesys Cloud permissions' />;
  if (true === result?.permissionsValidated) return children;

  return (
    <div>
      <Result
        status='403'
        title='Insufficient Permissions'
        subTitle={
          <>
            <p>To run this tool for the first time, you need to have specific Genesys Cloud permissions.</p>
            <a href={helpPage || oAuthClientCredentialsConfig?.[appId]?.helpPage + '#installation'} target='_blank' rel='noreferrer'>
              Check how to add required permissions
            </a>{' '}
            to your Genesys Cloud role.
          </>
        }
        extra={
          <div className='flex flex-col gap-1'>
            {result?.requiredPermissions?.map((item, index) => (
              <div key={index}>
                {item.granted ? (
                  <Space className='text-green-800 dark:text-green-200'>
                    {item.domain} &gt; {item.entityName} &gt; {item.action}
                    <Tag size='small' color='green'>
                      Granted
                    </Tag>
                  </Space>
                ) : (
                  <Space className='text-red-800 dark:text-red-200'>
                    {item.domain} &gt; {item.entityName} &gt; {item.action}
                    <Tag size='small' color='red'>
                      Not granted
                    </Tag>
                  </Space>
                )}
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
};

GcPermissionsProvider.propTypes = {
  children: PropTypes.node,
  requiredPermissions: PropTypes.arrayOf(PropTypes.object),
  appId: PropTypes.string,
  helpPage: PropTypes.string,
};

export default GcPermissionsProvider;
