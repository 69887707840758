import React, { useEffect, useState } from 'react';
import useScreen from 'src/hooks/useScreen';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import useUserInfo from 'src/hooks/useUserInfo';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import TitleBar from 'src/components/layout/TitleBar';
import { LuBuilding } from 'react-icons/lu';
import { Space, Tooltip, Switch, Input, message } from 'antd';
import { searchTenants, postTenant } from 'src/api/tenants';
import TenantEditor from './Components/TenantEditor';
import { FaStar, FaPlus } from 'react-icons/fa6';
import StyledButton from 'src/components/layout/StyledButton';
import { notEmptyArray } from 'src/misc/Misc';
import TenantCreate from './Components/TenantCreate';
import { SearchOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { uuid } from 'short-uuid';
import CondensedTable from 'src/components/layout/CondensedTable';
import Highlighter from 'react-highlight-words';
import { MdDashboard } from 'react-icons/md';
import PageLayoutType3 from 'src/components/layout/PageLayoutType3';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';

//import PropTypes from 'prop-types';

const MasterAdminTenants = () => {
  const { tenantId, tab } = queryString.parse(window.location.search);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const { theme } = useTheme();
  const { tenant_id } = useUserInfo();
  const { isDesktop } = useScreen();

  const pageSizeOptions = [20, 50, 100];

  const columns = [
    {
      title: 'ID',
      key: 'id',
      width: 360,
      render: (record) => (
        <span className={record.id === selectedTenant ? 'font-bold' : null}>
          <Highlighter textToHighlight={record.id ?? ''} searchWords={[`${query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
        </span>
      ),
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => (
        <Space className='btn'>
          <span className={record.id === selectedTenant ? 'font-bold' : null}>
            <Highlighter textToHighlight={record.name ?? ''} searchWords={[`${query ?? ''}`]} highlightStyle={theme.highlightStyle} unhighlightStyle={null} autoEscape={true} />
          </span>
          {record.id === tenant_id && (
            <Tooltip title={"Current user's tenant"}>
              <FaStar className='text-yellow-500' />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const [tenants, setTenants] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [layoutHorizontal, setLayoutHorizontal] = useState(isDesktop);
  const [newTenantOpen, setNewTenantOpen] = useState(false);
  const [query, setQuery] = useState(tenantId ?? null);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Tenants',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    setPage(1);
    setTotal(0);
    setTenants(null);
    setSelectedTenant(null);
    loadData();
  }, [query, pageSize]);

  useEffect(() => {
    setSelectedTenant(null);
    loadData();
  }, [page]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const q = query?.trim() ?? null;
        const resp = await searchTenants(q?.length > 0 ? q : null, page, pageSize);
        setTotal(resp?.total ?? 0);
        setTenants(resp.entities);
        if (tenantId) {
          setSelectedTenant(tenantId);
        }
      } catch (error) {
        if (error?.toString().includes('The user aborted a request')) return;
        console.log(error);
      }
    });
  };

  const handleOnRow = (record) => {
    return {
      style: { cursor: 'pointer', background: record.id === selectedTenant ? theme.backgroundLight : null },
      onClick: () => {
        setSelectedTenant(record.id);
      },
    };
  };

  const handleSelectedTenantOnSave = (tenant) => {
    const index = tenants.findIndex((o) => o.id === tenant.id);
    if (index < 0) return;
    const newTenants = [...tenants];
    newTenants[index] = tenant;
    setTenants(newTenants);
  };

  const handleTenantCreateOnSubmit = (values) => {
    console.log('handleTenantCreateOnSubmit', values);
    setNewTenantOpen(false);
    executeAsyncProcess(async () => {
      try {
        const resp = await postTenant(values);
        const [...o] = true === notEmptyArray(tenants) ? tenants : [];
        o.unshift(resp);
        setTenants(o);

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'New tenant has been created',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      {contextHolder}
      <TenantCreate open={newTenantOpen} onClose={() => setNewTenantOpen(false)} onSubmit={handleTenantCreateOnSubmit} />
      <TitleBar
        title={
          <Space className='btn'>
            <LuBuilding />
            Tenants
          </Space>
        }
        afterTitleExtras={
          <StyledButton color={theme.textBase} onClick={() => setNewTenantOpen(true)} size='small'>
            <Space className='btn'>
              <FaPlus />
              <WhenDesktop>Create tenant</WhenDesktop>
            </Space>
          </StyledButton>
        }
      >
        <Space>
          <StyledButton color={theme.textBase} onClick={() => navigate('/dashboards/features-access')} size='small'>
            <Space className='btn'>
              <MdDashboard />
              <WhenDesktop>Dashboards / Features Access</WhenDesktop>
            </Space>
          </StyledButton>
          <Switch
            unCheckedChildren={<>vertical</>}
            checkedChildren={<>horizontal</>}
            checked={layoutHorizontal}
            onChange={() => {
              setLayoutHorizontal(!layoutHorizontal);
            }}
          />
        </Space>
      </TitleBar>
      <PageLayoutType3
        left={
          <DraggableLayoutItem
            title='Tenant list'
            titleExtras={
              <Input
                size='small'
                disabled={tenantId && isBusy}
                className='w-44'
                placeholder='Search...'
                prefix={<SearchOutlined className='mr-2' />}
                allowClear
                value={query}
                onChange={(e) => {
                  if (tenantId) navigate('/master-admin/tenants');
                  setQuery(e.target.value);
                }}
              />
            }
          >
            <CondensedTable
              pagination={{
                showSizeChanger: !tenantId,
                pageSizeOptions,
                size: 'small',
                onShowSizeChange: (current, size) => {
                  console.log('onShowSizeChange', current, size);
                  setPageSize(size);
                },
                current: page,
                pageSize,
                total,
                onChange: (page) => setPage(page),
              }}
              size='small'
              columns={columns}
              dataSource={tenants}
              rowKey={'id'}
              onRow={handleOnRow}
            />
          </DraggableLayoutItem>
        }
        right={<TenantEditor tenantId={selectedTenant} onSave={handleSelectedTenantOnSave} initialTab={tab} />}
      />
    </PermissionsProvider>
  );
};

// MasterAdminTenants.propTypes = {};

export default MasterAdminTenants;
