import React from 'react';

import SetupRequestsWizard from './SetupRequestsWizard/SetupRequestsWizard';

const NewDataSubjectRequest = () => {
  return (
    <div className='bg-gray-100 dark:bg-gray-700 min-h-screen flex flex-col justify-center items-center'>
      <SetupRequestsWizard />
    </div>
  );
};

NewDataSubjectRequest.propTypes = {};

export default NewDataSubjectRequest;
