import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { getJob } from 'src/api/securityAdvisor';

const JobLoader = ({ children }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { job, setJob } = useContext(SecurityAdvisorContext);

  useEffect(() => {
    if (job) return;
    loadData();
  }, []);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const job = await getJob();
        console.log('Loaded Job', job);
        setJob(job);
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (!job) return <FullScreenSpin title='Please wait' subtitle='Loading data...' />;

  return children;
};

JobLoader.propTypes = {
  children: PropTypes.node,
};

export default JobLoader;
