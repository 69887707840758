import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import { Space, Tooltip } from 'antd';
import { invertColor } from 'src/misc/Misc';
import { PiDotsSixVerticalBold } from 'react-icons/pi';

const DraggableLayoutItem = ({ startExtras, title, titleExtras, children, height, collapsible, centered, onClick, bgColor }) => {
  const { theme, isDarkMode } = useTheme();

  const [collapsed, setCollapsed] = useState(false);

  const getBgColor = () => {
    if (!bgColor) return theme.backgroundBase;
    return `linear-gradient(180deg, ${isDarkMode ? invertColor(bgColor) : bgColor} -240px, ${theme.backgroundBase} 80px, ${theme.backgroundBase} 100%)`;
  };

  return (
    <div
      className={`w-full rounded-xl shadow-lg overflow-hidden ${onClick ? 'cursor-pointer opacity-90 hover:opacity-100 hover:scale-[1.02] transition-all' : ''}`}
      style={{ height, lineBreak: 'auto', background: getBgColor() }}
      onClick={() => {
        if (!onClick) return;
        onClick();
      }}
    >
      <div className='m-4'>
        {centered ? (
          <div className='relative flex-initial font-extrabold text-lg text-center whitespace-nowrap flex flex-row gap-2 items-center justify-center' style={{ color: theme.textBase }}>
            {/* Align icon to the left */}
            <div className={`flex-none  ${isDarkMode ? 'text-gray-700' : 'text-gray-300'} absolute left-0 top-1/2 transform -translate-y-1/2`}>
              <PiDotsSixVerticalBold />
            </div>

            {/* Center rest of the content */}
            <div className='flex flex-auto justify-center items-center'>
              <div>{title}</div>
            </div>

            <div className='absolute right-0 top-0'>{titleExtras}</div>
            <div className='absolute left-0 top-0'>{startExtras}</div>
          </div>
        ) : (
          <div className='flex flex-row'>
            {collapsible ? (
              <div
                className='flex-initial items-center font-semibold text-lg cursor-pointer'
                style={{ color: theme.textBase }}
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              >
                <Space className='btn'>
                  <div>{title}</div>
                  <div className='opacity-40 flex flex-col justify-center'>
                    {collapsed ? (
                      <Tooltip title='Click to expand'>
                        <BsArrowsExpand />
                      </Tooltip>
                    ) : (
                      <Tooltip title='Click to collapse'>
                        <BsArrowsCollapse />
                      </Tooltip>
                    )}
                  </div>
                  <div>{collapsed ? <span className='font-extralight text-xs opacity-60'>(collapsed)</span> : ''}</div>
                </Space>
              </div>
            ) : (
              <div className='flex-initial items-center font-semibold text-lg' style={{ color: theme.textBase }}>
                {title}
              </div>
            )}

            <div className='flex-auto flex flex-row justify-end items-center gap-1'>{titleExtras}</div>
          </div>
        )}
        {!collapsed && <div className='overflow-auto mt-2 draggable-layout-exclude'>{children}</div>}
      </div>
    </div>
  );
};

DraggableLayoutItem.propTypes = {
  startExtras: PropTypes.node,
  title: PropTypes.node,
  titleExtras: PropTypes.node,
  children: PropTypes.node,
  height: PropTypes.string,
  collapsible: PropTypes.bool,
  centered: PropTypes.bool,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
};

DraggableLayoutItem.defaultProps = {
  height: 'auto',
  collapsible: false,
  centered: false,
};

export default DraggableLayoutItem;
