import React from 'react';
import { Space } from 'antd';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import TicketProperties from 'src/components/pages/Teacket/Components/TicketProperties';
import TicketConversation from 'src/components/pages/Teacket/Components/TicketConversation';
import TicketMembers from 'src/components/pages/Teacket/Components/TicketMembers';
import S3Uploader from 'src/components/pages/Teacket/Components/S3Uploader/S3Uploader';
import TicketHistory from 'src/components/pages/Teacket/Components/TicketHistory';
import TicketCustomProperties from 'src/components/pages/Teacket/Components/TicketCustomProps/TicketCustomProperties';
import TicketStatus from 'src/components/pages/Teacket/Components/TicketStatus';
import TicketHistoryBtns from 'src/components/pages/Teacket/Components/TicketHistoryBtns';
import TicketSfData from 'src/components/pages/Teacket/Components/TicketSfData/TicketSfData';
import TicketDates from 'src/components/pages/Teacket/Components/TicketDates';
import OpportunityDetails from 'src/components/pages/Teacket/Components/OpportunityDetails';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import { notArrayOrEmpty } from 'src/misc/Misc';
import TicketTimeTracking from '../Components/TicketTimeTracking/TicketTimeTracking';

export const getComponentsGallery = (userPermissions, widgetList) =>
  [
    {
      id: 'Status / Priority',
      col: 0,
      component: (
        <DraggableLayoutItem title='Status & Priority' centered>
          <TicketStatus />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Properties',
      col: 0,
      component: (
        <DraggableLayoutItem title='Properties' centered>
          <TicketProperties />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Opportunity Details',
      col: 0,
      component: (
        <DraggableLayoutItem title='Opportunity Details' centered>
          <OpportunityDetails />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Dates',
      col: 0,
      component: (
        <DraggableLayoutItem title='Dates' centered>
          <TicketDates />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Time Tracking',
      col: 0,
      component: (
        <DraggableLayoutItem title='Time Reporting' centered collapsible>
          <TicketTimeTracking />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Conversation',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              {/* <MdOutlineSecurity /> */}
              Conversation
            </Space>
          }
          centered
        >
          <TicketConversation />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'SF Data',
      col: 2,
      component: (
        <DraggableLayoutItem title='Salesforce' centered>
          <TicketSfData />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.teacket.ticket.edit'],
    },
    {
      id: 'People',
      col: 2,
      component: (
        <DraggableLayoutItem title='People' centered>
          <TicketMembers />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Additional Properties',
      col: 2,
      component: (
        <DraggableLayoutItem title='Additional Properties' centered>
          <TicketCustomProperties />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.teacket.ticket.edit'],
    },
    {
      id: 'Files',
      col: 2,
      component: (
        <DraggableLayoutItem title='Files' centered>
          <S3Uploader />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'History',
      col: 2,
      component: (
        <DraggableLayoutItem title='History' titleExtras={<TicketHistoryBtns />} centered>
          <TicketHistory />
        </DraggableLayoutItem>
      ),
    },
  ]
    .filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
    .filter((item) => filterItemsBasedOnWidgetList(item, widgetList));

const filterItemsBasedOnWidgetList = (item, widgetList) => {
  // console.log('filterItemsBasedOnWidgetList()', item, widgetList);
  if (true === notArrayOrEmpty(widgetList)) return false;
  return widgetList.map((x) => x.widgetId).includes(item.id);
};
