import React, { useEffect, useState } from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { TbFaceIdError } from 'react-icons/tb';
import queryString from 'query-string';
import Space2 from 'src/components/layout/Space2';
import { SESSION_STORAGE_KEYS } from 'src/misc/Config';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
// import { logout } from 'src/misc/Session';
// import useUserInfo from 'src/hooks/useUserInfo';
//import PropTypes from 'prop-types';

const SignInError = () => {
  // const userInfo = useUserInfo();
  const { error, error_description } = queryString.parse(window.location.search);
  const [baseUrl] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.selectedGcRegion));

  useEffect(() => {
    console.log('error', error);
    console.log('error_description', error_description);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.authTargetPath);
    // if (userInfo) {
    //   logout(null, window.location.pathname);
    //   return;
    // }
  }, []);

  return (
    <>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 w-[1200px]'>
          <div className='my-8 font-bold text-2xl'>
            <Space2>
              <TbFaceIdError className={'text-4xl'} />
              Oops... we couldn't sign you in
            </Space2>
          </div>
          <div>{error}</div>
          <div>{error_description}</div>

          {error_description === 'the client has not been authorized for the organization' && (
            <div className='mt-8'>
              <div className='font-bold text-2xl'>What to do now?</div>
              <div className='my-8'>
                <h4>
                  Authorize an OAuth client: <span className='text-genesys-aqua-base'>07e01e9c-dd79-4f6d-a493-13043f68ed73</span>
                </h4>
                <p>Only an approved Genesys Cloud administrator can authorize an OAuth client. Users without permissions are shown a permissions error indicating an administrator is required to approve this application.</p>
                <p>
                  To authorize known client IDs:
                  <ol className='list-decimal list-inside'>
                    <li className='mb-2'>
                      Click <strong>Admin</strong>.
                    </li>
                    <li className='mb-2'>
                      Under <strong>Integrations</strong>, click <strong>Authorized Applications</strong>.
                    </li>
                    <li className='mb-2'>
                      Click <strong>Authorize a client</strong>.
                      <br />
                      <br />
                      <img src='/images/signinerror/auth_01.jpg' alt='screen' className='w-3/4' />
                    </li>
                    <li className='mb-2'>
                      Enter the <strong>Client ID</strong> and click <strong>Authorize client</strong>.
                      <br />
                      <br />
                      <img src='/images/signinerror/auth_02.jpg' alt='screen' className='w-1/3' />
                    </li>
                    <li className='mb-2'>
                      Select the required scope.
                      <br />
                      <br />
                      <img src='/images/signinerror/auth_03.jpg' alt='screen' className='w-3/4' />
                    </li>
                    <li className='mb-2'>
                      Click <strong>Approve</strong>.
                    </li>
                  </ol>
                </p>
                {baseUrl && (
                  <p className='mt-8'>
                    If you have a relevant administrative permissions, you can use the following link to navigate directly to the <strong>Authorized Applications</strong> page:
                    <br />
                    <a href={`https://apps.${baseUrl}/directory/#/admin/integrations/authorized-apps`} target='_blank' rel='noreferrer'>
                      {`https://apps.${baseUrl}/directory/#/admin/integrations/authorized-apps`}
                    </a>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

// SignInError.propTypes = {};

export default SignInError;
