import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import { Space, Input, Button, Form, Tag, Tooltip, Select, Modal, message, Alert } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postTicket } from 'src/api/teacket';
import { getAccountForOrgId, getAccountDeployments } from 'src/api/snowflake';
import useUserInfo from 'src/hooks/useUserInfo';
import { notEmptyArray } from 'src/misc/Misc';
import { getServicesIsMaster } from 'src/api/teacket';
import SnowflakeAccountPicker from 'src/components/controls/SnowflakeAccountPicker/SnowflakeAccountPicker';
import SnowflakeOpportunityPicker from 'src/components/controls/SnowflakeOpportunityPicker/SnowflakeOpportunityPicker';
import PropertySection from 'src/components/layout/PropertySection';
import SearchServiceUser from 'src/components/pages/Teacket/Components/ServiceUserPicker';
import { CONTENT_MAX_LENGTH, TITLE_MAX_LENGTH } from '../../Misc/misc';
import { uuid } from 'short-uuid';
import OpportunityDetails from '../../Components/OpportunityDetails';

//import PropTypes from 'prop-types';

const TicketCreateControl = () => {
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [createTicketForm] = Form.useForm();
  const { servicesForTenant, getServiceSettingForServiceId } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [isMaster, setIsMaster] = useState(null);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [opportunityModalOpen, setOpportunityModalOpen] = useState(false);
  const [opportunity, setOpportunity] = useState(null);
  const [opportunityDetails, setOpportunityDetails] = useState(null);

  const [snowflakeOpportunitySupport, setSnowflakeOpportunitySupport] = useState(null);
  const [requesterModalOpen, setRequesterModalOpen] = useState(false);
  const [requester, setRequester] = useState(null);
  const [gcDeploymentSupport, setGcDeploymentSupport] = useState(null);
  const [deploymentOptions, setDeploymentOptions] = useState(null);
  const [isOkDisabled, setIsOkDisabled] = useState(true);

  useEffect(() => {
    createTicketForm.setFieldValue('owner_id', userInfo.id);
    createTicketForm.setFieldValue('owner_name', userInfo.name);
  }, []);

  useEffect(() => {
    console.log('deploymentOptions:', deploymentOptions);
    // if (deploymentOptions) {
    // }
  }, [deploymentOptions]);

  //#region "FORM HANDLERS"

  const handleFormOnFinish = async (values) => {
    console.log('Success:', values);
    const body = { ...values, content: values?.content, status: createTicketForm.getFieldValue('status'), owner_name: undefined };

    // add SF opportunity fields
    if (true === snowflakeOpportunitySupport) {
      body.props = {
        OPPORTUNITY_AGENTS: opportunityDetails?.OPPORTUNITY_AGENTS,
        OPPORTUNITY_CURRENCY_ISO_CODE: opportunityDetails?.OPPORTUNITY_CURRENCY_ISO_CODE,
        OPPORTUNITY_GROSS_ACV_BOOKING: opportunityDetails?.OPPORTUNITY_GROSS_ACV_BOOKING,
        OPPORTUNITY_GROSS_ACV_BOOKING_USD: opportunityDetails?.OPPORTUNITY_GROSS_ACV_BOOKING_USD,
        OPPORTUNITY_COUNTRY_REGION: opportunityDetails?.OPPORTUNITY_COUNTRY_REGION,
        OPPORTUNITY_COUNTRY_SUB_REGION: opportunityDetails?.OPPORTUNITY_COUNTRY_SUB_REGION,
        OPPORTUNITY_DEAL_TYPE: opportunityDetails?.OPPORTUNITY_DEAL_TYPE,
        OPPORTUNITY_DIRECT_INDIRECT_SALE: opportunityDetails?.OPPORTUNITY_DIRECT_INDIRECT_SALE,
        OPPORTUNITY_SALES_SEGMENT: opportunityDetails?.OPPORTUNITY_SALES_SEGMENT,
        OPPORTUNITY_SEATS: opportunityDetails?.OPPORTUNITY_SEATS,
        OPPORTUNITY_STAGE_NAME: opportunityDetails?.OPPORTUNITY_STAGE_NAME,
        OPPORTUNITY_OWNER_NAME: opportunityDetails?.OPPORTUNITY_OWNER_NAME,
        OPPORTUNITY_OWNER_EMAIL: opportunityDetails?.OPPORTUNITY_OWNER_EMAIL,
        OPPORTUNITY_SC_NAME: opportunityDetails?.OPPORTUNITY_SC_NAME,
        OPPORTUNITY_SC_EMAIL: opportunityDetails?.OPPORTUNITY_SC_EMAIL,
        OPPORTUNITY_DSR_URL: opportunityDetails?.OPPORTUNITY_DSR_URL,
      };
    }

    await executeAsyncProcess(async () => {
      try {
        const ticket = await postTicket(body);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Ticket submitted successfully',
          duration: 3,
        });
        if (ticket) {
          navigate(`/teacket/tickets/${ticket.id}`);
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleFormOnFailure = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleFormReset = () => {
    setSubCategoryList([]);
    setCategoryList([]);
    setPriorityList([]);
    setDeploymentOptions(null);
    setGcDeploymentSupport(null);
  };

  const handleOnServiceChange = async (value) => {
    console.log('Selected service:', value);

    // <reset owner>
    createTicketForm.setFieldValue('owner_id', userInfo.id);
    createTicketForm.setFieldValue('owner_name', userInfo.name);
    // </reset owner>

    // <reset form options>
    setDefaultStatus(value);
    setPriorityOptions(value);
    setCategoryOptions(value);
    // </reset form options>

    // <reset selected acc or opp>
    createTicketForm.setFieldValue('acc_id', undefined);
    createTicketForm.setFieldValue('acc_name', undefined);
    createTicketForm.setFieldValue('opp_id', undefined);
    createTicketForm.setFieldValue('opp_name', undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);
    // </reset selected acc or opp>

    // <determine if the current user's tenant is a master one for the selected service>
    setIsMaster(null);
    const im = await isTenantMasterForService(value);
    setIsMaster(im);
    // </determine if the current user's tenant is a master one for the selected service>

    // <determine account>
    if (true !== im) {
      await determineAccount();
    }
    // </determine account>

    // <determine if snowflake opportunity support is enabled>
    const sf = getServiceSettingForServiceId(value, 'SnowflakeOpportunitySupport');
    console.log('sf', sf);
    setSnowflakeOpportunitySupport('1' === sf);
    // </determine if snowflake opportunity support is enabled>

    // <determine if GC deployment support is enabled>
    const gc = getServiceSettingForServiceId(value, 'GcDeploymentSupport');
    console.log('gc', gc);
    setGcDeploymentSupport('1' === gc);
    // </determine if GC deployment support is enabled>
  };

  const handleOnDeploymentChange = (value) => {
    console.log('Selected deployment:', value);
    const deployment = deploymentOptions.find((item) => item.ORG_ID === value);
    console.log('deployment', deployment?.ORG_NAME__C);
    createTicketForm.setFieldValue('dep_name', deployment?.ORG_NAME__C);
  };

  const handleOnCategoryChange = (value) => {
    console.log('Selected category:', value);
    setSubCategoryOptions(value);
  };

  const handleAccountModalOnOk = () => {
    console.log('handleAccountModalOnOk');
    createTicketForm.setFieldValue('acc_id', account?.ACCOUNT_ID ?? undefined);
    createTicketForm.setFieldValue('acc_name', account?.ACCOUNT_NAME ?? undefined);
    createTicketForm.setFieldValue('opp_id', undefined);
    createTicketForm.setFieldValue('opp_name', undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);
    setAccountModalOpen(false);
    if (gcDeploymentSupport && account?.ACCOUNT_ID) getDeploymentsForAccount(account?.ACCOUNT_ID);
  };

  const handleOpportunityModalOnOk = () => {
    console.log('handleOpportunityModalOnOk');
    createTicketForm.setFieldValue('acc_id', opportunity?.ACCOUNT_ID ?? undefined);
    createTicketForm.setFieldValue('acc_name', opportunity?.ACCOUNT_NAME ?? undefined);
    createTicketForm.setFieldValue('opp_id', opportunity?.OPPORTUNITY_ID ?? undefined);
    createTicketForm.setFieldValue('opp_name', opportunity?.OPPORTUNITY_NAME ?? undefined);
    createTicketForm.setFieldValue('dep_id', undefined);
    createTicketForm.setFieldValue('dep_name', undefined);

    setOpportunityModalOpen(false);
    if (gcDeploymentSupport && account?.ACCOUNT_ID) getDeploymentsForAccount(account?.ACCOUNT_ID);

    //TODO: OnConfirm - go here
    setOpportunityDetails(opportunity);
  };

  const handleAccountModalOnSelect = async (v) => {
    console.log('handleAccountModalOnSelect', v);
    setAccount(v);
  };

  const handleRequesterModalOnOk = () => {
    console.log('handleRequesterModalOnOk');
    createTicketForm.setFieldValue('owner_id', requester?.user_id ?? undefined);
    createTicketForm.setFieldValue('owner_name', requester?.user_name ?? undefined);
    setRequesterModalOpen(false);
  };

  const handleOpportunityModalOnSelect = (v) => {
    console.log('handleOpportunityModalOnSelect', v);
    setOpportunity(v);
  };

  const handleRequesterModalOnSelect = (v) => {
    console.log('handleRequesterModalOnSelect', v);
    setRequester(v);
  };

  //#endregion

  //#region "VALUES MANIPULATION"

  const [priorityList, setPriorityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  console.log('servicesForTenant', servicesForTenant);

  const setPriorityOptions = async (service_id) => {
    console.log('setPriorityOptions', service_id);
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      let prioList = null;
      if (true === (await isTenantMasterForService(service_id))) {
        prioList = true === notEmptyArray(service?.props?.priorityList) ? service?.props?.priorityList : [];
      } else {
        prioList = true === notEmptyArray(service?.props?.priorityList) ? service?.props?.priorityList.filter((x) => !x.masterOnly) : [];
      }
      setPriorityList(prioList);
      const defaultPriority = service?.props?.priorityList?.find((status) => true === status.default);
      if (!defaultPriority) return;
      createTicketForm.setFieldValue('priority', defaultPriority.priority);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load priority options',
        duration: 3,
      });
    }
  };

  const setDefaultStatus = (service_id) => {
    console.log('setDefaultStatus', service_id);
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      const defaultStatus = service?.props?.statusList?.find((status) => true === status.default);
      if (!defaultStatus) throw new Error('Default status not found');
      createTicketForm.setFieldValue('status', defaultStatus.status);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load status',
        duration: 3,
      });
    }
  };

  const setCategoryOptions = (service_id) => {
    console.log('setCategoryOptions', service_id);
    try {
      const service = servicesForTenant?.find((service) => service.id === service_id);
      setCategoryList(true === notEmptyArray(service?.props?.categoryList) ? service.props?.categoryList : []);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load category options',
        duration: 3,
      });
    }
  };

  const setSubCategoryOptions = (category) => {
    console.log('setSubCategoryOptions', category);
    try {
      const service = servicesForTenant?.find((service) => service.id === createTicketForm.getFieldValue('service_id'));
      const categoryItem = service?.props?.categoryList?.find((item) => item.category === category);
      setSubCategoryList(true === notEmptyArray(categoryItem?.items) ? categoryItem.items : []);
    } catch (error) {
      console.error(error);
      messageApi.open({
        key: uuid(),
        type: 'error',
        content: 'Failed to load sub category options',
        duration: 3,
      });
    }
  };
  //#endregion

  //#region "MISC"

  const isTenantMasterForService = async (service_id) => {
    let result = null;
    await executeAsyncProcess(async () => {
      try {
        const resp = await getServicesIsMaster(service_id);
        result = resp?.isMaster === true;
      } catch (error) {
        console.error(error);
        return false;
      }
    });
    return result;
  };

  const determineAccount = async () => {
    await executeAsyncProcess(async () => {
      try {
        const { ACCOUNT_ID: acc_id, ACCOUNT_NAME: acc_name, deployments } = await getAccountForOrgId(true);
        createTicketForm.setFieldValue('acc_id', acc_id);
        createTicketForm.setFieldValue('acc_name', acc_name);
        if (deployments) setDeploymentOptions(deployments);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getDeploymentsForAccount = async (acc_id) =>
    await executeAsyncProcess(async () => {
      try {
        const deployments = await getAccountDeployments(acc_id);
        setDeploymentOptions(deployments);
      } catch (error) {
        console.error(error);
      }
    });

  //#endregion

  return (
    <div>
      {contextHolder}
      {accountModalOpen && (
        <Modal
          title={'Search for account'}
          open={true}
          onCancel={() => {
            setAccountModalOpen(false);
          }}
          onOk={handleAccountModalOnOk}
        >
          <SnowflakeAccountPicker onSelect={handleAccountModalOnSelect} />
        </Modal>
      )}
      {opportunityModalOpen && (
        <Modal
          title={'Search for opportunity'}
          open={true}
          onCancel={() => {
            setOpportunityModalOpen(false);
          }}
          onOk={handleOpportunityModalOnOk}
          okButtonProps={{ disabled: isOkDisabled }}
        >
          <SnowflakeOpportunityPicker onSelect={handleOpportunityModalOnSelect} setBusy={setIsOkDisabled} limit={20} />
        </Modal>
      )}
      {requesterModalOpen && (
        <Modal
          title={'Search for requester'}
          open={true}
          onCancel={() => {
            setRequesterModalOpen(false);
          }}
          onOk={handleRequesterModalOnOk}
        >
          <SearchServiceUser serviceId={createTicketForm.getFieldValue('service_id')} onSelect={handleRequesterModalOnSelect} />
        </Modal>
      )}

      <Form form={createTicketForm} name='ticket-create-form' initialValues={{ remember: true }} onFinish={handleFormOnFinish} onFinishFailed={handleFormOnFailure} onReset={handleFormReset} autoComplete='off' layout='vertical'>
        <div>
          <div>
            <Form.Item
              label={
                <Space className='btn'>
                  Service
                  {isMaster && (
                    // 'conductor' as a proper replacement for 'master'
                    <Tooltip title='Your tenant is a conductor for the selected service' mouseEnterDelay={1}>
                      <Tag color='orange' style={{ margin: 0, fontSize: '0.8rem', lineHeight: '0.8rem' }}>
                        C
                      </Tag>
                    </Tooltip>
                  )}
                </Space>
              }
              name='service_id'
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select placeholder='Select a service...' onChange={handleOnServiceChange}>
                {servicesForTenant?.map((service) => (
                  <Select.Option key={service.id} value={service.id}>
                    {service.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {createTicketForm.getFieldValue('service_id') && (
              <div className='mb-6'>
                {/* <SF ACCOUNT OPPORTUNITY> */}
                <PropertySection>
                  <div className='mx-2 mt-2'>
                    <div className='flex flex-row gap-2'>
                      <div className='flex-initial w-56'>
                        <Form.Item label='Account Id' name='acc_id' rules={[{ required: false }]}>
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                      <div className='flex-auto'>
                        <Form.Item label='Account Name' name='acc_name' rules={[{ required: false }]}>
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                      <div>
                        {true === isMaster && (
                          <Form.Item label=' '>
                            <Button type='primary' icon={<SearchOutlined />} onClick={() => setAccountModalOpen(true)}></Button>
                          </Form.Item>
                        )}
                      </div>
                    </div>
                    {deploymentOptions?.length === 0 && <Alert className='mb-4' style={{ width: '100%' }} message='No deployments were found for this account' type='info' showIcon closable />}
                    {true === isMaster && true === snowflakeOpportunitySupport && (
                      <>
                        <div className='flex flex-row gap-2 items-start'>
                          <div className='flex-initial w-56'>
                            <Form.Item label='Opportunity ID' name='opp_id'>
                              <Input disabled={true} />
                            </Form.Item>
                          </div>
                          <div className='flex-auto'>
                            <Form.Item label='Opportunity Name' name='opp_name'>
                              <Input disabled={true} />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item label=' '>
                              <Button type='primary' icon={<SearchOutlined />} onClick={() => setOpportunityModalOpen(true)}></Button>
                            </Form.Item>
                          </div>
                        </div>
                        <div className='pb-4'>{opportunity && <OpportunityDetails serviceId={createTicketForm.getFieldValue('service_id')} opportunity={opportunityDetails} onChange={setOpportunityDetails} />}</div>
                      </>
                    )}

                    {/* {true === isMaster && (
                      <div className='flex flex-row gap-2 justify-center mb-4'>
                        <Button size='small' type='primary' icon={<SearchOutlined />} onClick={() => setAccountModalOpen(true)}>
                          Search for account
                        </Button>

                        {true === snowflakeOpportunitySupport && (
                          <Button size='small' type='primary' icon={<SearchOutlined />} onClick={() => setOpportunityModalOpen(true)}>
                            Search for opportunity
                          </Button>
                        )}
                      </div>
                    )} */}
                  </div>
                </PropertySection>
                {/* </ SF ACCOUNT OPPORTUNITY> */}
              </div>
            )}
            {createTicketForm.getFieldValue('service_id') && true === isMaster && (
              <div className='mb-6'>
                {/* <REQUESTER> */}
                <PropertySection>
                  <div className='mx-2 mt-2'>
                    <div className='flex flex-row gap-2'>
                      <div className='flex-initial w-56'>
                        <Form.Item label='Requester ID' name='owner_id' rules={[{ required: true, message: 'This field is required' }]}>
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                      <div className='flex-auto'>
                        <Form.Item label='Requester Name' name='owner_name' rules={[{ required: true, message: 'This field is required' }]}>
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item label=' '>
                          <Button type='primary' icon={<SearchOutlined />} onClick={() => setRequesterModalOpen(true)}></Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </PropertySection>
                {/* </ REQUESTER> */}
              </div>
            )}
            {gcDeploymentSupport && true === notEmptyArray(deploymentOptions) && (
              <Form.Item label='GC Deployment' name='dep_id'>
                <Select placeholder='Select a deployment...' onChange={handleOnDeploymentChange}>
                  {deploymentOptions?.map((item) => (
                    <Select.Option key={item.ORG_ID} value={item.ORG_ID}>
                      {item.ORG_NAME__C}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item label='Category' name='category' rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder='Select a category...' onChange={handleOnCategoryChange} disabled={!createTicketForm.getFieldValue('service_id')}>
                {categoryList?.map((item) => (
                  <Select.Option key={item.category} value={item.category}>
                    {item.category}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Sub Category' name='sub_category' rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder='Select a sub category...' disabled={!createTicketForm.getFieldValue('category')}>
                {subCategoryList?.map((item) => (
                  <Select.Option key={item.subCategory} value={item.subCategory}>
                    {item.subCategory}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Priority' name='priority' rules={[{ required: true, message: 'This field is required' }]}>
              <Select placeholder='Select a priority...' disabled={!createTicketForm.getFieldValue('service_id')}>
                {priorityList?.map((item) => (
                  <Select.Option key={item.priority} value={item.priority}>
                    {item.priority}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Title'
              name='title'
              rules={[
                { required: true, message: 'This field is required' },
                { min: 50, message: 'This should contain at least 50 characters' },
              ]}
            >
              <Input placeholder='Enter a title for this ticket (50 characters min.)...' autoSize={{ minRows: 2, maxRows: 6 }} maxLength={TITLE_MAX_LENGTH} showCount disabled={!createTicketForm.getFieldValue('service_id')} />
            </Form.Item>
            <Form.Item label='Problem description' name='content' rules={[{ required: true, message: 'This field is required' }]}>
              <Input.TextArea placeholder='What is your problem?' autoSize={{ minRows: 4, maxRows: 8 }} maxLength={CONTENT_MAX_LENGTH} showCount disabled={!createTicketForm.getFieldValue('service_id')} />
            </Form.Item>
            <Form.Item name='owner_id' hidden={true} />
            <Form.Item name='dep_name' hidden={true} />
          </div>
        </div>
        <div className='mt-4'>
          <Form.Item>
            <Space>
              <Button htmlType='reset'>Reset</Button>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

TicketCreateControl.propTypes = {};

export default TicketCreateControl;
