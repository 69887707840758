import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { MdSecurity } from 'react-icons/md';
import { Space } from 'antd';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import Options from './Components/Options/OptionsWidget';
import FineTuneSecurity from './Components/Options/SecurityFineTuningWidget';
import { MdSettings } from 'react-icons/md';
import JobLoader from './Components/JobLoader';
import HelpSettingsWidget from './Components/Options/HelpSettingsWidget';

//import PropTypes from 'prop-types';

const Events = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  const componentsGallery = [
    {
      id: 'Fine Tune Security',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdSettings />
              Fine Tune Security
            </Space>
          }
          centered>
          <FineTuneSecurity />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Options',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdSettings />
              Options
            </Space>
          }
          centered>
          <Options />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Help',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdSettings />
              Help
            </Space>
          }
          centered>
          <HelpSettingsWidget />
        </DraggableLayoutItem>
      ),
    },
  ];

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Security Advisor',
        path: '/security-advisor',
      },

      {
        title: 'Options',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.securityadvisor.view']}>
      <TitleBar
        title={
          <Space className='btn'>
            <MdSecurity />
            Security Advisor
            <span className='font-thin'>Options</span>
          </Space>
        }>
        {layoutItemsSelector}
      </TitleBar>
      <JobLoader>
        <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={3} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutSecurityAdvisorOptions} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutSecurityAdvisorOptionsHidden} />
      </JobLoader>
    </PermissionsProvider>
  );
};

// Events.propTypes = {};

export default Events;
