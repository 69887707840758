import React, { Component } from 'react';
import TeacketContext from './TeacketContext';
import { notArrayOrEmpty } from 'src/misc/Misc';

var currentTicketConversationGlobal = null;
export default class GlobalProvider extends Component {
  state = {
    // SERVICES
    servicesForTenant: null,
    // CURRENT TICKET
    currentTicket: null,
    currentTicketConversation: null,
    currentTicketIsMasterTenant: null,
    currentTicketMembers: null,
    currentTicketReadOnly: true,
    currentTicketHistory: null,
    currentTicketHistoryForceReload: null,
    currentTicketCustomFields: null,
    currentTicketS3FilesList: null,
    currentTicketTimeTracking: null,
    activeViewers: [],
    // USER TICKET LIST
    userTicketList: null,
    userTicketListRefresh: false,
    // MY TICKETS
    myTickets: null,
    myTicketsPageSize: 8,
    myTicketsCurrentPage: 1,
    myTicketsTotal: 0,
    myTicketsSelectedTicket: null,
    myTicketsLayoutItemsSelector: null,
    myTicketsQuery: null,
    myTicketsStatuses: null,
    myTicketsPriorities: null,
    // DASHBOARD
    dashboardLayoutItemsSelector: null,
    dashboardListServices: null,
    dashboardStartDate: null,
    dashboardEndDate: null,
    dashboardDataTickets: null,
    // TICKET LIST
    ticketListSelectedTicket: null,
    ticketList: null,
    ticketListTotal: null,
    ticketListCurrentPage: null,
    ticketListQuery: null,
    ticketListServices: null,
    ticketListPriorities: null,
    ticketListStatuses: null,
    ticketListUsers: null,
    ticketListQuerying: false,
    ticketListStartDate: null,
    ticketListEndDate: null,

    // OTHERS
    dashboardUser: null,
    ticketAfterUpdateSync: null,
  };

  render() {
    return (
      <TeacketContext.Provider
        value={{
          ...this.state,

          // ### SERVICES ###
          setServicesForTenant: (servicesForTenant) => this.setState({ servicesForTenant }),
          getServicePropsForDerviceId: (serviceId, propName) => {
            if (!serviceId) return null;
            if (!propName) return null;
            if (true === notArrayOrEmpty(this.state.servicesForTenant)) return null;

            const service = this.state.servicesForTenant.find((s) => s.id === serviceId);
            if (!service.props) return null;
            return service.props[propName];
          },
          getServiceSettingForServiceId: (serviceId, settingName) => {
            const service = this.state.servicesForTenant.find((s) => s.id === serviceId);
            if (!service) return null;
            const { settingList } = service.props ?? {};
            if (notArrayOrEmpty(settingList)) return null;
            const setting = settingList.find((s) => s.name === settingName);
            if (!setting?.value) return null;
            return setting.value;
          },
          getWidgetListForCurrentTicket: () => {
            if (!this.state.currentTicket) return null;
            const service = this.state.servicesForTenant.find((s) => s.id === this.state.currentTicket.service_id);
            if (!service) return null;
            const { widgetList } = service.props ?? {};
            if (notArrayOrEmpty(widgetList)) return [];
            return widgetList;
          },
          getServiceSettingForCurrentTicket: (settingName) => {
            if (!this.state.currentTicket) return null;
            const service = this.state.servicesForTenant.find((s) => s.id === this.state.currentTicket.service_id);
            if (!service) return null;
            const { settingList } = service.props ?? {};
            if (notArrayOrEmpty(settingList)) return null;
            const setting = settingList.find((s) => s.name === settingName);
            if (!setting?.value) return null;
            return setting.value;
          },
          // ### CURRENT TICKET ###
          setCurrentTicket: (ticket, isMaster) => this.setState({ currentTicket: ticket, currentTicketIsMasterTenant: isMaster }),
          updateCurrentTicket: (updates) => {
            const currentTicket = { ...this.state.currentTicket, ...updates };
            this.setState({ currentTicket });
          },
          setCurrentTicketConversation: (conversation) => {
            currentTicketConversationGlobal = conversation;
            this.setState({
              currentTicketConversation: conversation,
            });
          },
          addCurrentTicketConversationMessage: (message) => {
            let c = [...currentTicketConversationGlobal].filter((m) => !m.isPosting);
            c.push(message);
            c = c.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            currentTicketConversationGlobal = c;
            this.setState({ currentTicketConversation: c });
          },
          updateCurrentTicketConversationMessage: (message) => {
            message.isPosting = false;
            let c = [...currentTicketConversationGlobal];
            const index = c.findIndex((conv) => conv.id === message.id);
            if (index !== -1) {
              c[index] = { ...c[index], ...message };
              currentTicketConversationGlobal = c;
              this.setState({ currentTicketConversation: c });
            } else {
              console.log(`Conversation message with id ${c.id} not found.`);
            }
          },
          setCurrentTicketMembers: (members) => this.setState({ currentTicketMembers: members }),
          setCurrentTicketReadOnly: (readOnly) => this.setState({ currentTicketReadOnly: readOnly }),
          setCurrentTicketHistory: (history) => this.setState({ currentTicketHistory: history }),
          setCurrentTicketHistoryForceReload: (forceReload) => this.setState({ currentTicketHistoryForceReload: forceReload }),
          setCurrentTicketCustomFields: (customFields) => this.setState({ currentTicketCustomFields: customFields }),
          setCurrentTicketS3FilesList: (currentTicketS3FilesList) => this.setState({ currentTicketS3FilesList }),
          getCurrentTicketService: () => this.state.servicesForTenant.find((s) => s.id === this.state.currentTicket.service_id),
          setActiveViewers: (activeViewers) => this.setState({ activeViewers }),
          resetCurrentTicket: () => {
            this.setState({
              currentTicket: null,
              currentTicketConversation: null,
              currentTicketIsMasterTenant: null,
              currentTicketMembers: null,
              currentTicketReadOnly: true,
              currentTicketHistory: null,
              currentTicketS3FilesList: null,
              currentTicketCustomFields: null,
              currentTicketHistoryForceReload: null,
              currentTicketTimeTracking: null,
            });
          },

          // ### USER TICKET LIST ###
          setUserTicketList: (userTicketList) => this.setState({ userTicketList }),
          setUserTicketListRefresh: (userTicketListRefresh) => this.setState({ userTicketListRefresh }),

          // ### MY TICKETS ###
          setMyTickets: (myTickets) => this.setState({ myTickets }),
          setMyTicketsCurrentPage: (myTicketsCurrentPage) => this.setState({ myTicketsCurrentPage }),
          setMyTicketsTotal: (myTicketsTotal) => this.setState({ myTicketsTotal }),
          setMyTicketsSelectedTicket: (myTicketsSelectedTicket) => this.setState({ myTicketsSelectedTicket }),
          setMyTicketsLayoutItemsSelector: (myTicketsLayoutItemsSelector) => this.setState({ myTicketsLayoutItemsSelector }),
          setMyTicketsQuery: (myTicketsQuery) => this.setState({ myTicketsQuery }),
          setMyTicketsStatuses: (myTicketsStatuses) => this.setState({ myTicketsStatuses }),
          setMyTicketsPriorities: (myTicketsPriorities) => this.setState({ myTicketsPriorities }),

          // ### DASHBOARD ###
          setDashboardLayoutItemsSelector: (dashboardLayoutItemsSelector) => this.setState({ dashboardLayoutItemsSelector }),
          setDashboardListServices: (dashboardListServices) => this.setState({ dashboardListServices }),
          setDashboardStartDate: (dashboardStartDate) => this.setState({ dashboardStartDate }),
          setDashboardEndDate: (dashboardEndDate) => this.setState({ dashboardEndDate }),
          setDashboardDataTickets: (dashboardDataTickets) => this.setState({ dashboardDataTickets }),

          // ### TICKET LIST ###
          setTicketListSelectedTicket: (ticketListSelectedTicket) => this.setState({ ticketListSelectedTicket }),
          setTicketList: (ticketList) => this.setState({ ticketList }),
          setTicketListTotal: (ticketListTotal) => this.setState({ ticketListTotal }),
          setTicketListCurrentPage: (ticketListCurrentPage) => this.setState({ ticketListCurrentPage }),
          setTicketListQuery: (ticketListQuery) => this.setState({ ticketListQuery }),
          setTicketListServices: (ticketListServices) => this.setState({ ticketListServices }),
          setTicketListPriorities: (ticketListPriorities) => this.setState({ ticketListPriorities }),
          setTicketListStatuses: (ticketListStatuses) => this.setState({ ticketListStatuses }),
          setTicketListUsers: (ticketListUsers) => this.setState({ ticketListUsers }),
          setTicketListQuerying: (ticketListQuerying) => this.setState({ ticketListQuerying }),
          setTicketListStartDate: (ticketListStartDate) => this.setState({ ticketListStartDate }),
          setTicketListEndDate: (ticketListEndDate) => this.setState({ ticketListEndDate }),

          // ### OTHERS ###
          setDashboardUser: (dashboardUser) => this.setState({ dashboardUser }),
          setTicketAfterUpdateSync: (flag) => this.setState({ ticketAfterUpdateSync: flag }),
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </TeacketContext.Provider>
    );
  }
}
