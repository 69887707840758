import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const RangePickerDayJs = ({ showDropdown, startDate, endDate, disabled, onChange, predefinedToday, predefinedYesterday, predefinedQuarter, predefinedMonth, predefined30days, predefinedWeek, predefinedWeekBefore, predefined7days }) => {
  const [browserTimeZone, setBrowserTimeZone] = useState('UTC');

  useEffect(() => {
    const tz = getBrowserTimeZone();
    setBrowserTimeZone(tz);
  }, []);

  const predefinedIntervalListOnClick = (e) => {
    console.log('[RangePicker] predefinedIntervalListOnClick()', e);
    if (!e || !e.key) {
      return;
    }
    const interval = getDatesForPredefinedInterval(e.key, browserTimeZone);
    if (onChange) onChange({ startDate: dayjs(interval[0]).startOf('day'), endDate: dayjs(interval[1]).endOf('day') }, false);
  };

  const predefinedIntervalList = (
    <Menu
      onClick={predefinedIntervalListOnClick}
      items={[
        predefinedToday && { key: 'today', label: 'Today' },
        predefinedYesterday && { key: 'yesterday', label: 'Yesterday' },
        predefined7days && { key: 'past-7-days', label: 'Past 7 days' },
        predefinedWeek && { key: 'week-to-date', label: 'This week to date' },
        predefinedWeek && { key: 'last-week', label: 'Last week' },
        predefinedWeekBefore && { key: 'last-week-before-1', label: '1 week before last week' },
        predefinedWeekBefore && { key: 'last-week-before-2', label: '2 weeks before last week' },
        predefinedWeekBefore && { key: 'last-week-before-3', label: '3 weeks before last week' },
        predefined30days && { key: 'past-30-days', label: 'Past 30 days' },
        predefinedMonth && { key: 'month-to-date', label: 'This month to date' },
        predefinedMonth && { key: 'last-month', label: 'Last month' },
        predefinedQuarter && { key: 'quarter-to-date', label: 'This quarter to date' },
        predefinedQuarter && { key: 'last-quarter', label: 'Last quarter' },
      ]}
    />
  );

  const getDatesForPredefinedInterval = (predefinedInterval, timezone) => {
    console.log(`[RangePicker] getDatesForPredefinedIInterval(${predefinedInterval}, ${timezone});`);
    let interval = null;
    switch (predefinedInterval) {
      case 'today':
        interval = [dayjs().tz(timezone).startOf('day').format(), dayjs().tz(timezone).endOf('day').format()];
        break;
      case 'yesterday':
        interval = [dayjs().tz(timezone).subtract(1, 'days').startOf('day').format(), dayjs().tz(timezone).subtract(1, 'days').endOf('day').format()];
        break;
      case 'past-7-days': {
        interval = [dayjs().tz(timezone).subtract(7, 'days').startOf('day').format(), dayjs().tz(timezone).subtract(1, 'days').endOf('day').format()];
        break;
      }
      case 'week-to-date':
        interval = [dayjs().tz(timezone).startOf('isoWeek').format(), dayjs().tz(timezone).endOf('day').format()];
        break;
      case 'last-week':
        interval = [dayjs().tz(timezone).subtract(1, 'weeks').startOf('isoWeek').format(), dayjs().tz(timezone).subtract(1, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-1':
        interval = [dayjs().tz(timezone).subtract(2, 'weeks').startOf('isoWeek').format(), dayjs().tz(timezone).subtract(2, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-2':
        interval = [dayjs().tz(timezone).subtract(3, 'weeks').startOf('isoWeek').format(), dayjs().tz(timezone).subtract(3, 'weeks').endOf('isoWeek').format()];
        break;
      case 'last-week-before-3':
        interval = [dayjs().tz(timezone).subtract(4, 'weeks').startOf('isoWeek').format(), dayjs().tz(timezone).subtract(4, 'weeks').endOf('isoWeek').format()];
        break;
      case 'past-30-days': {
        interval = [dayjs().tz(timezone).subtract(30, 'days').startOf('day').format(), dayjs().tz(timezone).subtract(1, 'days').endOf('day').format()];
        break;
      }
      case 'month-to-date':
        interval = [dayjs().tz(timezone).startOf('month').format(), dayjs().tz(timezone).endOf('day').format()];
        break;
      case 'last-month':
        interval = [dayjs().tz(timezone).subtract(1, 'months').startOf('month').format(), dayjs().tz(timezone).subtract(1, 'months').endOf('month').format()];
        break;
      case 'quarter-to-date':
        interval = [dayjs().tz(timezone).startOf('quarter').format(), dayjs().tz(timezone).endOf('day').format()];
        break;
      case 'last-quarter':
        interval = [dayjs().tz(timezone).subtract(1, 'quarters').startOf('quarter').format(), dayjs().tz(timezone).subtract(1, 'quarters').endOf('quarter').format()];
        break;
      default:
        throw new Error(`Unknown interval: ${predefinedInterval}`);
    }
    console.log('[RangePicker] interval', interval);
    return interval;
  };

  return (
    <div style={{ minWidth: '310px' }}>
      <RangePicker
        disabled={disabled}
        value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
        onChange={(e) => {
          // <handle clearing the range>
          if (!Array.isArray(e) || e.length !== 2) {
            if (onChange) onChange({ startDate: null, endDate: null });
            return;
          }
          // </handle clearing the range>

          if (onChange) onChange({ startDate: e[0].startOf('day'), endDate: e[1].endOf('day') }, true);
        }}
      />
      {showDropdown && (
        <Dropdown disabled={disabled} overlay={predefinedIntervalList} trigger={['click']}>
          <Button type='link' className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

RangePickerDayJs.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  predefinedToday: PropTypes.bool,
  predefinedYesterday: PropTypes.bool,
  predefinedQuarter: PropTypes.bool,
  predefinedMonth: PropTypes.bool,
  predefined30days: PropTypes.bool,
  predefinedWeek: PropTypes.bool,
  predefinedWeekBefore: PropTypes.bool,
  predefined7days: PropTypes.bool,
  showDropdown: PropTypes.bool,
};

RangePickerDayJs.defaultProps = {
  startDate: null,
  endDate: null,
  disabled: false,
  onChange: () => {
    console.warn('OnChange() function not provided');
  },
  predefinedQuarter: true,
  predefinedMonth: true,
  predefinedToday: true,
  predefinedYesterday: true,
  predefined30days: true,
  predefinedWeek: true,
  predefinedWeekBefore: false,
  predefined7days: true,
  showDropdown: true,
};

export default RangePickerDayJs;
