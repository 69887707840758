//import { sleepMs } from 'src/misc/Misc';
import { objToQueryStringParams } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

//#region "NEW NOTIFICATION CENTER"

export const getNotificationCenterMy = async (page = 1, pageSize = 10, origins = null, unread = null) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/my${objToQueryStringParams({ page, pageSize, origins, unread })}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchNotificationCenterMyMarkAsRead = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/my/${id}/markasread`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ is_read: true }),
    })
  );
};

export const deleteNotificationCenterMy = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/my/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ is_read: true }),
    })
  );
};

export const patchNotificationCenterMyMarkAsReadAll = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/my/markasread`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ is_read: true }),
    })
  );
};

export const deleteNotificationCenterMyAll = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/my`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ is_read: true }),
    })
  );
};

//#endregion

//#region "ADMIN MESSAGES"

export const deleteNotifications = async (message_history_id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/notifications${objToQueryStringParams({ message_history_id })}`, {
      method: 'DELETE',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};


var controllerAdminMessages;
export const getAdminMessages = async (query, page, pageSize, fields = ['id', 'title', 'important', 'created_at']) => {
  controllerAdminMessages?.abort('cancelled');
  controllerAdminMessages = new AbortController();
  const signal = controllerAdminMessages.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize, fields });
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/messages${queryStringParams}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getAdminMessage = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/messages/${id}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postAdminMessage = async (data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/messages`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const patchAdminMessage = async (id, data) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/messages/${id}`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const postAdminMessageTest = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/messages/${id}/test`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getTargetsCount = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/messages/${id}/targets-count`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postAdminMessageSend = async (id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/messages/${id}/send`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getAdminMessageHistory = async (query, page = 1, pageSize = 100) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/center/admin/messages-history${objToQueryStringParams({ query, page, pageSize })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

export const getMyNotifications = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/my`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getNotifications = async (userId) => {
  console.log('getNotifications for userId', userId);
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/users/${userId}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const markAsRead = async (notificationId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/${notificationId}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        is_read: true,
      }),
    })
  );
};

export const markAsReadAll = async (userId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/users/${userId}/markasread`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const markAsReadByMessageId = async (userId, messageId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/users/${userId}/${messageId}/markasread`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        is_read: true,
      }),
    })
  );
};

export const deleteAllByUserId = async (userId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/notifications/users/${userId}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        is_read: true,
      }),
    })
  );
};
