import React from 'react';
import Header from 'src/components/pages/Landing/components/ui/Header';
import { AiOutlineAudit } from 'react-icons/ai';
import { Space } from 'antd';
//import PropTypes from 'prop-types';

const AuditViewer = () => {
  return (
    <>
      <div className='fixed z-50'>
        <Header />
      </div>
      <div className='absolute flex flex-col items-center w-full'>
        <div className='mt-24 max-w-7xl '>
          <div className='my-8 font-bold text-2xl'>
            <Space>
              <AiOutlineAudit />
              Audit Viewer
            </Space>
          </div>
          <div>
            <a href='#installation' target='_self' className='decoration-solid hover:decoration-slice'>
              Requirements & Installation
            </a>
          </div>
          <div className='my-8'>Introducing Audit Viewer – your indispensable tool for gaining comprehensive insights into your organization's Genesys Cloud activities and ensuring transparency, accountability, and security. Designed with user-friendly functionality in mind, Audit Viewer empowers Genesys Cloud customers to effortlessly navigate and interpret audit logs through an intuitive and accessible interface.</div>
          <div className='flex justify-center'>
            <img src='/images/products/AuditViewer/Audit_Viewer_Screenshot.png' alt='Audit Viewer' className='shadow-2xl dark:shadow-genesys-gray-400 w-3/4 rounded-lg' />
          </div>
          <div className='my-8'>At its core, Audit Viewer provides a centralized platform for viewing and analyzing audit logs generated within your Genesys Cloud environment. With its streamlined interface, Audit Viewer enables users to quickly locate and review specific events, actions, or changes across a wide range of parameters, including user activity and configuration modifications.</div>
          <div className='my-8'>Audit Viewer also plays a crucial role in promoting accountability and transparency within organizations. By providing a detailed record of user actions and system activities, Audit Viewer helps organizations track changes, detect unauthorized access, and ensure compliance with internal policies and external regulations, such as GDPR, HIPAA, and PCI-DSS.</div>
          <div className='my-8'>
            To further enhance its capabilities, Audit Viewer supports real-time notifications, ensuring that stakeholders are immediately informed of critical events or suspicious activities. This feature allows organizations to respond swiftly to potential security threats, unauthorized changes, or compliance violations, thereby enhancing the overall security posture. By providing timely alerts, Audit Viewer enables proactive monitoring and swift intervention, helping organizations maintain
            control and visibility over their operations in an ever-evolving digital landscape.
          </div>
          <div className='flex justify-center'>
            <img src='/images/products/AuditViewer/Audit_Viewer_Notifications.png' alt='Audit Viewer' className='shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
          </div>
          <div className='my-8'>In today's fast-paced digital landscape, maintaining visibility and control over organizational activities is more important than ever. With Audit Viewer as your trusted companion, you can gain the insights you need to protect your data, safeguard your operations, and demonstrate compliance with confidence.</div>
          <div id='installation' className='my-8 font-bold text-2xl'>
            <Space>Requirements & Installation</Space>
          </div>
          <div className='my-8'>
            The Audit Viewer tool requires the following permissions to run:
            <ul className='list-disc list-inside'>
              <li>
                Audits &gt; Audit &gt; View <i>to view your audit logs</i>
              </li>
            </ul>
            <div className='my-8'>After creating a role for the users that contains this permission or assigning the permission directly to a user, you will need to logout and log back in this portal to gain full access to this tool. </div>
            <div>You may logout from this portal by using your profile picture on the top-right.</div>
            <div className='flex justify-center mt-4'>
              <img src='/images/products/User_Sign_Out.png' alt='Log out/Sign out' className='shadow-2xl dark:shadow-genesys-gray-400 rounded-lg' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Copilot.propTypes = {};

export default AuditViewer;
