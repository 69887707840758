import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { Button, Select } from 'antd';
import RangePicker from 'src/components/controls/RangePicker/RangePicker';
import { SendOutlined } from '@ant-design/icons';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import dayjs from 'dayjs';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getDashboardsTickets } from 'src/api/teacket';
import { notEmptyArray } from 'src/misc/Misc';

//import PropTypes from 'prop-types';

const DashboardFilters = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { servicesForTenant, dashboardListServices, setDashboardListServices, dashboardStartDate, setDashboardStartDate, dashboardEndDate, setDashboardEndDate, setDashboardDataTickets } = useContext(TeacketContext);

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    setDefaultRange();
  }, []);

  useEffect(() => {
    if (initiated) return;
    if (!dashboardStartDate) return;
    if (!dashboardEndDate) return;
    setInitiated(true);
    handleOnSubmit();
  }, [dashboardStartDate, dashboardEndDate]);

  const setDefaultRange = () => {
    const timezone = getBrowserTimeZone();
    const start = dayjs().tz(timezone).subtract(7, 'days').startOf('day');
    const end = dayjs().tz(timezone).subtract(1, 'days').endOf('day');
    setDashboardStartDate(start);
    setDashboardEndDate(end);
  };

  const handleOnSubmit = async () => {
    console.log('handleOnSubmit()');
    await executeAsyncProcess(async () => {
      try {
        const result = await getDashboardsTickets(dayjs(dashboardStartDate).format(), dayjs(dashboardEndDate).format(), true === notEmptyArray(dashboardListServices) ? dashboardListServices : null);
        // console.log('resultg', result);
        setDashboardDataTickets(result);
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <div className='flex flex-row flex-wrap gap-4'>
      <div>
        <RangePicker
          startDate={dashboardStartDate}
          endDate={dashboardEndDate}
          onChange={(v) => {
            setDashboardStartDate(v.startDate);
            setDashboardEndDate(v.endDate);
          }}
        />
      </div>
      <div>
        <Select
          mode='multiple'
          value={dashboardListServices}
          onChange={(v) => {
            setDashboardListServices(v);
          }}
          className='w-60'
          options={servicesForTenant?.map((x) => {
            return { value: x.id, label: x.name };
          })}
          allowClear
          placeholder='[all services]'
        />
      </div>
      <div>
        <Button onClick={handleOnSubmit}>
          <SendOutlined />
        </Button>
      </div>
    </div>
  );
};

DashboardFilters.propTypes = {};

export default DashboardFilters;
