import React, { useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useTheme from 'src/hooks/useTheme';
import { getCopilotUsageData } from 'src/api/securityqa';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { eachWeekOfInterval, format } from 'date-fns';

const GraphCopilotUsage = () => {
  const { isDarkMode } = useTheme();
  const [data, setData] = useState(undefined);
  const [labels, setLabels] = useState(undefined);
  const [weeklyData, setWeeklyData] = useState(undefined);
  const [weeklyLabels, setWeeklyLabels] = useState(undefined);
  const [loadingUsageData, setLoadingUsageData] = useState(false);

  const themeMode = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const loadData = async () => {
    try {
      setLoadingUsageData(true);
      const resp = await getCopilotUsageData();
      // console.log('@@Data', resp);

      //#region Line chart data

      let count = 0;
      setData(resp.map((item) => ++count));
      setLabels(resp.map((item) => new Date(item.created_at)));

      //#endregion

      //#region Weekly chart data

      const weeks = eachWeekOfInterval({
        start: new Date(resp[0].created_at),
        end: new Date(resp[resp.length - 1].created_at),
      });
      setWeeklyLabels(weeks.map((week) => format(week, 'yyyy-MM-dd')));

      // Aggregate usage data into weekly buckets
      const weeklyData = weeks.map((weekStart, index) => {
        const weekEnd = index + 1 < weeks.length ? weeks[index + 1] : new Date();
        const weekUsage = resp.reduce((acc, item, i) => {
          const date = new Date(item.created_at);
          if (date >= weekStart && date < weekEnd) {
            return acc + 1;
          }
          return acc;
        }, 0);
        return weekUsage;
      });
      setWeeklyData(weeklyData);

      //#endregion
    } catch (error) {
      console.log('@@Error');
      console.error(error);
    } finally {
      setLoadingUsageData(false);
    }
  };

  useEffect(() => {
    if (!data) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log('@@Data', data);
  // }, [data]);

  // useEffect(() => {
  //   console.log('@@Labels', labels);
  // }, [labels]);

  // useEffect(() => {
  //   console.log('@@weeklyData', weeklyData);
  // }, [weeklyData]);

  // useEffect(() => {
  //   console.log('@@weeklyLabels', weeklyLabels);
  // }, [weeklyLabels]);

  return (
    <ThemeProvider theme={themeMode}>
      <div>
        {data === undefined ? (
          <div className='text-center'>
            <Spin />
          </div>
        ) : (
          <>
            <div>
              <Card loading={loadingUsageData}>
                {data && labels && (
                  <LineChart
                    xAxis={[
                      {
                        scaleType: 'time',
                        data: labels,
                      },
                    ]}
                    series={[
                      {
                        type: 'line',
                        data,
                        showMark: false,
                      },
                    ]}
                    width={500}
                    height={300}
                    showHighlight={true}
                    showTooltip={true}
                  />
                )}
              </Card>
            </div>
            <div>
              <Card loading={loadingUsageData}>{data && weeklyData && <BarChart width={500} height={300} series={[{ data: weeklyData }]} xAxis={[{ data: weeklyLabels, scaleType: 'band' }]} showHighlight={true} showTooltip={true} />}</Card>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default GraphCopilotUsage;
