import React, { useState, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getAdminService } from 'src/api/teacket';
import PropTypes from 'prop-types';
import { Space, Tabs } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import { LuBuilding, LuLayoutDashboard } from 'react-icons/lu';
import { GrConfigure } from 'react-icons/gr';
import NoData from 'src/components/layout/NoData';
import TeacketServiceEditorConfiguration from './TeacketServiceEditorConfiguration';
import TeacketServiceEditorTenants from './TeacketServiceEditorTenants';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import useUserInfo from 'src/hooks/useUserInfo';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import TeacketServiceEditorWidgets from './TeacketServiceEditorWidgets';

const TeacketServiceEditor = ({ serviceId }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { permissions: userPermissions } = useUserInfo();

  const tabItems = [
    {
      key: 'configuration',
      label: (
        <Space className='btn'>
          <GrConfigure />
          Configuration
        </Space>
      ),
      children: <TeacketServiceEditorConfiguration serviceId={serviceId} />,
    },
    {
      key: 'tenants',
      label: (
        <Space className='btn'>
          <LuBuilding />
          Tenants Assignments
        </Space>
      ),
      children: serviceId && <TeacketServiceEditorTenants serviceId={serviceId} />,
      permissions: [MASTER_ADMIN_PERMISSION],
    },
    {
      key: 'widgets',
      label: (
        <Space className='btn'>
          <LuLayoutDashboard />
          Widgets Assignments
        </Space>
      ),
      children: serviceId && <TeacketServiceEditorWidgets serviceId={serviceId} />,
      permissions: [MASTER_ADMIN_PERMISSION],
    },
  ].filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions));

  const [service, setService] = useState(null);
  const [activeTab, setActiveTab] = useState(tabItems[0].key);

  useEffect(() => {
    setService(null);
    if (!serviceId) return;
    setActiveTab(tabItems[0].key);
    loadData();
  }, [serviceId]);

  const loadData = async (loadMore = false) => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getAdminService(serviceId);
        setService(resp);
      } catch (error) {
        if (error?.toString().includes('The user aborted a request')) return;
        console.log(error);
      }
    });
  };

  return (
    <DraggableLayoutItem
      title={
        service ? (
          <Space className='whitespace-nowrap btn'>
            <BsCupHotFill className='opacity-60' />
            <div className='overflow-hidden overflow-ellipsis'>{service?.name ?? 'unknown'}</div>
          </Space>
        ) : (
          ''
        )
      }
    >
      {service ? (
        <Tabs onTabClick={(key) => setActiveTab(key)} activeKey={activeTab} type='card' items={tabItems} size='small' />
      ) : (
        <div className='h-full flex flex-row justify-center items-center'>
          <NoData />
        </div>
      )}
    </DraggableLayoutItem>
  );
};

TeacketServiceEditor.propTypes = {
  serviceId: PropTypes.string,
};

export default TeacketServiceEditor;
