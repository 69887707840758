import React, { useState } from 'react';
import { Select, Spin, Space } from 'antd';
import { searchRoles } from 'src/api/accesscontrol';
import PropTypes from 'prop-types';
import { DEFAULT_ROLE } from 'src/misc/Config';

const { Option } = Select;

const RolePicker = ({ width, onPick }) => {
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    console.log('handleClear');
    setItemList([]);
    onPick(null);
  };

  const handleSearchForTenants = async (v) => {
    setItemList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await searchRoles(v);
      if (resp) {
        setItemList(resp);
      }
      setLoading(false);
    } catch (error) {
      if (error?.toString().includes('The user aborted a request')) return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnSelect = (v) => {
    console.log('RolePicker onSelect:', v);
    const role = itemList.find((u) => u.role === v);
    onPick(role);
  };

  return (
    <Select showSearch allowClear placeholder='type to search role...' notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null} filterOption={false} onSearch={handleSearchForTenants} onClear={handleClear} onSelect={handleOnSelect} style={{ width }} autoComplete='off'>
      {itemList.map((d) => (
        <Option key={d.role} disabled={d.role === DEFAULT_ROLE}>
          <Space className='btn'>
            <div>{d.role}</div>
          </Space>
        </Option>
      ))}
    </Select>
  );
};

RolePicker.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
};

RolePicker.defaultProps = {
  width: '300px',
};

export default RolePicker;
