import React, { useEffect, useState, useContext } from 'react';
import { Button, Input, Space, Spin, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { notEmptyArray } from 'src/misc/Misc';
import AiModelBadge from './AiModelBadge';
import AiModelBadgeSkeleton from './AiModelBadgeSkeleton';
import { TbUsersGroup } from 'react-icons/tb';
//import useMasterAdmin from 'src/hooks/useMasterAdmin';
import AIModelsContext from 'src/context/AIModelsContext';

import { searchAiModels } from 'src/api/aiModels';

var aiModelsCurrentPage = 1;

const AiModelSelector = ({ selected, onAiModelSearch, onAiModelSelected }) => {
  const items = [
    {
      key: 5,
      label: <>5</>,
    },
    {
      key: 15,
      label: <>15</>,
    },
    {
      key: 25,
      label: <>25</>,
    },
  ];

  //const { aiModels, setAiModels } = useMasterAdmin();
  const { aiModels, setAiModels } = useContext(AIModelsContext);

  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(parseInt(items[1].key));
  const [thereIsMore, setThereIsMore] = useState(false);

  useEffect(() => {
    console.log('[AiModelSelector] Ai models', aiModels);
  }, [aiModels]);

  useEffect(() => {
    aiModelsCurrentPage = 1;
    setAiModels(null);
    setThereIsMore(false);
    loadData();
  }, [query, pageSize]);

  const loadData = async (loadMore = false) => {
    setLoading(true);
    if (onAiModelSearch) onAiModelSearch(query);
    try {
      const q = query?.trim() ?? null;
      const resp = await searchAiModels(q?.length > 0 ? q : null, aiModelsCurrentPage, pageSize);
      aiModelsCurrentPage++;
      setThereIsMore(resp?.length === pageSize ? true : false);
      if (true === notEmptyArray(resp)) {
        if (loadMore) setAiModels([...aiModels, ...resp]);
        else setAiModels(resp);
      }
    } catch (error) {
      if (error?.toString().includes('The user aborted a request')) return;
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className='h-full max-h-full flex flex-col'>
      <div className='flex-initial p-4 flex flex-row justify-center'>
        <div className='flex flex-col'>
          <Input
            className='w-60'
            placeholder='Search...'
            prefix={loading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
            allowClear
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />

          <div>
            <Dropdown
              trigger={['click']}
              menu={{
                activeKey: pageSize,
                items,
                onClick: (e) => {
                  console.log(e);
                  setPageSize(parseInt(e.key));
                },
              }}
              className='text-xs cursor-pointer'
            >
              <span className='text-blue-600 opacity-40 hover:opacity-100 '>page size {pageSize}</span>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className='relative flex-auto overflow-scroll flex flex-col justify-start items-center'>
        <div className='flex flex-row gap-4 p-4 flex-wrap max-w-2xl content-center justify-center'>
          {aiModels?.map((aiModel) => (
            <AiModelBadge
              key={aiModel.id}
              id={aiModel.id}
              name={aiModel.name}
              description={aiModel.description}
              onClick={(aiModelId) => {
                if (onAiModelSelected) onAiModelSelected(aiModelId);
              }}
              isSelected={selected === aiModel.id}
              newCreated={aiModel.newCreated}
            />
          ))}
          {loading && (
            <div className='center'>
              <AiModelBadgeSkeleton />
            </div>
          )}
        </div>
        {!loading && thereIsMore && (
          <div>
            <Button
              onClick={() => {
                loadData(true);
              }}
            >
              <Space className='btn'>
                <TbUsersGroup />
                Load more
              </Space>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

AiModelSelector.propTypes = {
  selected: PropTypes.number,
  onAiModelSearch: PropTypes.func,
  onAiModelSelected: PropTypes.func,
};

export default AiModelSelector;
