//import { sleepMs } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

export const getWatermarkedFile = async (filename, spPath) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/watermarkedfile?filename=${encodeURIComponent(filename)}&driveId=b!t6zcu8HEHkiimIcTr95FUe4yWEXARABKsUq8lFt-RuAJb2YwvYn2QJXzW4vPZw5X&rootFolderName=${encodeURIComponent(spPath)}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal: AbortSignal.timeout(30000),
    })
  );
};

export const getFiles = async (spPath) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/files?driveId=b!t6zcu8HEHkiimIcTr95FUe4yWEXARABKsUq8lFt-RuAJb2YwvYn2QJXzW4vPZw5X&rootFolderName=${encodeURIComponent(spPath)}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal: AbortSignal.timeout(30000),
    })
  );
};

export const getFileStats = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/filestats`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal: AbortSignal.timeout(30000),
    })
  );
};
