import React, { useState } from 'react';
import { Select, Spin, Space } from 'antd';
import { getAccountsByName } from 'src/api/snowflake';
import { FiHash } from 'react-icons/fi';
import { TbWorldPin } from 'react-icons/tb';
import { PiIdentificationBadgeFill } from 'react-icons/pi';
import { FaRegBuilding } from 'react-icons/fa';
import PropTypes from 'prop-types';

const { Option } = Select;

const SnowflakeAccountPicker = ({ onSelect, limit, initialValue, displayOrganizationCount, showOnlyWithOrganization }) => {
  const [loading, setLoading] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(initialValue?.ACCOUNT_NAME ?? null);

  const handleSearch = async (query) => {
    setItemsList([]);
    if (query.length < 3) return;
    try {
      setLoading(true);
      let resp = await getAccountsByName(query, limit, showOnlyWithOrganization);
      if (resp) {
        setItemsList(resp);
      }
      setLoading(false);
    } catch (error) {
      if (error === 'cancelled') return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleClear = async () => {
    console.log('handleClear');
    setSelectedItem(null);
    setItemsList([]);
    if (onSelect) onSelect(null);
  };

  // const isOptionDisabled = (key) => {
  //   return true === notEmptyArray(rolePermissions?.filter((x) => x.permission === key));
  // };

  const handleAssign = async (itemId) => {
    console.log('handleAssign', itemId);
    const selectedItem = itemsList.find((x) => x.ACCOUNT_ID === itemId);
    setSelectedItem(itemId);
    if (onSelect) onSelect(selectedItem ?? { id: itemId });
  };

  return (
    <Select
      value={selectedItem}
      showSearch
      allowClear
      placeholder='type to find an account...'
      notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
      filterOption={false}
      onSearch={handleSearch}
      onClear={handleClear}
      onSelect={handleAssign}
      style={{ width: '100%' }}
      size='large'
      optionRender={(option) => {
        const item = itemsList.find((x) => x.ACCOUNT_ID === option.key);
        if (!item) return null;

        return (
          <div className='flex flex-row gap-2 my-1'>
            <div className='flex flex-col'>
              <div className='overflow-hidden'>{item.ACCOUNT_NAME}</div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space className='btn'>
                  <FiHash />
                  {item.ACCOUNT_ID}
                </Space>
              </div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space className='btn'>
                  <PiIdentificationBadgeFill />
                  {item.OWNER_NAME || 'N/A'}
                </Space>
              </div>
              {displayOrganizationCount && (
                <div className='text-xs font-light opacity-60 overflow-hidden'>
                  <Space className='btn'>
                    <FaRegBuilding />
                    Deployments: {item.ORG_COUNT}
                  </Space>
                </div>
              )}
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space className='btn'>
                  <TbWorldPin />
                  {item.ACCOUNT_REGION || 'N/A'}
                </Space>
              </div>
            </div>
          </div>
        );
      }}
    >
      {itemsList.map((item) => (
        <Option key={item.ACCOUNT_ID} autoComplete='off'>
          {item.ACCOUNT_NAME}
        </Option>
      ))}
    </Select>
  );
};

SnowflakeAccountPicker.propTypes = {
  onSelect: PropTypes.func,
  limit: PropTypes.number,
  initialValue: PropTypes.object,
  displayOrganizationCount: PropTypes.bool,
  showOnlyWithOrganization: PropTypes.bool,
};

SnowflakeAccountPicker.defaultProps = {
  onSelect: () => {
    console.warn('Function onSelect not provided');
  },
  limit: 50,
  displayOrganizationCount: false,
  showOnlyWithOrganization: false,
};

export default SnowflakeAccountPicker;
