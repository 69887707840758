import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import StyledScrollbars from 'src/components/layout/StyledScrollbars';

const ScrollableSection = ({ children, maxHeight = '100px', scrollDown = false, edgeGradientColor }) => {
  const { theme } = useTheme();
  const scrollRef = useRef();

  useEffect(() => {
    if (!scrollRef.current) return;
    if (!scrollDown) return;
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [scrollRef, scrollDown]);

  return (
    <StyledScrollbars color={theme.primary} bgColor={theme.backgroundLight}>
      <div className='rounded-l-xl' style={{ border: `1px solid ${theme.borderLight}`, overflow: 'hidden', padding: '1px', position: 'relative' }}>
        {edgeGradientColor && <div style={{ zIndex: 1, position: 'absolute', top: '0px', left: '0px', right: '0px', height: '18px', background: `linear-gradient(180deg, ${edgeGradientColor} 0%, #ffffff00 100%)`, pointerEvents: 'none', userSelect: 'none' }} />}
        {edgeGradientColor && <div style={{ zIndex: 1, position: 'absolute', bottom: '0px', left: '0px', right: '0px', height: '18px', background: `linear-gradient(180deg, #ffffff00 0%, ${edgeGradientColor} 100%)`, pointerEvents: 'none', userSelect: 'none' }} />}
        <div ref={scrollRef} style={{ maxHeight, overflowX: 'hidden', overflowY: 'scroll', position: 'relative' }}>
          {children}
        </div>
      </div>
    </StyledScrollbars>
  );
};

ScrollableSection.propTypes = {
  children: PropTypes.any,
  maxHeight: PropTypes.string,
  scrollDown: PropTypes.bool,
  edgeGradientColor: PropTypes.string,
};

export default ScrollableSection;
