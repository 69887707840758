import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { useNavigate } from 'react-router-dom';
import useUserInfo from 'src/hooks/useUserInfo';
import { getTicket, getServicesIsMaster, getTicketMembers } from 'src/api/teacket';
import PropTypes from 'prop-types';
import { useInterval } from 'react-interval-hook';
import { subscribe } from 'src/api/subscriptions';
import { notEmptyArray, notArrayOrEmpty } from 'src/misc/Misc';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';

const LoaderCurrentTicket = ({ ticketId, children }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { resetCurrentTicket, currentTicket, setCurrentTicket, setActiveViewers, currentTicketMembers, setCurrentTicketMembers, setCurrentTicketReadOnly, getCurrentTicketService } = useContext(TeacketContext);
  const navigate = useNavigate();
  const { id: userId, permissions: userPermissions } = useUserInfo();

  const [currentTicketService, setCurrentTicketService] = useState(null);

  useEffect(() => {
    if (!ticketId) return;
    // if (!currentTicket) loadData();
    loadData();
    return () => {
      resetCurrentTicket();
    };
  }, [ticketId]);

  useEffect(() => {
    console.log('currentTicket:', currentTicket);
    if (!currentTicket?.status || !currentTicketMembers) return;

    if (notEmptyArray(userPermissions) && userPermissions.includes(MASTER_ADMIN_PERMISSION)) {
      setCurrentTicketReadOnly(false);
      return;
    }

    if (notEmptyArray(userPermissions) && userPermissions.includes('apps.teacket.list.view')) {
      // superuser / supervisor
      setCurrentTicketReadOnly(false);
      return;
    }

    if (true === isCurrentTicketClosed()) {
      setCurrentTicketReadOnly(true);
      return;
    }

    if (true === notArrayOrEmpty(currentTicketMembers)) {
      setCurrentTicketReadOnly(true);
      return;
    }

    if (!currentTicketMembers.some((x) => x.user_id === userId)) {
      setCurrentTicketReadOnly(true);
      return;
    }

    setCurrentTicketReadOnly(false);
  }, [currentTicket?.status, currentTicketMembers, currentTicketService]);

  useInterval(async () => {
    await subscribeToTicket();
  }, 10000);

  const subscribeToTicket = async () => {
    const viewers = await subscribe('ticket', ticketId);
    setActiveViewers(viewers);
  };

  const isCurrentTicketClosed = () => {
    if (!currentTicket) return false;
    if (!currentTicketService) return false;
    const { statusList } = currentTicketService?.props ?? {};
    const status = statusList?.find((x) => x.status === currentTicket.status);
    console.log('status:', status, status?.isClosed === true);
    return status?.isClosed === true;
  };

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const ticket = await getTicket(ticketId);
        console.log('ticket:', ticket);
        const { isMaster } = await getServicesIsMaster(ticket.service_id);
        console.log('isMaster:', isMaster);
        setCurrentTicket(ticket, isMaster);
        const result2 = await getTicketMembers(ticketId);
        setCurrentTicketMembers(result2);
        await subscribe('ticket', ticketId);
        setCurrentTicketService(getCurrentTicketService());
      } catch (error) {
        console.error(error);
        if (error?.toString()?.includes('404')) {
          navigate('/404');
        }
      }
    });
  };

  if (!currentTicket) return <FullScreenSpin title='Please wait' subtitle='Loading ticket' />;

  return <>{children}</>;
};

LoaderCurrentTicket.propTypes = {
  ticketId: PropTypes.string,
  children: PropTypes.node,
};

export default LoaderCurrentTicket;
