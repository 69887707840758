import React, { useContext, useEffect } from 'react';
import DashboardsContext from 'src/context/DashboardsContext';
import useUserInfo from 'src/hooks/useUserInfo';
import { useNavigate } from 'react-router-dom';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import { Space, Tag } from 'antd';
import { GiPotionBall } from 'react-icons/gi';
import { SiVectorlogozone } from 'react-icons/si';
import { VscFeedback } from 'react-icons/vsc';
import { FaAws } from 'react-icons/fa';
import EvaluationsItem from '../SecurityQAGPT/Components/Evaluations';
import GraphVectorSources from '../SecurityQAGPT/Components/GraphVectorSources';
import GraphCopilotFeedback from '../SecurityQAGPT/Components/GraphCopilotFeedback';
import GraphCopilotUsage from '../SecurityQAGPT/Components/GraphCopilotUsage';
import CopilotUsage2 from '../SecurityQAGPT/Components/CopilotUsage2';
import AWSFrame from '../SecurityQAGPT/Components/AWSFrame';
import IntegrationsStatus from './Components/IntegrationsStatus';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';


const AIAssistant = () => {
  const navigate = useNavigate();
  const { permissions: userPermissions } = useUserInfo();
  const { setLayoutItemsSelector } = useContext(DashboardsContext);

  useEffect(() => () => setLayoutItemsSelector(null), []);

  const componentsGallery = [
    {
      id: 'Bot Evaluator',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space>
              <GiPotionBall />
              Evaluator
            </Space>
          }
        >
          <EvaluationsItem />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.dashboards.view'],
    },
    {
      id: 'Vector Embeddings',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space>
              <SiVectorlogozone />
              Vectors
            </Space>
          }
        >
          <GraphVectorSources />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.dashboards.view'],
    },
    {
      id: 'Copilot Feedback',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space>
              <VscFeedback />
              Feedback
              <Tag className='mb-1' color='red'>
                * ALL Orgs
              </Tag>
            </Space>
          }
          onClick={() => {
            navigate('/dashboards/copilot-feedback');
          }}
        >
          <GraphCopilotFeedback />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.dashboards.view'],
    },
    {
      id: 'Copilot Usage',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space>
              <GiPotionBall />
              COE AI Assistant Usage (90 days)
              <Tag className='mb-1' color='red'>
                * ALL Orgs
              </Tag>
            </Space>
          }
        >
          <GraphCopilotUsage />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.dashboards.view'],
    },
    {
      id: 'Copilot Usage2',
      col: 2,
      component: (
        <DraggableLayoutItem
          title={
            <Space>
              <GiPotionBall />
              <Space>
                <div>Users</div>
                <div className='text-xs'> (Questions asked)</div>
              </Space>
              <Tag className='mb-1' color='green'>
                * Current Org
              </Tag>
            </Space>
          }
        >
          <CopilotUsage2 />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.dashboards.view'],
    },
    {
      id: 'AWS Stats',
      col: 2,
      component: (
        <DraggableLayoutItem
          title={
            <Space>
              <FaAws />
              AWS Stats
            </Space>
          }
        >
          <AWSFrame />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.dashboards.view'],
    },
    {
      id: 'Integrations Status',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space>
              Integrations Status
            </Space>
          }
        >
          <IntegrationsStatus />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.dashboards.view'],
    },
  ].filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions));

  //const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  return (
    <PermissionsProvider requiredPermissions={['apps.dashboards.aiassistant.view']}>
      <>
        {/* <span className='mt-0 float-right'> {layoutItemsSelector}</span> */}
        <div>
          <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={3} mainColumnIndex={null} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutDashboards} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutDashboardsHidden} />
        </div>
      </>
    </PermissionsProvider>
  );
};

export default AIAssistant;
