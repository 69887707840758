import React, { useState, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import PropTypes from 'prop-types';
import { message, Select, Button } from 'antd';
import { getAdminServiceProps, putAdminServiceProps } from 'src/api/teacket';
import { notArrayOrEmpty } from 'src/misc/Misc';
import useTheme from 'src/hooks/useTheme';
import { v4 as uuid } from 'uuid';
import CondensedTable from 'src/components/layout/CondensedTable';
import { FaCircleMinus } from 'react-icons/fa6';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import Space2 from 'src/components/layout/Space2';
import NoData from 'src/components/layout/NoData';

const TeacketServiceEditorWidgets = ({ serviceId }) => {
  const PROP_NAME = 'widgetList';
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { theme } = useTheme();

  const columns = [
    {
      title: 'Widget ID',
      key: 'widgetId',
      render: (record) => <>{record.widgetId}</>,
    },

    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space2>
          <YesNoDialog
            title='Unassign'
            body={
              <>
                Do you want to unassign widget <strong>{record.widgetId}</strong>?
              </>
            }
            onYesClick={() => {
              handleWidgetRemove(record.widgetId);
            }}
            iconYes={<FaCircleMinus />}
            showRed={true}
            labelYes='Yes, unassign'
            labelNo='Cancel'
          >
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }}>
              <FaCircleMinus />
            </Button>
          </YesNoDialog>
        </Space2>
      ),
    },
  ];

  const [selectedWidget, setSelectedWidget] = useState(null);
  const [widgetList, setWidgetList] = useState(null);
  const [widgetSchema, setWidgetSchema] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getAdminServiceProps(serviceId, PROP_NAME, true);
        setWidgetList(resp?.value ?? []);
        setWidgetSchema(resp?.schema ?? null);
      } catch (error) {
        setWidgetList([]);
        console.log(error);
      }
    });
  };

  const handleWidgetAdd = async () => {
    console.log('handleWidgetAdd:', selectedWidget);
    await executeAsyncProcess(async () => {
      const key = uuid();
      messageApi.open({
        key,
        type: 'loading',
        content: 'Saving...',
        duration: 0,
      });
      const resp = await putAdminServiceProps(serviceId, PROP_NAME, [...widgetList, { widgetId: selectedWidget }]);
      setWidgetList(resp);
      setSelectedWidget(null);
      messageApi.open({
        key,
        type: 'success',
        content: 'Widget list saved',
        duration: 3,
      });
    });
  };

  const handleWidgetRemove = async (widgetId) => {
    console.log('handleWidgetRemove:', widgetId);
    await executeAsyncProcess(async () => {
      const key = uuid();
      messageApi.open({
        key,
        type: 'loading',
        content: 'Saving...',
        duration: 0,
      });
      const resp = await putAdminServiceProps(
        serviceId,
        PROP_NAME,
        widgetList.filter((x) => x.widgetId !== widgetId)
      );
      setWidgetList(resp);
      setSelectedWidget(null);
      messageApi.open({
        key,
        type: 'success',
        content: 'Widget list saved',
        duration: 3,
      });
    });
  };

  if (!widgetList || true === notArrayOrEmpty(widgetSchema?.items?.properties?.widgetId?.enum)) return <NoData />;

  return (
    <>
      {contextHolder}
      <div className='px-2 h-14 flex-initial flex flex-row gap-2 overflow-hidden items-center' style={{ backgroundColor: theme.backgroundLight }}>
        <Select className='w-[300px]' placeholder='select widget...' allowClear showSearch value={selectedWidget} onChange={(x) => setSelectedWidget(x)}>
          {widgetSchema.items.properties.widgetId.enum.map((x) => (
            <Select.Option value={x}>{x}</Select.Option>
          ))}
        </Select>

        <Button type='primary' disabled={!selectedWidget || widgetList.some((x) => x.widgetId === selectedWidget)} onClick={handleWidgetAdd}>
          Assign
        </Button>
      </div>
      <div className='flex-auto overflow-auto' style={{ marginTop: '6px', paddingBottom: '70px' }}>
        <CondensedTable pagination={false} columns={columns} dataSource={widgetList} rowKey={'widgetId'} />
      </div>
    </>
  );
};

TeacketServiceEditorWidgets.propTypes = {
  serviceId: PropTypes.string,
};

export default TeacketServiceEditorWidgets;
