import React from 'react';
import PropTypes from 'prop-types';
import StyledScrollbars from './StyledScrollbars';
import useTheme from 'src/hooks/useTheme';

const PageLayoutType1 = ({ left, right, leftWidth = '300px', height = 'calc(100vh - 116px)' }) => {
  const { theme } = useTheme();
  return (
    <StyledScrollbars color={theme.borderLight} bgColor={'transparent'} className={`p-2 w-full lg:h-[${height}] lg:overflow-hidden`}>
      <div className='flex flex-col lg:flex-row gap-4 h-full'>
        <div className={`flex-initial lg:w=${leftWidth} overflow-auto pb-6`}>{left}</div>
        <div className='flex-auto overflow-auto pb-6'>{right}</div>
      </div>
    </StyledScrollbars>
  );
};

PageLayoutType1.propTypes = {
  leftWidth: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  height: PropTypes.string,
};

export default PageLayoutType1;
