import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useUserInfo from 'src/hooks/useUserInfo';
import { Dropdown, Space } from 'antd';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import { FaSortDown } from 'react-icons/fa';

const PageSwitch = ({ pageList, label }) => {
  const { permissions: userPermissions } = useUserInfo();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [active, setActive] = useState('');

  useEffect(() => {
    const i = pageList
      ?.filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions))
      ?.map((page) => {
        return {
          key: page.path,
          label: (
            <Space className='btn'>
              {page.icon} {page.label}
            </Space>
          ),
          disabled: page.path === window.location.pathname,
        };
      });
    setItems(i);
    setActive(pageList.find((item) => item.path === window.location.pathname)?.label ?? 'Unknown');
    //console.log('aaaaa', i, pageList);
  }, [pageList]);

  const handleMenuOnClick = (e) => {
    //console.log('handleMenuOnCklick()', e);
    if (!e.key) return;
    navigate(e.key);
  };

  return (
    <Dropdown menu={{ items, onClick: handleMenuOnClick }} trigger={['click']}>
      <div className='cursor-pointer'>
        <Space>
          <span className='font-extralight'>
            {label || active} <FaSortDown />
          </span>
        </Space>
      </div>
    </Dropdown>
  );
};

PageSwitch.propTypes = {
  pageList: PropTypes.array,
  label: PropTypes.string,
};

export default PageSwitch;
