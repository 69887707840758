import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MobileMenu from './MobileMenu';
import DarkModeSwitch from 'src/components/layout/DarkModeSwitch';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import AppLogo from 'src/components/layout/AppLogo';

const Header = ({ hideSignInBtn = false }) => {
  const [top, setTop] = useState(true);
  const navigate = useNavigate();

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 dark:md:bg-opacity-95 transition duration-300 ease-in-out ${!top ? 'bg-white dark:bg-genesys-gray-900 backdrop-blur-sm shadow-lg' : ''}`}>
      <div className='max-w-6xl mx-auto px-5 sm:px-6'>
        <div className='flex items-center justify-between h-16 md:h-20'>
          {/* Site branding */}
          <AppLogo
            onClick={() => {
              navigate('/');
            }}
          />
          {/* Desktop navigation */}
          <nav className='hidden md:flex md:grow'>
            {/* Desktop sign in links */}
            <ul className='flex grow justify-end flex-wrap items-center list-none gap-2'>
              <li>
                <DarkModeSwitch />
              </li>
              {!hideSignInBtn && (
                <li>
                  <Button
                    style={{ backgroundColor: '#ff4f1f', borderColor: '#ff4f1f' }}
                    type='primary'
                    size='large'
                    disabled={false}
                    className='hover:scale-105 transition-transform duration-300 ease-in-out group font-bold rounded-2xl'
                    onClick={() => {
                      //navigate('/signin');
                      window.location.href = '/home';
                    }}
                  >
                    Sign in
                    <ArrowRightOutlined />
                  </Button>
                </li>
              )}
              {/* <li>
                <a href='/signin' className='btn inline-flex items-center justify-center p-4 no-underline font-medium text-gray-800 rounded hover:text-gray-900 dark:text-gray-600 dark:hover:text-gray-400 transition duration-150 ease-in-out'>
                  Sign in
                </a>
              </li> */}
              {/* <li>
                <a href='/signup' className='btn inline-flex items-center justify-center p-4 no-underline font-medium text-gray-200 bg-blue-900 rounded hover:text-gray-900  hover:bg-blue-800 dark:text-gray-200 dark:hover:text-black dark:hover:bg-blue-300 ml-3 transition duration-150 ease-in-out'>
                  <span>Sign up</span>
                  <svg className='w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z' fillRule='nonzero' />
                  </svg>
                </a>
              </li> */}
            </ul>
          </nav>
          <MobileMenu />
        </div>
        {/* <div className='flex justify-end'>SPC is currently down for maintenance and will be back soon.</div> */}
      </div>
    </header>
  );
};

Header.propTypes = {
  hideSignInBtn: PropTypes.bool,
};

export default Header;
