import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from 'src/components/layout/AppLayout';
import UserControl from 'src/components/layout/UserControl';
import { Space } from 'antd';
import DarkModeSwitch from 'src/components/layout/DarkModeSwitch';
import TenantBadge from 'src/components/layout/TenantBadge';
import NotificationCenterIcon from 'src/components/pages/NotificationCenter/components/NotificationCenterIcon';
import FeaturesAccessIcon from 'src/components/controls/FeatruesAccess/FeaturesAccessIcon';

const PrivateRouteLayout = ({ children }) => {
  return (
    <AppLayout
      headerExtras={
        <Space className='btn'>
          <FeaturesAccessIcon />
          <NotificationCenterIcon />

          {/* <NotificationsButton /> */}
          <TenantBadge />
          <DarkModeSwitch />
          <UserControl />
        </Space>
      }
    >
      {children}
    </AppLayout>
  );
};

PrivateRouteLayout.propTypes = {
  children: PropTypes.node,
};

export default PrivateRouteLayout;
