import React, { useState } from 'react';
import TitleBar from 'src/components/layout/TitleBar';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import { MdSecurity } from 'react-icons/md';
import { Alert, Space } from 'antd';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import RiskLevel from './Components/RiskLevel';
import RiskLevelHistory from './Components/RiskLevelHistory';
import WorkersHistory from './Components/WorkersHistory';
import Controls from './Components/Controls';
import { MdOutlineTrendingDown, MdHistory, MdOutlineSecurity /*MdWarning*/ } from 'react-icons/md';
import { IoMdPlay } from 'react-icons/io';
// import EventsWidget from './Components/Events/EventsWidget';
// import EventsWidgetBtns from './Components/Events/EventsWidgetBtns';
// import EventsSetup from './Components/Events/EventsSetup';
import JobLoader from './Components/JobLoader';
//import PropTypes from 'prop-types';

const Dashboard = () => {
  const componentsGallery = [
    {
      id: 'Current Risk Level',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdOutlineSecurity />
              Current Risk Level
            </Space>
          }
          centered>
          <RiskLevel />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.securityadvisor.view'],
    },
    {
      id: 'Control Panel',
      col: 0,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <IoMdPlay />
              Control Panel
            </Space>
          }
          centered>
          <Controls />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.securityadvisor.view'],
    },
    {
      id: 'Trend',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdOutlineTrendingDown />
              Trend
            </Space>
          }
          centered>
          <RiskLevelHistory />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.securityadvisor.view'],
    },
    {
      id: 'History',
      col: 1,
      component: (
        <DraggableLayoutItem
          title={
            <Space className='btn'>
              <MdHistory />
              History
            </Space>
          }
          centered>
          <WorkersHistory />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.securityadvisor.view'],
    },
    // SA Events are moved to Audit Viewer:
    // {
    //   id: 'Real-Time Notifications',
    //   col: 1,
    //   component: (
    //     <DraggableLayoutItem
    //       title={
    //         <Space className='btn'>
    //           <MdWarning />
    //           Real-Time Notifications
    //         </Space>
    //       }
    //       centered
    //       titleExtras={<EventsWidgetBtns />}>
    //       <EventsSetup>
    //         <EventsWidget />
    //       </EventsSetup>
    //     </DraggableLayoutItem>
    //   ),
    //   permissions: ['apps.securityadvisor.view'],
    // },
  ];

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  return (
    <>
      <TitleBar
        title={
          <Space className='btn'>
            <MdSecurity />
            Security Advisor
            <span className='font-thin'>Dashboard</span>
          </Space>
        }>
        {layoutItemsSelector}
      </TitleBar>
      <Alert className='m-4' message='This report draws upon best practices learned from serving hundreds of Genesys Cloud customers. Security Advisor inspects your organization configuration and makes recommendations when opportunities exist to improve system availability and performance, or help close security gaps.' type='info' showIcon closable={true} />
      <Alert className='m-4' message='To get started, click on "Start". This will trigger a new "job" to scan your organization and find potential security issues.' type='info' showIcon closable={true} />
      <JobLoader>
        <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={1} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutSecurityAdvisor} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutSecurityAdvisorHidden} />
      </JobLoader>
    </>
  );
};

// HomeMasterAdmin.propTypes = {};

export default Dashboard;
