import React, { useContext, useState } from 'react';
import { Table, Tag, Tooltip, Space, Button, Popconfirm, message } from 'antd';
import UserImage from 'src/components/layout/UserImage';
import useTheme from 'src/hooks/useTheme';
import { textSorter, dateTimeSorter } from 'src/misc/Misc';
import { getDemoTypeIcon, getDemoTypeLabel, requestTypeList, getStatusLabel, getStatusColor, getColor } from '../Misc/Dictionary';
import { getJob, deleteJob } from 'src/api/dataSubjectRequests';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
//import dayjs from 'dayjs';
import DataSubjectRequestsContext from 'src/context/DataSubjectRequestsContext';
import * as dayjs from 'dayjs';
import { FaCircle } from 'react-icons/fa';
import { uuid } from 'short-uuid';
import { FaRegTrashCan } from 'react-icons/fa6';

const JobsList = () => {
  const { theme } = useTheme();
  const { jobs, setJob, deleteJobFromCollection } = useContext(DataSubjectRequestsContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [activeRow, setActiveRow] = useState(null);

  const getStatus = (requests) => {
    if (!requests) return 'n/a';
    requests = requests.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

    const completedStatuses = ['completed', 'deleted', 'updated', 'failed', 'ambigous'];

    let iCount = 0;
    let newestRequestId;
    for (let i = 0; i < requests.length; i++) {
      if (!completedStatuses.includes(requests[i].status)) {
        if (iCount === 0) newestRequestId = i;
        iCount++;
      }
    }
    if (iCount > 0)
      return (
        <span>
          {/* {iCount > 1 ? <Tag color='warning'>In progress x {iCount}</Tag> : <Tag color='warning'>In progress</Tag>} */}
          <FaCircle style={{ marginRight: '8px', scale: '0.7', color: 'orange' }} />
          {iCount > 1 ? `In progress x ${iCount}` : 'In progress'}

          <br />
          <span className='text-xs italic'> {iCount > 1 ? `latest: ${dayjs(requests[newestRequestId].updated_at).fromNow()}` : `${dayjs(requests[newestRequestId].updated_at).fromNow()}`}</span>
        </span>
      );
    return (
      <span>
        {/* <Tag color={getStatusColor(requests[0].status)}>{getStatusLabel(requests[0].status)}</Tag> */}
        <FaCircle style={{ marginRight: '8px', scale: '0.7', color: getColor(getStatusColor(requests[0].status), 4) }} />
        {getStatusLabel(requests[0].status)}
        <br />
        <span className='text-xs italic'> {dayjs(requests[0].updated_at).fromNow()}</span>
      </span>
    );
  };

  const deleteItem = async (id) => {
    console.log('delete', id);
    executeAsyncProcess(async () => {
      try {
        await deleteJob(id);
        deleteJobFromCollection(id);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Job has been deleted',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (record) => (
        <>
          <div className='quitelight'>
            <strong>{record?.name}</strong>
          </div>
          <div className='quitelight' style={{ paddingLeft: '15px' }}>
            <i className={`${getDemoTypeIcon(record.type)}  btn-margin-right`} /> {getDemoTypeLabel(record.type)} {record.type === '1' && record.useRealApi === true ? <Tag color='blue'>Real API Calls</Tag> : null}
          </div>
        </>
      ),
      sorter: (a, b) => textSorter(a, b, 'name'),
    },
    {
      title: 'Requests',
      key: 'requests',
      width: 160,
      align: 'left',
      render: (record) => {
        if (!record?.requests) return <div className='ultralight'>n/a</div>;
        const result = [];
        const reqTypes = {};

        for (const key in record.requests) {
          const label = requestTypeList.find((x) => x.value === record.requests[key].type)?.label ?? record.requests[key].type;
          reqTypes[label] = reqTypes[label] ? (reqTypes[label] += 1) : 1;
        }
        const reqTypesArray = Object.entries(reqTypes);

        reqTypesArray.forEach(([label, value], index) => {
          result.push(
            <div key={index} className='text-xs' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ flex: 1, textAlign: 'left' }}>
                <span>{label}</span>
              </div>
              <div>{value}</div>
            </div>
          );
        });

        return result;
      },
    },
    {
      title: 'Status',
      key: 'status',
      width: 180,
      align: 'left',
      render: (record) =>
        // <>
        //   <i className='ion-record' style={{ marginRight: '8px', color: getColor(getStatusColor(record.status), 4) }} />
        //   {getStatusLabel(record.status)}
        // </>
        getStatus(record?.requests),
      sorter: (a, b) => textSorter(a, b, 'status'),
    },
    {
      title: 'Created At',
      key: 'created',
      width: 220,
      align: 'center',
      render: (record) => (
        <>
          <div>{dayjs(record.created_at).fromNow()}</div>
          <div className='ultralight smaller'>at {dayjs(record.created_at).format('LLL')}</div>
        </>
      ),
      sorter: (a, b) => dateTimeSorter(a, b, 'created_at'),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Created By',
      key: 'createdBy',
      width: 220,
      align: 'center',
      render: (record) => (
        <Tooltip title={record?.user?.name}>
          <Space>
            <UserImage image={record?.user?.user_pic} size='26px' />
            {record?.user?.name ? record.user.name : <span className='ultralight'>n/a</span>}
          </Space>
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      align: 'center',
      render: (record) => (
        <Space className='btn'>
          <Popconfirm placement='topLeft' title='Delete' description='Do you want to delete this Job?' okText='Yes' cancelText='No' onConfirm={() => deleteItem(record.id)}>
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} className='hover:scale-125'>
              <FaRegTrashCan />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  return (
    <>
      {contextHolder}
      <Table
        rowKey={(record) => record.id}
        dataSource={jobs}
        columns={columns}
        size='small'
        pagination={{ pageSize: 5 }}
        onRow={(record) => {
          return {
            onClick: async () => {
              if (activeRow?.id !== record.id) {
                setActiveRow(record);
                await executeAsyncProcess(async () => {
                  try {
                    const recordData = await getJob(record.id);
                    setJob(recordData);
                  } catch (error) {
                    console.error(error);
                  }
                });
              }
            },
          };
        }}
        rowClassName={(record) => (record?.id === activeRow?.id ? 'bg-sky-900/50' : '')}
      />
    </>
  );
};

JobsList.propTypes = {};

export default JobsList;
