import React from 'react';
import { Tabs } from 'antd';
import { FaRegClock, FaRegCalendar } from 'react-icons/fa6';
import Space2 from 'src/components/layout/Space2';
import TicketTimeTrackingSubmit from './TicketTimeTrackingSubmit';
import TicketTimeTrackingEntries from './TicketTimeTrackingEntries';
//import PropTypes from 'prop-types';

const TicketTimeTracking = () => {
  return (
    <>
      <Tabs
        size='small'
        type='card'
        //defaultActiveKey={initialTab}
        items={[
          {
            key: 'submit',
            label: (
              <Space2>
                <FaRegClock />
                Submit time
              </Space2>
            ),
            children: <TicketTimeTrackingSubmit />,
          },
          {
            key: 'entries',
            label: (
              <Space2>
                <FaRegCalendar />
                Time entries
              </Space2>
            ),
            children: <TicketTimeTrackingEntries />,
          },
        ]}
      />
    </>
  );
};

TicketTimeTracking.propTypes = {};

export default TicketTimeTracking;
