import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import SearchServiceUser from 'src/components/pages/Teacket/Components/ServiceUserPicker';
import PropertySection from 'src/components/layout/PropertySection';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { Alert, Button, Modal, Skeleton, Space, message } from 'antd';
import { postTicketMember, getTicketMembers, deleteTicketMember } from 'src/api/teacket';
import TicketMembersItem from './TicketMembersItem';
import { IoPersonAdd } from 'react-icons/io5';
import NoData from 'src/components/layout/NoData';
import useUserInfo from 'src/hooks/useUserInfo';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';
import { hasMasterAdminRights, hasSuperuserRights } from '../Misc/misc';
import { uuid } from 'short-uuid';

//import PropTypes from 'prop-types';

const TicketMembers = () => {
  const { currentTicket, getCurrentTicketService, currentTicketMembers, setCurrentTicketMembers, currentTicketReadOnly, updateCurrentTicket, setTicketAfterUpdateSync } = useContext(TeacketContext);
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const { id: userId, permissions: userPermissions } = useUserInfo();
  const [messageApi, contextHolder] = message.useMessage();

  const [currentTicketService, setCurrentTicketService] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [addAsOpen, setAddAsOpen] = useState(false);

  const userInfo = useUserInfo();

  //#region "USE EFFECTS"

  useEffect(() => {
    if (!currentTicket) return;
    const cts = getCurrentTicketService();
    //console.log('currentTicketService:', cts);
    setCurrentTicketService(cts);
  }, [currentTicket]);

  useEffect(() => {
    if (!addAsOpen) setSelectedUser(null);
  }, [addAsOpen]);

  //#endregion

  //#region "MISC"

  const loadData = async (refreshNotifications) => {
    console.log('loadData()', refreshNotifications);
    await executeAsyncProcess(async () => {
      try {
        const result = await getTicketMembers(currentTicket.id);
        setCurrentTicketMembers(result);
        if (refreshNotifications) updateLiveNotifications(result); // update live notifications for current user (if self assigned)
      } catch (error) {
        console.error(error);
      }
    });
  };

  //#endregion

  //#region "EVENT HANDLERS"

  const updateTicketListMembers = (m) => {
    setTicketAfterUpdateSync(true);
  };

  const handleOnUserSelect = (user) => {
    console.log('handleOnUserSelect()', user);
    setSelectedUser(user);
  };

  const handleAddAs = async (role) => {
    console.log('handleAddAs()', role);

    await executeAsyncProcess(async () => {
      try {
        const newMember = await postTicketMember(currentTicket.id, selectedUser.user_id, role);
        console.log('newMember:', newMember);
        const m = true === notEmptyArray(currentTicketMembers) ? [...currentTicketMembers, newMember] : [newMember];
        setCurrentTicketMembers(m);
        updateLiveNotifications(m); // update live notifications for current user (if self assigned)
        setAddAsOpen(false);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'People successfully updated',
          duration: 3,
        });

        updateTicketListMembers(m);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleAddAsCancel = () => setAddAsOpen(false);

  const handleOnRemove = async (user_id) => {
    console.log('handleOnRemove()', user_id);
    await executeAsyncProcess(async () => {
      try {
        await deleteTicketMember(currentTicket.id, user_id);
        const m = currentTicketMembers.filter((x) => x.user_id !== user_id);
        setCurrentTicketMembers(m);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'People successfully updated',
          duration: 3,
        });
        updateLiveNotifications(m); // update live notifications for current user (if self assigned)
        updateTicketListMembers(m);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const updateLiveNotifications = (members) => {
    console.log('updateLiveNotifications()');
    try {
      const me = members.find((x) => x.user_id === userInfo.id);
      if (me) updateCurrentTicket({ ...currentTicket, notifications: { ...currentTicket.notifications, live: me.live_notifications } });
      else updateCurrentTicket({ ...currentTicket, notifications: { ...currentTicket.notifications, live: null } });
    } catch (error) {
      console.error(error);
    }
  };

  //#endregion

  const handleOnWsMessageReceived = async (event) => {
    try {
      console.log('[TicketMembers] TICKET_MEMBERS_ADDED or TICKET_MEMBERS_REMOVED', event);
      await loadData(true);

      if (event?.action === 'TICKET_MEMBERS_ADDED') {
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: `User added (${event?.data?.user_name})`,
          duration: 3,
        });

        return;
      } else {
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'People updated (user removed)',
          duration: 3,
        });
      }

      // Reload Files
    } catch (error) {
      console.error(error);
    }
  };

  const canManageMembers = () => {
    // Admins can always manage members
    if (notEmptyArray(userPermissions) && true === hasMasterAdminRights(userPermissions)) {
      // console.log('User is an admin can always manage members');
      return true;
    }

    // If the ticket is read only, nobody can manage members
    if (currentTicketReadOnly) {
      // console.log('Ticket is read-only, nobody can manage members');
      return false;
    }

    // Superusers can always manage members
    if (notEmptyArray(userPermissions) && true === hasSuperuserRights(userPermissions)) {
      // console.log('User is a superuser can always manage members');
      return true;
    }

    // If the ticket has no members, nobody can manage members
    if (true === notArrayOrEmpty(currentTicketMembers)) {
      // console.log('Ticket has no members, nobody can manage members');
      return false;
    }

    // If the ticket has members, only people with a role flag can manage members
    const member = currentTicketMembers.find((x) => x.user_id === userId);
    if (member && currentTicketService?.props?.roleList?.find((x) => x.role === member.role)?.canManageMembers) {
      // console.log('User has a role flag that can manage members:', member.role);
      return true;
    }

    return false;
  };

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_MEMBERS_ADDED', 'TICKET_MEMBERS_REMOVED']}></GlobalWsMessenger>
      {contextHolder}
      <Modal title='Add' open={addAsOpen} onCancel={handleAddAsCancel} footer={[]} forceRender={true}>
        <div className='text-left'>
          {addAsOpen && (
            <div className={isBusy && 'disabled'}>
              <SearchServiceUser serviceId={currentTicket.service_id} onSelect={handleOnUserSelect} />
              {selectedUser && true === notEmptyArray(currentTicketService.props.roleList) && (
                <>
                  {notEmptyArray(currentTicketMembers) && currentTicketMembers.some((x) => x.user_id === selectedUser.user_id) ? (
                    <div className='mt-4'>
                      <Alert message='The person is already a member of this ticket' type='warning' showIcon />
                    </div>
                  ) : (
                    <div className='mt-4'>
                      <div className='text-center'>Select a role for this person:</div>
                      <div className='flex flex-col gap-2 mt-2'>
                        {currentTicketService.props.roleList.map((role) => (
                          <Button
                            disabled={role.maxItems && currentTicketMembers?.filter((x) => x.role === role.role).length >= role.maxItems}
                            key={role.role}
                            className='w-full'
                            onClick={() => {
                              handleAddAs(role.role);
                            }}>
                            {role.role}
                          </Button>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Modal>
      <div className='flex flex-col gap-2'>
        <PropertySection>
          <div className='flex flex-col  gap-2 mx-4 my-2 justify-center'>
            {null === currentTicketMembers && (
              <div>
                <Skeleton active avatar paragraph={{ rows: 0 }} />
                <Skeleton active avatar paragraph={{ rows: 0 }} />
                <Skeleton active avatar paragraph={{ rows: 0 }} />
              </div>
            )}
            {currentTicketMembers?.length === 0 && <NoData label='Nobody is assigned yet' />}
            {currentTicketMembers?.length > 0 && currentTicketMembers.map((member, i) => <TicketMembersItem key={i} member={member} onRemove={handleOnRemove} canManageMembers={canManageMembers()} />)}
          </div>
        </PropertySection>

        {/* {true && ( */}
        {true === canManageMembers() && (
          <Button type='text' onClick={() => setAddAsOpen(true)}>
            <Space className='btn'>
              <IoPersonAdd />
              Add
            </Space>
          </Button>
        )}
      </div>
    </>
  );
};

TicketMembers.propTypes = {};

export default TicketMembers;
