import { FaHome } from 'react-icons/fa';
import { BsTable } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { PiFolderUserBold } from 'react-icons/pi';
import { IoStatsChart } from 'react-icons/io5';
import { MASTER_ADMIN_PERMISSION } from 'src/misc/Config';

export const pageList = [
  {
    path: '/teacket',
    label: 'Home',
    icon: <FaHome />,
    permissions: ['apps.teacket.view'],
  },
  {
    path: '/teacket/create-new',
    label: 'Create new',
    icon: <MdAdd />,
    permissions: ['apps.teacket.ticket.create'],
  },
  {
    path: '/teacket/my-tickets',
    label: 'My Tickets',
    icon: <PiFolderUserBold />,
    permissions: ['apps.teacket.ticket.create', 'apps.teacket.ticket.edit'],
  },
  {
    path: '/teacket/tickets',
    label: 'Ticket List',
    icon: <BsTable />,
    permissions: ['apps.teacket.list.view'],
  },
  {
    path: '/teacket/dashboard',
    label: 'Dashboard',
    icon: <IoStatsChart />,
    permissions: ['apps.teacket.stats.view'],
  },
];

export const hasTickeCreate = (permissions) => permissions.includes('apps.teacket.ticket.create') || permissions.includes(MASTER_ADMIN_PERMISSION);
export const hasTicketEditRights = (permissions) => permissions.includes('apps.teacket.ticket.edit') || permissions.includes(MASTER_ADMIN_PERMISSION);
export const hasSuperuserRights = (permissions) => permissions.includes('apps.teacket.list.view') || permissions.includes(MASTER_ADMIN_PERMISSION);
export const hasSupervisorRights = (permissions) => permissions.includes('apps.teacket.stats.view') || permissions.includes(MASTER_ADMIN_PERMISSION);
export const hasMasterAdminRights = (permissions) => permissions.includes(MASTER_ADMIN_PERMISSION) || permissions.includes(MASTER_ADMIN_PERMISSION);

export const colors = {
  open: {
    light: '#93C5FD',
    dark: '#1D4ED8',
  },
  closed: {
    light: '#F0ABFC',
    dark: '#A21CAF',
  },
  remaining: {
    light: '#FDBA74',
    dark: '#C2410C',
  },
};

export const TITLE_MAX_LENGTH = 250;
export const CONTENT_MAX_LENGTH = 5000;
