import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Space, Tooltip, Tag, message } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';

import { useParams } from 'react-router-dom';
import LoaderServicesForTenant from './Loaders/LoaderServicesForTenant';
import LoaderCurrentTicket from './Loaders/LoaderCurrentTicket';
import useUserInfo from 'src/hooks/useUserInfo';
import { MdContentCopy } from 'react-icons/md';
import { getComponentsGallery } from './Misc/ticketLayout';
import SubscribeForLiveEvents from './Components/TicketSubscribeForNotifiactions';
import { uuid } from 'short-uuid';

import queryString from 'query-string';
import { createSwapy } from 'swapy';

//import PropTypes from 'prop-types';

const Ticket = () => {
  const isSwapy = queryString.parse(window.location.search)?.swapy === '1';

  console.log('isSwapy', isSwapy);

  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  // const { executeAsyncProcess } = useAsyncProcesses();
  const { currentTicket, currentTicketReadOnly, getWidgetListForCurrentTicket } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { ticketId } = useParams();
  // const navigate = useNavigate();
  const { permissions: userPermissions } = useUserInfo();

  const componentsGallery = getComponentsGallery(userPermissions, getWidgetListForCurrentTicket());

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      // {
      //   title: 'Ticket List',
      //   path: '/teacket/tickets',
      // },
      {
        title: <>Ticket</>,
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    let swapy;
    let container;
    if (isSwapy) {
      container = document.querySelector('.test-container');

      if (container) {
        swapy = createSwapy(container, {
          swapMode: 'hover',
          continuousMode: false,
        });
        swapy.onSwap(({ data }) => {
          console.log('swap', data);
          localStorage.setItem('slotItem', JSON.stringify(data.object));
        });

        swapy.onSwapEnd(({ data }) => {
          console.log('end', data);
        });

        swapy.onSwapStart(() => {
          console.log('start');
        });
      }
    }

    return () => {
      if (isSwapy && container) swapy.destroy();
    };
  }, [currentTicket]);

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  const handleCopyUrl = () => {
    console.log('handleCopyUrl()');
    navigator.clipboard.writeText(getTicketUrl());
    messageApi.open({
      key: uuid(),
      type: 'success',
      content: 'Copied',
      duration: 3,
    });
  };

  const getTicketUrl = () => `${window.location.origin}/teacket/tickets/${currentTicket.id}`;

  if (isSwapy) {
    return (
      <LoaderServicesForTenant>
        <LoaderCurrentTicket ticketId={ticketId}>
          <div className='test-container m-4'>
            <div className='flex flex-row gap-4 w-full'>
              <div className='flex flex-col gap-4 w-1/4'>
                <div data-swapy-slot='slot-0'>
                  <div data-swapy-item='item-0'>{componentsGallery[0]?.component}</div>
                </div>
                <div data-swapy-slot='slot-1'>
                  <div data-swapy-item='item-1'>{componentsGallery[1]?.component}</div>
                </div>
                <div data-swapy-slot='slot-2'>
                  <div data-swapy-item='item-2'>{componentsGallery[2]?.component}</div>
                </div>
              </div>
              <div className='flex flex-col gap-4 w-1/2'>
                <div data-swapy-slot='slot-2'>
                  <div data-swapy-item='item-3'>{componentsGallery[3]?.component}</div>
                </div>
              </div>
              <div className='flex flex-col gap-4 w-1/4'>
                <div data-swapy-slot='slot-3'>
                  <div data-swapy-item='item-4'>{componentsGallery[4]?.component}</div>
                </div>
                <div data-swapy-slot='slot-5'>
                  <div data-swapy-item='item-5'>{componentsGallery[5]?.component}</div>
                </div>
                <div data-swapy-slot='slot-6'>
                  <div data-swapy-item='item-6'>{componentsGallery[6]?.component}</div>
                </div>
                <div data-swapy-slot='slot-7'>
                  <div data-swapy-item='item-7'>{componentsGallery[7]?.component}</div>
                </div>
                <div data-swapy-slot='slot-8'>
                  <div data-swapy-item='item-8'>{componentsGallery[8]?.component}</div>
                </div>
              </div>
            </div>
          </div>
        </LoaderCurrentTicket>
      </LoaderServicesForTenant>
    );
  }

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.ticket.create', 'apps.teacket.ticket.edit']}>
      {contextHolder}
      <TitleBar
        title={
          <Space className='btn'>
            <BsCupHotFill />
            <div>
              <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
            </div>
          </Space>
        }
        afterTitleExtras={
          <div className='flex flex-row gap-2 items-center'>
            <PageSwitch pageList={pageList} label={`Ticket ${currentTicket?.id ?? '...'}`} />
            {currentTicket && (
              <Tooltip title='Copy ticket URL' mouseEnterDelay={1}>
                <MdContentCopy className='hover:scale-110 cursor-pointer' onClick={handleCopyUrl} />
              </Tooltip>
            )}
            {currentTicket && currentTicketReadOnly && <Tag color='volcano'>Read only</Tag>}
          </div>
        }
      >
        <SubscribeForLiveEvents />

        {layoutItemsSelector}
      </TitleBar>
      <LoaderServicesForTenant>
        <LoaderCurrentTicket ticketId={ticketId}>
          <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={3} mainColumnIndex={1} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutTeacketTicket} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutTeacketTicketHidden} />
        </LoaderCurrentTicket>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// Ticket.propTypes = {};

export default Ticket;
