import React, { useState } from 'react';
import styled from 'styled-components';
import useTheme from 'src/hooks/useTheme';
import PropTypes from 'prop-types';
import { Space, Steps } from 'antd';
import TitleBar from 'src/components/layout/TitleBar';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { ReactComponent as GenesysLogo } from 'src/components/svg/genesys-logo.svg';
import StepPdf from './StepPdf';
import StepScrap from './StepScrap';
import StepSfdc from './StepSfdc';
import StepSnowFlake from './StepSnowFlake';

const StyledDiv = styled.div``;

const CopilotTenant = ({ tenant }) => {
  const { theme } = useTheme();

  const [current, setCurrent] = useState(0);

  if (!tenant)
    return (
      <div className='h-full flex flex-row justify-center items-center'>
        <div>No data</div>
      </div>
    );

  const onChange = (value) => {
    console.log('onChange:', value);
    setCurrent(value);
  };

  return (
    <StyledDiv style={{ backgroundColor: theme.backgroundBase }}>
      <TitleBar
        busyIndicator={false}
        colorBackground={theme.backgroundBase}
        title={
          <Space className='btn'>
            {tenant.name === 'Genesys SPC' ? (
              <>
                <div style={{ height: '22px', width: '18px', color: '#ff4f1f' }}>
                  <GenesysLogo />
                </div>
                <div className='font-roboto'>{tenant.name}</div>
              </>
            ) : (
              <>
                <HiOutlineOfficeBuilding />
                {tenant.name}
              </>
            )}
            <div className='font-extralight opacity-60'>AI Assistant Tenant</div>
          </Space>
        }
        afterTitleExtras={<></>}></TitleBar>
      <div className='min-h-[600px] flex-col'>
        <Steps
          type='navigation'
          size='small'
          current={current}
          onChange={onChange}
          className='flex-initial'
          items={[
            {
              title: 'PDF Files',
              // description: 'Upload document files',
            },
            {
              title: 'Website Scraper',
              // description: 'Scrap web documents',
            },
            {
              title: 'Salesforce',
              // description: 'Integrate with SFDC',
            },
            {
              title: 'Snowflake',
              // description: 'Integrate with Snowflake',
            },
          ]}
        />
        <div className='flex-auto overflow-auto p-4'>
          {current === 0 && <StepPdf pdfFiles={tenant?.configuration?.pdfFiles} />}
          {current === 1 && <StepScrap urls={tenant?.configuration?.urls} />}
          {current === 2 && <StepSfdc />}
          {current === 3 && <StepSnowFlake />}
        </div>
      </div>
    </StyledDiv>
  );
};

CopilotTenant.propTypes = {
  tenant: PropTypes.any,
};

export default CopilotTenant;
