import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { searchPermissions } from 'src/api/accesscontrol';
import PropTypes from 'prop-types';

const { Option } = Select;

const PermissionPicker = ({ width, onPick, defaultValue, placeholder = 'type to search permission...' }) => {
  const [permissionList, setPermissionList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    console.log('handleClear');
    setPermissionList([]);
    onPick(null);
  };

  const handleSearchForPermissions = async (v) => {
    setPermissionList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await searchPermissions(v);
      //console.log('permissions:', resp);
      if (resp) {
        setPermissionList(resp ?? []);
      }
      setLoading(false);
    } catch (error) {
      if (error === 'cancelled') return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnSelect = (v) => {
    console.log('PermissionPicker onSelect:', v);
    const p = permissionList.find((t) => t.permission === v);
    onPick(p);
  };

  return (
    <Select defaultValue={defaultValue} showSearch allowClear placeholder={placeholder} notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null} filterOption={false} onSearch={handleSearchForPermissions} onClear={handleClear} onSelect={handleOnSelect} style={{ width }} autoComplete='off'>
      {permissionList.map((d) => (
        <Option key={d.permission}>
          {d.permission} <span className='text-[0.6rem] font-light opacity-60'>({d.description})</span>
        </Option>
      ))}
    </Select>
  );
};

PermissionPicker.propTypes = {
  width: PropTypes.string,
  onPick: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
};

PermissionPicker.defaultProps = {
  width: '220px',
};

export default PermissionPicker;
