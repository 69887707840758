import React, { useEffect } from 'react';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import styled from 'styled-components';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import { LuShieldCheck } from 'react-icons/lu';
import { Space, Tabs } from 'antd';
import Roles from 'src/components/pages/MasterAdmin/PermissionsAndRoles/Components/Roles';
import Permissions from 'src/components/pages/MasterAdmin/PermissionsAndRoles/Components/Permissions';
import Assignments from './Components/Assignments';

//import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  .ant-tabs-content-holder {
    height: 100% !important;
    overflow: auto !important;
  }
  .ant-tabs-content-top {
    height: 100% !important;
  }
  .ant-tabs-tabpane {
    height: 100% !important;
  }
`;

const MasterAdminPermissions = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Admin',
        path: '/master-admin',
      },
      {
        title: 'Roles & Permissions',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      <StyledDiv className='h-full flex flex-col'>
        <TitleBar
          title={
            <Space className='btn'>
              <LuShieldCheck />
              Roles & Permissions
            </Space>
          }
        />

        <Content>
          <Tabs defaultActiveKey='assignments' className='overflow-hidden h-full max-h-full w-full' type='card'>
            <Tabs.TabPane tab='Assignments' key='assignments'>
              <Assignments />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Roles' key='roles'>
              <Roles />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Permissions' key='permissions'>
              <Permissions />
            </Tabs.TabPane>
          </Tabs>
        </Content>
      </StyledDiv>
    </PermissionsProvider>
  );
};

// MasterAdminPermissions.propTypes = {};

export default MasterAdminPermissions;
