import React, { useEffect } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import { MdDashboard } from 'react-icons/md';
import { Space } from 'antd';
import FeaturesAccessList from './Components/FeaturesAccessList';
import PermissionsProvider from 'src/providers/PermissionsProvider';
//import PropTypes from 'prop-types';

const FeaturesAccess = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Dashboards',
        path: '/dashboards',
      },
      {
        title: 'Features Access',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['admin.master']}>
      <TitleBar
        title={
          <Space className='btn'>
            <MdDashboard />
            Dashboards
          </Space>
        }
        afterTitleExtras={<PageSwitch pageList={pageList} />}
      />

      <FeaturesAccessList />
    </PermissionsProvider>
  );
};

// FeaturesAccess.propTypes = {};

export default FeaturesAccess;
