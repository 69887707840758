import React, { useEffect, useState, useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getClientCredentialsVerify, getClientCredentialsConfig, postClientCredentialsSetup } from 'src/api/auth';
import { Space, Result, notification } from 'antd';
import Wizard from 'src/components/controls/Wizard/Wizard';
import { MdSecurity } from 'react-icons/md';
import ClientCredentialsConfig from './ClientCredentialsConfig';
import { notEmptyArray } from 'src/misc/Misc';
// import useUserInfo from 'src/hooks/useUserInfo';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import GcPermissionsProvider from '../GcPermissionsProvider';

var wizardCurrentStepGlobal = null;
const ClientCredentialsProvider = ({ children, appId, welcomeStep, setupStepList, showSetupEvenIfClientExists }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  // const userInfo = useUserInfo();
  const { oAuthVerified, setOAuthVerified, oAuthClientCredentialsConfig, setOAuthClientCredentialsConfig, oAuthWizardSteps, setOAuthWizardSteps } = useContext(GlobalContext);

  // console.log(userInfo);
  // const [oAuthVerify, setOAuthVerify] = useState(null);
  // const [oAuthClientCredentialsConfig, setOAuthClientCredentialsConfig] = useState(null);

  useEffect(() => {
    console.log('ClientCredentialsProvider()');
    if (oAuthVerified[appId]) return;
    loadData();
    prepareWizardSteps();
  }, []);

  useEffect(() => {
    if (!oAuthVerified[appId] || ![appId]) return;
    prepareWizardSteps();
  }, [oAuthVerified[appId], oAuthClientCredentialsConfig[appId]]);

  const loadData = async () => {
    console.log('loadData()');
    executeAsyncProcess(async () => {
      try {
        const resp = await getClientCredentialsConfig(appId);
        setOAuthClientCredentialsConfig(appId, resp);
        const resp1 = await getClientCredentialsVerify(appId);
        setOAuthVerified(appId, resp1);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getGcRequiredPermissions = () => {
    const permissions = [];
    // <add constant permissions needed to create oAuth client>
    // to be able to manage a custom role for the oAuth client:
    permissions.push({ domain: 'authorization', entityName: 'role', action: 'view' });
    permissions.push({ domain: 'authorization', entityName: 'role', action: 'add' });
    permissions.push({ domain: 'authorization', entityName: 'role', action: 'edit' });
    permissions.push({ domain: 'authorization', entityName: 'role', action: 'delete' });
    // to be able to manage the oAuth client:
    permissions.push({ domain: 'oauth', entityName: 'client', action: 'view' });
    permissions.push({ domain: 'oauth', entityName: 'client', action: 'add' });
    permissions.push({ domain: 'oauth', entityName: 'client', action: 'edit' });
    // </add constant permissions needed to create oAuth client>

    // <add permissions from the application config>
    if (true === notEmptyArray(oAuthClientCredentialsConfig[appId]?.gcCustomRole?.permissionPolicies)) {
      for (let policy of oAuthClientCredentialsConfig[appId].gcCustomRole.permissionPolicies) {
        for (let action of policy.actionSet) {
          if (permissions.some((x) => x.domain === policy.domain && x.entityName === policy.entityName && x.action === action)) continue;
          const permission = { domain: policy.domain, entityName: policy.entityName, action: action };
          permissions.push(permission);
        }
      }
    }
    // </add permissions from the application config>
    return permissions;
  };

  //#region "Handle Wizard"

  const config = ClientCredentialsConfig[appId];

  // const [wizardSteps, setWzardSteps] = useState(null);
  const [wizardCurrentStep, setWizardCurrentStep] = useState(0);

  useEffect(() => {
    wizardCurrentStepGlobal = wizardCurrentStep;
  }, [wizardCurrentStep]);

  const prepareWizardSteps = () => {
    const steps = [];
    if (welcomeStep) {
      steps.push({ ...welcomeStep, header: <div className='text-xl'>Setup</div> });
    }

    steps.push(defaultWizardSteps[0]);
    if (notEmptyArray(setupStepList)) {
      for (let setupStep of setupStepList) {
        setupStep.header = config?.header;
        steps.push(setupStep);
      }
    }

    steps.push(defaultWizardSteps[1]);
    setOAuthWizardSteps(appId, steps);
  };

  const defaultWizardSteps = [
    {
      title: 'oAuth Client',
      header: config?.header,
      onNext: async () => {
        console.log('oAuth Client - onNext()');
        await executeAsyncProcess(async () => {
          try {
            await postClientCredentialsSetup(appId);
            //await sleepMs(100);
            notification.success({
              message: 'Success',
              description: <>oAuth client has been created</>,
              duration: 5,
            });
          } catch (error) {
            console.log(error);
          }
        });
      },
      content: (
        <div>
          <div className='font-bold text-xl '>Create an oAuth client in your Genesys Cloud organization</div>

          <div className='font-extralight'>
            <div className='mt-4'>
              This tool requires the creation of an OAuth client in your Genesys Cloud organization. The OAuth client will be used to run this tool in the future.
              <br />
              <br /> The following items will be added to your Genesys Cloud organization:
            </div>

            <div className='mt-4'>
              <span className='font-medium'>Client name:</span> {oAuthClientCredentialsConfig[appId]?.clientName ?? 'n/a'}
            </div>

            <div className='mt-1'>
              <span className='font-medium'>Role name:</span> {oAuthClientCredentialsConfig[appId]?.gcCustomRole?.name ?? 'n/a'}
            </div>

            {notEmptyArray(oAuthClientCredentialsConfig[appId]?.gcCustomRole?.permissionPolicies) && (
              <>
                <div className='mt-1 font-medium'>Role permissions:</div>
                <div className='mt-1 grid grid-cols-3 gap-x-4'>
                  {oAuthClientCredentialsConfig[appId]?.gcCustomRole?.permissionPolicies.map((policy) => {
                    return policy.actionSet.map((action) => {
                      return (
                        <div>
                          {policy.domain}.{policy.entityName}.{action}
                        </div>
                      );
                    });
                  })}
                </div>
              </>
            )}
          </div>
          <div className='mt-4'>
            Click the <strong>Next</strong> button to create the OAuth client as well as a dedicated role.
          </div>
        </div>
      ),
    },
    {
      title: 'Done',
      allowBack: false,
      header: (
        <Space className='btn'>
          <MdSecurity />
          Security Advisor
        </Space>
      ),
      content: <div className='w-full h-full flex flex-col justify-center items-center gap-2'>{<Result status='success' title='Setup is complete. Please click on Finish to access your tool.' />}</div>,
    },
  ];

  const handleWizardOnNext = async () => {
    const step = oAuthWizardSteps[appId][wizardCurrentStepGlobal];
    if (step.onNext) {
      await step.onNext();
    }

    setWizardCurrentStep(wizardCurrentStepGlobal + 1);
  };

  const handleWizardOnPrev = () => {
    setWizardCurrentStep(wizardCurrentStepGlobal - 1);
  };

  const handleWizardOnFinish = () => {
    window.location.reload();
  };
  //#endregion

  if (!oAuthVerified[appId]) return <FullScreenSpin title='Please wait' subtitle='Verifying oAuth configuration' />;

  if ((showSetupEvenIfClientExists || !oAuthVerified[appId].oauthClientGC || !oAuthVerified[appId].oauthClientDB || !oAuthVerified[appId].oauthClientMatch) && oAuthClientCredentialsConfig[appId] && oAuthWizardSteps[appId]) {
    return (
      <GcPermissionsProvider appId={appId} requiredPermissions={getGcRequiredPermissions()}>
        <Wizard steps={oAuthWizardSteps[appId]} currentStep={wizardCurrentStep} onNext={handleWizardOnNext} onPrev={handleWizardOnPrev} onFinish={handleWizardOnFinish} />;
      </GcPermissionsProvider>
    );
  }

  return children;
};

ClientCredentialsProvider.propTypes = {
  children: PropTypes.node,
  appId: PropTypes.string,
  welcomeStep: PropTypes.object,
  setupStepList: PropTypes.array,
  showSetupEvenIfClientExists: PropTypes.bool,
};

export default ClientCredentialsProvider;
