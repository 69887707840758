// Taken from https://simple.cruip.com/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './components/ui/Header';
import Hero from './components/Hero';
import Banner from './components/Banner';
// import Features from './components/Features';
import FeaturesBlocks from './components/FeaturesBlocks';
// import Testimonials from './components/Testimonials';
// import Newsletter from './components/Newsletter';
import Footer from './components/ui/Footer';

const Landing = ({ children }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  return (
    <div className='flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip'>
      <main className='grow'>
        <Header />
        <Hero />
        {/* <Features /> */}
        <FeaturesBlocks />
        {/* <Testimonials /> */}
        <div className='h-32' />
        {/* <Newsletter /> */}
        <Banner />
      </main>
      <Footer />
    </div>
  );
};

Landing.propTypes = {
  children: PropTypes.node,
};

export default Landing;
