import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import PropTypes from 'prop-types';
import UserImage from 'src/components/layout/UserImage';
import TicketStatusColor from 'src/components/pages/Teacket/Components/TicketStatusColor';

const Item = ({ ticket }) => {
  const { myTicketsSelectedTicket, setMyTicketsSelectedTicket, myTickets, setMyTickets } = useContext(TeacketContext);

  const [isSelected, setIsSelected] = useState(false);
  const [requester, setRequester] = useState(null);

  useEffect(() => {
    setRequester(ticket?.teacket_ticket_members?.find((member) => member.role === 'Requester')?.user ?? null);
  }, [ticket]);

  useEffect(() => {
    setIsSelected(myTicketsSelectedTicket === ticket?.id);
  }, [myTicketsSelectedTicket]);

  const handleItemClick = () => {
    if (isSelected === false && myTicketsSelectedTicket) {
      const previouslySelected = myTickets.findIndex((t) => t.id === myTicketsSelectedTicket);
      if (previouslySelected !== -1) {
        if (myTickets[previouslySelected]?.ghostMode && ticket?.id !== myTicketsSelectedTicket) {
          setMyTickets(myTickets.filter((x) => x.id !== myTicketsSelectedTicket));
        }
      }
    }

    setMyTicketsSelectedTicket(ticket?.id ?? null);
  };

  return (
    <div onClick={isSelected ? null : handleItemClick} className={`rounded-l-xl px-3 py-1 ${!isSelected && 'bg-sky-100 dark:bg-sky-900 cursor-pointer opacity-70 origin-right scale-95 hover:scale-100 transition-all'} ${isSelected && 'bg-sky-200 dark:bg-sky-800'}  ${ticket.ghostMode ? 'opacity-30' : ''}  `}>
      <div className='overflow-hidden'>
        <div className='flex flex-row gap-2 items-center'>
          <div>{ticket.id}</div>
          <div className='flex flex-row gap-1 items-center'>
            <TicketStatusColor serviceId={ticket.service_id} status={ticket.status} />
            <span className='text-xs font-light opacity-80'>{ticket.status}</span>
          </div>
        </div>
        <div className='mt-1 flex flex-row gap-1'>
          <div>
            <UserImage image={requester?.user_pic} size='24px' />
          </div>
          <div>
            <div className='font-bold'>{requester?.name ?? 'no requester'}</div>
            <div className='font-bold text-[0.6rem] opacity-60'>{ticket?.acc_name ?? 'unknown account'}</div>
            <div className='max-h-[32px] overflow-hidden text-xs font-light'>{ticket.title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  ticket: PropTypes.object.isRequired,
};

export default Item;
