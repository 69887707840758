import React, { useState, useEffect } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useTheme from 'src/hooks/useTheme';
import { getQuestion } from 'src/api/fsireport';
import PropTypes from 'prop-types';
import { Space, Tabs } from 'antd';
import TitleBar from 'src/components/layout/TitleBar';
import { GrConfigure } from 'react-icons/gr';
import NoData from 'src/components/layout/NoData';
import QuestionEditorConfiguration from './QuestionEditorConfiguration';

const QuestionEditor = ({ questionId, onSubmit }) => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { theme } = useTheme();

  const loadData = async (loadMore = false) => {
    console.log('loadData');
    await executeAsyncProcess(async () => {
      try {
        const resp = await getQuestion(questionId);
        setQuestion(resp);
      } catch (error) {
        if (error?.toString().includes('The user aborted a request')) return;
        console.log(error);
      }
    });
  };

  const tabItems = [
    {
      key: 'question',
      label: (
        <Space className='btn'>
          <GrConfigure />
          Question
        </Space>
      ),
      children: (
        <QuestionEditorConfiguration
          questionId={questionId}
          onSubmit={() => {
            if (onSubmit) onSubmit();
          }}
        />
      ),
    },
  ];

  const [question, setQuestion] = useState(null);
  const [activeTab, setActiveTab] = useState(tabItems[0].key);

  useEffect(() => {
    setQuestion(null);
    if (!questionId) return;
    setActiveTab(tabItems[0].key);
    loadData();
  }, [questionId]);

  if (!question)
    return (
      <div className='h-full flex flex-row justify-center items-center'>
        <NoData />
      </div>
    );

  return (
    <>
      <TitleBar
        busyIndicator={false}
        colorBackground={theme.backgroundBase}
        title={
          <Space className='whitespace-nowrap btn'>
            <div className='overflow-hidden overflow-ellipsis'>{question?.question_id ?? 'unkn44444own'}</div>
          </Space>
        }
        afterTitleExtras={<></>}
      ></TitleBar>
      <div className='p-4'>
        <Tabs onTabClick={(key) => setActiveTab(key)} activeKey={activeTab} type='card' items={tabItems} />
      </div>
    </>
  );
};

QuestionEditor.propTypes = {
  questionId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default QuestionEditor;
