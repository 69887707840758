// import VideoThumb from '/images/hero-image.png';
import ModalVideo from './ModalVideo';

export default function Hero() {
  return (
    <section className='relative'>
      {/* Illustration behind hero content */}
      <div className='absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1' aria-hidden='true'>
        <svg width='1360' height='578' viewBox='0 0 1360 578' xmlns='http://www.w3.org/2000/svg'>
          <defs>
            <linearGradient x1='0%' y1='50%' x2='100%' y2='50%' id='illustration-01'>
              <stop stopColor='#ffffff' offset='0%' />
              <stop stopColor='#23395d' offset='100%' />
            </linearGradient>
          </defs>
          <g fill='url(#illustration-01)' fillRule='evenodd'>
            <circle cx='1232' cy='128' r='128' />
            <circle cx='155' cy='443' r='64' />
          </g>
        </svg>
      </div>

      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        {/* Hero content */}
        <div className='pt-32 pb-12 md:pt-40 md:pb-20'>
          {/* Section header */}
          <div className='text-center pb-12 md:pb-16'>
            <h1 className='text-6xl md:text-8xl font-extrabold leading-tighter tracking-tighter mb-4' data-aos='zoom-y-out'>
              Security, Privacy & Compliance <span className='bg-clip-text text-transparent bg-gradient-to-r from-genesys-aqua-800 to-genesys-aqua-400'>center</span>
            </h1>
            <div className='max-w-3xl mx-auto'>
              <p className='text-xl tracking-wide font-light text-genesys-gray-base mb-8' data-aos='zoom-y-out' data-aos-delay='150'>
                Use our security & privacy tools directly from your Genesys Cloud CX organization, and get beautiful results forever.
              </p>
              {/* <div className='max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center tracking-wide space-x-4' data-aos='zoom-y-out' data-aos-delay='300'>
                <a href='#0' className='btn inline-flex items-center justify-center p-4 no-underline text-base font-medium text-gray-500 rounded-lg bg-blue-700 hover:text-white-900 hover:bg-blue-800 dark:text-gray-100 dark:bg-blue-600 dark:hover:bg-blue-700 dark:hover:text-white'>
                  <span className='w-full'>Start free trial</span>
                </a>
                <a href='#0' className='inline-flex items-center justify-center p-4 no-underline text-base font-medium text-gray-500 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white'>
                  <span className='w-full'>Learn more</span>
                  <svg className='w-4 h-4 ms-2 rtl:rotate-180' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 10'>
                    <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M1 5h12m0 0L9 1m4 4L9 9' />
                  </svg>
                </a>
              </div> */}
            </div>
          </div>

          {/* Hero image */}
          <ModalVideo thumb={'/images/landing/21743724_6505026.svg'} thumbWidth={768} thumbHeight={432} thumbAlt='Modal video thumbnail' video='/videos/SPC - Introduction.mp4' videoWidth={1920} videoHeight={1080} />
        </div>
      </div>
    </section>
  );
}
