import React, { useContext, useState, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { Select, Button, Space, message, Modal, Input } from 'antd';
import { patchTicket, postEscalateTicket } from 'src/api/teacket';
import { FaWindowClose } from 'react-icons/fa';
import { PiSirenFill } from 'react-icons/pi';
import { MdSend } from 'react-icons/md';
import PropertyItem from 'src/components/layout/PropertyItem';
import useUserInfo from 'src/hooks/useUserInfo';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { hasMasterAdminRights, hasSuperuserRights } from '../Misc/misc';
import TicketStatusColor from './TicketStatusColor';
import TicketPriorityColor from './TicketPriorityColor';
import { uuid } from 'short-uuid';

const TicketStatus = () => {
  const { currentTicket, currentTicketMembers, currentTicketReadOnly, getCurrentTicketService, servicesForTenant, updateCurrentTicket, setTicketAfterUpdateSync } = useContext(TeacketContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const { id: userId, permissions: userPermissions } = useUserInfo();

  // const priorityOptions = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.priorityList?.map(({ priority }) => ({ key: priority, label: priority }));

  const [priorityList, setPriorityList] = useState(null);

  const [statusListNotClosed, setStatusListNotClosed] = useState(null);
  const [statusListClosed, setStatusListClosed] = useState(null);
  const [statusListEscalated, setStatusListEscalated] = useState(null);
  const [wrapUpCodeList, setWrapUpCodeList] = useState(null);

  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [closeStatus, setCloseStatus] = useState(null);
  const [closeWrapUpCode, setCloseWrapUpCode] = useState(null);

  const [escalateModalOpen, setEscalateModalOpen] = useState(false);
  const [escalateStatus, setEscalateStatus] = useState(null);
  const [escalateJustification, setEscalateJustification] = useState(null);
  const JUSTIFICATION_MIN_LENGTH = 25;

  useEffect(() => {
    determinePriorityList();
    determineStatusLists();
  }, []);

  useEffect(() => {
    if (!closeModalOpen) return;
    setCloseStatus(statusListClosed?.length === 1 ? statusListClosed[0].value : null);
    setCloseWrapUpCode(null);
  }, [closeModalOpen]);

  useEffect(() => {
    if (!escalateModalOpen) return;
    setEscalateStatus(statusListEscalated?.length === 1 ? statusListEscalated[0].value : null);
    setEscalateJustification(null);
  }, [escalateModalOpen]);

  // useEffect(() => {
  //   if (true === isClosedStatus()) setCurrentTicketReadOnly(true);
  // }, [currentTicket?.status]);

  const determinePriorityList = () => {
    const priorities = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.priorityList?.map(({ priority }) => ({ value: priority, label: priority }));
    setPriorityList(priorities);
  };

  const determineStatusLists = () => {
    const notClosed = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.filter((s) => s.userSelectable && !s.isClosed)
      .map(({ status }) => ({ value: status, label: status }));
    const closed = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.filter((s) => s.userSelectable && s.isClosed)
      .map(({ status }) => ({ value: status, label: status }));
    const escalated = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.filter((s) => s.isEscalated)
      .map(({ status }) => ({ value: status, label: status }));
    const wrapUpCodes = servicesForTenant?.find((service) => service.id === currentTicket?.service_id)?.props?.wrapUpCodes?.map(({ wrapUpCode }) => ({ value: wrapUpCode, label: wrapUpCode }));

    setStatusListNotClosed(notClosed);
    setStatusListClosed(closed);
    setStatusListEscalated(escalated);
    setWrapUpCodeList(wrapUpCodes);
  };

  const handleOnPriorityChange = (priority) => {
    console.log('handleOnPriorityChange()', priority);
    executeAsyncProcess(async () => {
      try {
        const ticket = await patchTicket(currentTicket.id, { priority });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Priority successfully updated',
          duration: 3,
        });
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleOnStatusChange = async (status) => {
    console.log('handleOnStatusChange()', status);
    // if (currentTicket?.status === e.key) return;
    await executeAsyncProcess(async () => {
      try {
        const ticket = await patchTicket(currentTicket.id, { status, wrap_up_code: null, closed_at: null, justification: null });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Status successfully updated',
          duration: 3,
        });
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const priorityLabelRender = ({ value }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <TicketPriorityColor serviceId={currentTicket?.service_id} priority={value} size={'12px'} />
        <span>{value}</span>
      </div>
    );
  };

  const priorityOptionRender = ({ value }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <TicketPriorityColor serviceId={currentTicket?.service_id} priority={value} size={'10px'} />
        <span>{value}</span>
      </div>
    );
  };

  const statusLabelRender = ({ value }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <TicketStatusColor serviceId={currentTicket?.service_id} status={value} size={'12px'} />
        <span>{value}</span>
      </div>
    );
  };

  const statusOptionRender = ({ value }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <TicketStatusColor serviceId={currentTicket?.service_id} status={value} size={'10px'} />
        <span>{value}</span>
      </div>
    );
  };

  //#region "HANDLERS - MODAL CLOSE"

  const handleOnCloseTicketClick = async () => {
    console.log('handleOnCloseTicketClick()');
    setCloseModalOpen(true);
  };

  const handleCloseModalOnCancel = () => {
    setCloseModalOpen(false);
  };

  const handleCloseModalOnCloseStatusChange = (status) => {
    console.log('handleCloseModalOnCloseStatusChange()', status);
    setCloseStatus(status);
  };

  const handleCloseModalOnWrapUpCodeChange = (wrapUpCode) => {
    console.log('handleCloseModalOnWrapUpCodeChange()', wrapUpCode);
    setCloseWrapUpCode(wrapUpCode);
  };

  const handleCloseModalOnSubmit = async () => {
    console.log('handleCloseModalOnSubmit()');
    await executeAsyncProcess(async () => {
      try {
        const ticket = await patchTicket(currentTicket.id, { status: closeStatus, wrap_up_code: closeWrapUpCode, closed_at: new Date().toISOString() });
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Ticket successfully closed',
          duration: 3,
        });
        setCloseModalOpen(false);
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const isClosedStatus = () => statusListClosed?.some((s) => s.value === currentTicket?.status);

  const isEscalatedStatus = () => statusListEscalated?.some((s) => s.value === currentTicket?.status);

  //#endregion

  //#region "HANDLERS - MODAL ESCALATE"

  const handleOnEscalateTicketClick = async () => {
    console.log('handleOnEscalateTicketClick()');
    setEscalateModalOpen(true);
  };

  const handleEscalateModalOnCancel = () => {
    setEscalateModalOpen(false);
  };

  const handleEscalateModalOnCloseStatusChange = (status) => {
    console.log('handleEscalateModalOnCloseStatusChange()', status);
    setEscalateStatus(status);
  };

  const handleEscalateModalOnJustificationChange = (e) => {
    console.log('handleEscalateModalOnJustificationChange()', e.target.value);
    setEscalateJustification(e.target.value);
  };

  const handleEscalateModalOnSubmit = async () => {
    console.log('handleEscalateModalOnSubmit()');
    await executeAsyncProcess(async () => {
      try {
        const ticket = await postEscalateTicket(currentTicket.id, escalateStatus, escalateJustification);
        updateCurrentTicket(ticket);
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Ticket successfully escalated',
          duration: 3,
        });
        setEscalateModalOpen(false);
        setTicketAfterUpdateSync(true);
      } catch (error) {
        console.error(error);
      }
    });
  };

  //#endregion

  const canManageStatus = () => {
    // admin can always manage status
    if (notEmptyArray(userPermissions) && true === hasMasterAdminRights(userPermissions)) return true;

    // if the ticket is read only, nobody can manage status
    if (currentTicketReadOnly) return false;

    // superuser can always manage status
    if (notEmptyArray(userPermissions) && true === hasSuperuserRights(userPermissions)) return true;

    // if the ticket has no members, nobody can manage status
    if (true === notArrayOrEmpty(currentTicketMembers)) return false;

    // if the ticket has members, only people with a role flag can manage status
    const member = currentTicketMembers.find((x) => x.user_id === userId);
    const currentTicketService = getCurrentTicketService();
    if (member && currentTicketService?.props?.roleList?.find((x) => x.role === member.role)?.canManageStatuses) return true;

    return false;
  };

  const canEscalate = () => {
    if (true === isEscalatedStatus()) return false;
    if (statusListEscalated?.length < 1) return false;
    if (currentTicketReadOnly) return false;

    const isDefaultStatus = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.statusList?.find((s) => s.status === currentTicket?.status)
      ?.default;

    const isDefaultPriority = servicesForTenant
      ?.find((service) => service.id === currentTicket?.service_id)
      ?.props?.priorityList?.find((s) => s.priority === currentTicket?.priority)
      ?.default;

    // hide escalate button for default status and priority
    if (true === isDefaultStatus || true === isDefaultPriority) return false;

    return true;

  };

  return (
    <>
      {contextHolder}
      {/* <MODAL - CLOSE> */}
      <Modal
        title={`Closing the ${currentTicket?.id} ticket`}
        open={closeModalOpen}
        onCancel={handleCloseModalOnCancel}
        footer={[
          <Button type='primary' loading={isBusy} disabled={!closeStatus || !closeWrapUpCode} onClick={handleCloseModalOnSubmit}>
            <Space className='btn'>
              <MdSend />
              Submit
            </Space>
          </Button>,
        ]}
      >
        <div className='flex flex-col gap-2'>
          <div>
            Status:
            <Select disabled={isBusy} className='w-full' options={statusListClosed} value={closeStatus} onChange={handleCloseModalOnCloseStatusChange} placeholder='Not selected' labelRender={statusLabelRender} optionRender={statusOptionRender} />
          </div>
          <div>
            Wrap-up code:
            <Select disabled={isBusy} className='w-full' options={wrapUpCodeList} value={closeWrapUpCode} onChange={handleCloseModalOnWrapUpCodeChange} placeholder='Not selected' />
          </div>
        </div>
      </Modal>
      {/* </MODAL - CLOSE> */}

      {/* <MODAL - ESCALATE> */}
      <Modal
        title={`Escalating the ${currentTicket?.id} ticket`}
        open={escalateModalOpen}
        onCancel={handleEscalateModalOnCancel}
        footer={[
          <Button type='primary' loading={isBusy} disabled={!escalateStatus || !escalateJustification || escalateJustification.length < JUSTIFICATION_MIN_LENGTH} onClick={handleEscalateModalOnSubmit}>
            <Space className='btn'>
              <MdSend />
              Submit
            </Space>
          </Button>,
        ]}
      >
        <div className='flex flex-col gap-2'>
          <div>
            Status:
            <Select disabled={isBusy} className='w-full' options={statusListEscalated} value={escalateStatus} onChange={handleEscalateModalOnCloseStatusChange} placeholder='Not selected' labelRender={statusLabelRender} optionRender={statusOptionRender} />
          </div>
          <div>
            Justification <span className='text-xs font-light opacity-60'>(min {JUSTIFICATION_MIN_LENGTH} characters)</span>:
            <Input.TextArea disabled={isBusy} className='w-full mb-4' value={escalateJustification} onChange={handleEscalateModalOnJustificationChange} autoSize={{ minRows: 2, maxRows: 8 }} maxLength={250} showCount />
          </div>
        </div>
      </Modal>
      {/* </MODAL - ESCALATE> */}

      <div className='flex flex-col gap-2'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <div>Status:</div>
            <Select className='w-full' options={statusListNotClosed} value={currentTicket?.status} onChange={handleOnStatusChange} disabled={false === canManageStatus()} labelRender={statusLabelRender} optionRender={statusOptionRender} />
          </div>
          <div className='flex flex-col gap-1'>
            <div>Priority:</div>
            <Select className='w-full' options={priorityList} value={currentTicket?.priority} onChange={handleOnPriorityChange} disabled={false === canManageStatus()} labelRender={priorityLabelRender} optionRender={priorityOptionRender} />
          </div>
        </div>
        {currentTicket?.justification && <PropertyItem label='Justification for escalation' value={currentTicket?.justification} />}
        {isClosedStatus() ? (
          <PropertyItem label='Wrap-up code' value={currentTicket?.wrap_up_code} />
        ) : (
          <div className='flex flex-col gap-2'>
            {true === canManageStatus() && (
              <Button type='text' onClick={handleOnCloseTicketClick}>
                <Space className='btn'>
                  <FaWindowClose />
                  Close this ticket
                </Space>
              </Button>
            )}
            {true === canEscalate() && (
              <Button type='text' onClick={handleOnEscalateTicketClick}>
                <Space className='btn'>
                  <PiSirenFill />
                  Escalate this ticket
                </Space>
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

TicketStatus.propTypes = {};

export default TicketStatus;
