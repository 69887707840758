import React, { useEffect, useState, useContext } from 'react';
import { Button, Input, Space, Spin, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { notEmptyArray } from 'src/misc/Misc';
import AiTemplateBadge from './AiTemplateBadge';
import AiTemplateBadgeSkeleton from './AiTemplateBadgeSkeleton';
import { TbUsersGroup } from 'react-icons/tb';
import AITemplatesContext from 'src/context/AITemplatesContext';

import { searchAiTemplates } from 'src/api/aiTemplates';

var aiTemplatesCurrentPage = 1;

const AiTemplateSelector = ({ selected, onAiTemplateSearch, onAiTemplateSelected }) => {
  const items = [
    {
      key: 5,
      label: <>5</>,
    },
    {
      key: 15,
      label: <>15</>,
    },
    {
      key: 25,
      label: <>25</>,
    },
  ];

  const { aiTemplates, setAiTemplates } = useContext(AITemplatesContext);

  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(parseInt(items[1].key));
  const [thereIsMore, setThereIsMore] = useState(false);

  useEffect(() => {
    console.log('[AiTemplateSelector] Ai templates', aiTemplates);
  }, [aiTemplates]);

  useEffect(() => {
    aiTemplatesCurrentPage = 1;
    setAiTemplates(null);
    setThereIsMore(false);
    loadData();
  }, [query, pageSize]);

  const loadData = async (loadMore = false) => {
    setLoading(true);
    if (onAiTemplateSearch) onAiTemplateSearch(query);
    try {
      const q = query?.trim() ?? null;
      const resp = await searchAiTemplates(q?.length > 0 ? q : null, aiTemplatesCurrentPage, pageSize);
      aiTemplatesCurrentPage++;
      setThereIsMore(resp?.length === pageSize ? true : false);
      if (true === notEmptyArray(resp)) {
        if (loadMore) setAiTemplates([...aiTemplates, ...resp]);
        else setAiTemplates(resp);
      }
    } catch (error) {
      if (error?.toString().includes('The user aborted a request')) return;
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className='h-full max-h-full flex flex-col'>
      <div className='flex-initial p-4 flex flex-row justify-center'>
        <div className='flex flex-col'>
          <Input
            className='w-60'
            placeholder='Search...'
            prefix={loading ? <Spin size='small' className='mr-2' /> : <SearchOutlined className='mr-2' />}
            allowClear
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />

          <div>
            <Dropdown
              trigger={['click']}
              menu={{
                activeKey: pageSize,
                items,
                onClick: (e) => {
                  console.log(e);
                  setPageSize(parseInt(e.key));
                },
              }}
              className='text-xs cursor-pointer'>
              <span className='text-blue-600 opacity-40 hover:opacity-100 '>page size {pageSize}</span>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className='relative flex-auto overflow-scroll flex flex-col justify-start items-center'>
        <div className='flex flex-row gap-4 p-4 flex-wrap max-w-2xl content-center justify-center'>
          {aiTemplates?.map((aiTemplate) => (
            <AiTemplateBadge
              key={aiTemplate.id}
              id={aiTemplate.id}
              name={aiTemplate.name}
              content={aiTemplate.content}
              onClick={(aiTemplateId) => {
                if (onAiTemplateSelected) onAiTemplateSelected(aiTemplateId);
              }}
              isSelected={selected === aiTemplate.id}
              newCreated={aiTemplate.newCreated}
            />
          ))}
          {loading && (
            <div className='center'>
              <AiTemplateBadgeSkeleton />
            </div>
          )}
        </div>
        {!loading && thereIsMore && (
          <div>
            <Button
              onClick={() => {
                loadData(true);
              }}>
              <Space className='btn'>
                <TbUsersGroup />
                Load more
              </Space>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

AiTemplateSelector.propTypes = {
  selected: PropTypes.number,
  onAiTemplateSearch: PropTypes.func,
  onAiTemplateSelected: PropTypes.func,
};

export default AiTemplateSelector;
