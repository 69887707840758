import React from 'react';
import PropTypes from 'prop-types';
import UserImage from 'src/components/layout/UserImage';
import useTheme from 'src/hooks/useTheme';

const UserSelectorItem = ({ id, name, image, selected, onClick }) => {
  const { theme } = useTheme();

  const handleOnClick = () => {
    if (!onClick) return;
    onClick(id);
  };

  return (
    <div className={`flex flex-row gap-1 items-center py-1 px-2 rounded-md ${!selected ? 'cursor-pointer hover:scale-105' : 'cursor-default'}`} style={{ userSelect: 'none', border: selected ? `2px solid ${theme.primary}` : `1px solid ${theme.borderLight}` }} onClick={!selected ? handleOnClick : null}>
      <UserImage image={image} size='24px' scaleOnHover={false} />
      <div className='font-light text-xs'>{name}</div>
    </div>
  );
};

UserSelectorItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default UserSelectorItem;
