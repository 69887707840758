import React from 'react';
//import PropTypes from 'prop-types';

const TicketTimeTrackingEntries = () => {
  return <>Hello TicketTimeTrackingEntries :-)</>;
};

TicketTimeTrackingEntries.propTypes = {};

export default TicketTimeTrackingEntries;
