import React, { useState, useEffect, useContext } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import MasterAdminContext from 'src/context/MasterAdminContext';
import PropTypes from 'prop-types';
import CondensedTable from 'src/components/layout/CondensedTable';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { getAdminServiceProps, putAdminServiceProps } from 'src/api/teacket';
import PropertySection from 'src/components/layout/PropertySection';
import { Button, Space, Tag, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import short from 'short-uuid';
import TeacketServiceEditorConfigForm from './TeacketServiceEditorConfigForm/TeacketServiceEditorConfigForm';

const TeacketServiceEditorConfigList = ({ prop, serviceId }) => {
  const { teacketServicePropData, setTeacketServicePropData, getTeacketServicePropData, /* resetTeacketServicePropData,*/ setTeacketServicePropShowModal, teacketServicePropShowModal } = useContext(MasterAdminContext);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const { title: propName, description: propDescription } = prop ?? {};

  const getColums = () => {
    let columns1 = [];
    const properties = prop?.items?.properties ?? {};
    for (const p in properties) {
      columns1.push({
        title: properties[p].description,
        key: p,
        align: 'center',
        // dataIndex: p,
        render: (record) => {
          if (typeof record[p] === 'boolean') {
            return <div>{true === record[p] ? 'Yes' : 'No'}</div>;
          } else if (Array.isArray(record[p])) {
            return <div>{record[p].join(', ')}</div>;
          } else {
            return <div>{record[p]}</div>;
          }
        },
      });
    }
    columns1.push({
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (record) => {
        return (
          <Space>
            <Button
              type='text'
              size='small'
              onClick={() => {
                handleEdit(record);
              }}>
              <EditOutlined />
            </Button>
            <Button
              type='text'
              size='small'
              onClick={() => {
                handleDelete(record);
              }}>
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    });
    return columns1;
  };

  const [columns] = useState(getColums());
  const [modified, setModified] = useState(false);

  // useEffect(() => {
  //   console.log('teacketServicePropData[propName]', teacketServicePropData[propName]);
  // }, [teacketServicePropData]);

  // useEffect(() => {
  //   return () => {
  //     resetTeacketServicePropData();
  //   };
  // }, []);

  useEffect(() => {
    if (!columns) return;
    loadData();
  }, [columns]);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = (await getAdminServiceProps(serviceId, propName))?.map((x) => {
          return { ...x, key: short.generate() };
        });
        setTeacketServicePropData(propName, resp);
        setModified(false);
      } catch (error) {
        if (error?.toString().includes('The user aborted a request')) return;
        console.log(error);
      }
    });
  };

  //#region "HANDLERS"

  const handleDelete = async (record) => {
    console.log('handleDelete()');
    if (!propName) return;
    const prop = getTeacketServicePropData(propName);
    if (true === notArrayOrEmpty(prop)) return;
    const pd = prop.filter((x) => x.key !== record.key);
    setTeacketServicePropData(propName, pd);
    setModified(true);
  };

  const handleEdit = async (record) => {
    console.log('handleEdit()');
    setTeacketServicePropShowModal(propName, record, 'edit');
  };

  const handleAddNew = async () => {
    console.log('handleAddNew()');
    setTeacketServicePropShowModal(propName, {}, 'add');
  };

  const handleSave = async () => {
    console.log('handleSave()');

    await executeAsyncProcess(async () => {
      try {
        const key = '8c3ba3d6-f753-4a4e-98b5-d27134617235';
        messageApi.open({
          key,
          type: 'loading',
          content: 'Saving...',
          duration: 0,
        });
        const data = teacketServicePropData[propName];
        await putAdminServiceProps(serviceId, propName, data);
        setModified(false);
        messageApi.open({
          key,
          type: 'success',
          content: 'Saved',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  //#endregion

  //#region "MODAL HANDLERS"

  const handleModalOnOk = async ({ item }) => {
    console.log('handleModalOnOk()', item);
    if (!teacketServicePropData) return;
    let data = true === notEmptyArray(teacketServicePropData[propName]) ? [...teacketServicePropData[propName]] : [];
    if (item.key) {
      // edited existing
      data = data.map((x) => (x.key === item.key ? item : x));
    } else {
      // added new
      data.push({ ...item, key: short.generate() });
    }
    setTeacketServicePropData(propName, data);
    setTeacketServicePropShowModal(null);
    setModified(true);
  };

  const handleModalOnCancel = () => {
    setTeacketServicePropShowModal(null);
  };

  //#endregion

  if (!propName) return null;
  if (!teacketServicePropData) return null;
  if (!teacketServicePropData[propName]) return null;
  if (true === notArrayOrEmpty(columns)) return null;

  return (
    <>
      {contextHolder}
      {propName === teacketServicePropShowModal?.propName && <TeacketServiceEditorConfigForm propName={propName} propDescription={propDescription} onOk={handleModalOnOk} onCancel={handleModalOnCancel} />}
      <PropertySection>
        <div className='p-2'>
          <div className='flex flex-row gap-2 w-full'>
            <div className='flex-initial text-lg font-bold flex flex-row gap-2 items-center'>
              <span> {propDescription}</span>
              {/* <span className='font-light opacity-60'>|</span> */}
              <span className='text-xs font-light opacity-60'>{propName}</span>
              {modified && <Tag color='red'>Not saved</Tag>}
            </div>
            <div className='flex-auto flex flex-row gap-2 justify-end'>
              <Button size='small' onClick={handleAddNew}>
                Add new
              </Button>
              <Button size='small' onClick={loadData}>
                Refresh
              </Button>
              <Button type='primary' size='small' onClick={handleSave} disabled={!modified}>
                Save
              </Button>
            </div>
          </div>
          <div className='mt-2'>
            <CondensedTable
              columns={columns}
              dataSource={teacketServicePropData[propName]}
              pagination={{
                pageSize: 10,
                size: 'small',
              }}
              rowKey='key'
            />
          </div>
        </div>
      </PropertySection>
    </>
  );
};

TeacketServiceEditorConfigList.propTypes = {
  prop: PropTypes.object.isRequired,
  serviceId: PropTypes.string,
};

export default TeacketServiceEditorConfigList;
