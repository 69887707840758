import React, { useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import PropTypes from 'prop-types';
import useUserInfo from 'src/hooks/useUserInfo';
import { getComponentsGallery } from '../Misc/ticketLayout';

const TicketModal = ({
  setLayoutItemsSelector = () => {
    console.warn('setLayoutItemsSelector() not provided');
  },
  rootComponentId,
  extraOffsetX,
}) => {
  const { permissions: userPermissions } = useUserInfo();
  const { getWidgetListForCurrentTicket } = useContext(TeacketContext);

  const componentsGallery = getComponentsGallery(userPermissions, getWidgetListForCurrentTicket());

  return <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={3} mainColumnIndex={1} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutTeacketTicketModal} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutTeacketTicketModalHidden} rootComponentId={rootComponentId} extraOffsetX={extraOffsetX} />;
};

TicketModal.propTypes = {
  setLayoutItemsSelector: PropTypes.func.isRequired,
  rootComponentId: PropTypes.string,
  extraOffsetX: PropTypes.number,
};

export default TicketModal;
