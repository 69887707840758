import React, { useContext, useState, useEffect } from 'react';
import UserSelector from 'src/components/controls/UserSelector/UserSelector';
import PropertyItem from 'src/components/layout/PropertyItem';
import TeacketContext from 'src/context/TeacketContext';
import { notEmptyArray } from 'src/misc/Misc';
import useUserInfo from 'src/hooks/useUserInfo';
//import PropTypes from 'prop-types';

const TicketTimeTrackingSubmit = () => {
  const { currentTicketMembers, getCurrentTicketService } = useContext(TeacketContext);
  const { id: userId } = useUserInfo();

  const [timeTrackingUsers, setTimeTrackingUsers] = useState(null);
  useEffect(() => {
    getTimeTrackingUsers();
  }, [currentTicketMembers]);

  const getTimeTrackingUsers = () => {
    const { props: props1 } = getCurrentTicketService();
    const roleList = props1?.roleList ?? [];
    console.log('roleList:', roleList);

    const result = notEmptyArray(currentTicketMembers)
      ? currentTicketMembers
          .filter((member) =>
            roleList
              .filter((role) => !role.isRequester)
              .map((role) => role.role)
              .includes(member.role)
          )
          .map((member) => {
            return { id: member.user_id, name: member.user_name, image: member.user_pic };
          })
      : null;
    setTimeTrackingUsers(result);
  };

  const handleUserSelectorOnSelect = (id) => {
    console.log('handleUserSelectorOnSelect:', id);
  };

  return (
    <div className='w-full flex flex-col gap-2'>
      <PropertyItem
        label='Who?'
        value={
          <div className='my-2'>
            <UserSelector userList={timeTrackingUsers} onSelect={handleUserSelectorOnSelect} defaultValue={notEmptyArray(timeTrackingUsers) && timeTrackingUsers.some((u) => u.id === userId) ? userId : null} />
          </div>
        }
      />
      <div className='flex flex-row w-full gap-4'>
        <div className='w-32 text-right font-bold text-xs'>How many hours?</div>
        <div className='flex-auto'>f</div>
      </div>
      <div className='flex flex-row w-full gap-4'>
        <div className='w-32 text-right font-bold text-xs'></div>
        <div className='flex-auto'>f</div>
      </div>
    </div>
  );
};

TicketTimeTrackingSubmit.propTypes = {};

export default TicketTimeTrackingSubmit;
