import React, { Component } from 'react';
import DataSubjectRequestsContext from './DataSubjectRequestsContext';
//import { notEmptyArray } from 'src/misc/Misc';
import { postJob, postRequest, getJob } from 'src/api/dataSubjectRequests';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import dayjs from 'dayjs';

export default class DataSubjectRequestsProvider extends Component {
  state = {
    eventWidgetItems: null,
    eventWidgetItemsForceReload: false,
    requestType: 'GDPR_EXPORT',
    forceCleanup: true,
    job: null,
    jobName: '',
    jobs: [],
    jobsCount: null,
    mode: '', // 'requests' or 'demo'
    optionsEditState: false,
    wizard_ReplacementValue: null,
    wizard_UpdateOldValue: null,
    wizard_ReplacementType: 'NAME',
    reportData: [],
    subject: null,
    useRealApi: false,
  };

  render() {
    return (
      <DataSubjectRequestsContext.Provider
        value={{
          ...this.state,
          setJob: (job) => this.setState({ job }),
          setSubject: (subject) => this.setState({ subject }),
          setJobName: (jobName) => this.setState({ jobName }),
          setJobs: (jobs) => this.setState({ jobs }), // All jobs for the current tenant
          deleteJobFromCollection: (jobId) => this.setState({ jobs: this.state.jobs.filter((job) => job.id !== jobId) }),
          wizard_SetReplacementType: (wizard_ReplacementType) => this.setState({ wizard_ReplacementType }),
          wizard_SetUpdateOldValue: (wizard_UpdateOldValue) => this.setState({ wizard_UpdateOldValue }),
          wizard_SetReplacementValue: (wizard_ReplacementValue) => this.setState({ wizard_ReplacementValue }),
          setRequestType: (requestType) => this.setState({ requestType }),
          setMode: (mode) => this.setState({ mode }),
          setUseRealApi: (useRealApi) => this.setState({ useRealApi }),
          setForceCleanup: (forceCleanup) => this.setState({ forceCleanup }),

          updateJob: (changes) => this.setState({ job: { ...this.state.job, ...changes } }),
          setOptionsEditState: (optionsEditState) => this.setState({ optionsEditState }),
          setEventWidgetItems: (eventWidgetItems) => this.setState({ eventWidgetItems }),
          setEventWidgetItemsForceReload: (eventWidgetItemsForceReload) => this.setState({ eventWidgetItemsForceReload }),
          setJobsCount: (jobsCount) => this.setState({ jobsCount }),
          submitRequest: async () => {
            try {
              let steps, requests;
              console.log('submitRequest', this.state.requestType, this.state.job?.use_real_api);

              switch (this.state.requestType) {
                case 'GDPR_EXPORT':
                  console.log('GDPR_EXPORT');
                  if (this.state.useRealApi || this.state.job?.use_real_api) {
                    steps = [];
                    requests = [
                      {
                        type: this.state.requestType,
                        status: 'notStarted',
                      },
                    ];
                  } else {
                    steps = [
                      {
                        message: null,
                        start_time: dayjs().subtract(5, 'minutes').utc().format(),
                        status: 'completed',
                        step: 'startGDPRProcess',
                      },
                      {
                        message: null,
                        start_time: dayjs().subtract(4, 'minutes').utc().format(),
                        status: 'waiting',
                        step: 'waitGDPRComplete',
                      },
                      {
                        message: null,
                        start_time: dayjs().subtract(3, 'minutes').utc().format(),
                        status: 'completed',
                        step: 'waitGDPRComplete',
                      },
                      {
                        message: null,
                        start_time: dayjs().subtract(2, 'minutes').utc().format(),
                        status: 'started',
                        step: 'processingFile',
                      },
                      {
                        message: null,
                        start_time: dayjs().subtract(1, 'minute').utc().format(),
                        status: 'completed',
                        step: 'processingFile',
                      },
                    ];
                    requests = [
                      {
                        type: this.state.requestType,
                        status: 'completed',
                        objects: [
                          {
                            type: 'file',
                            value: 'fixed-files/gdpr-export.zip',
                          },
                          {
                            type: 'folder',
                            value: 'fixed-files/gdpr-demo-folder',
                          },
                        ],
                      },
                    ];
                  }
                  // Create job and make it completed right away as this is a fake request

                  if (this.state.job) {
                    // Job already exists. Create a new Request
                    await postRequest(this.state.job.id, {
                      steps,
                      requests,
                    });
                  } else {
                    await postJob({
                      type: '1',
                      name: this.state.jobName,
                      time_zone: getBrowserTimeZone(),
                      use_real_api: this.state.useRealApi,
                      subject: this.state.subject,
                      steps,
                      requests,
                    });
                  }

                  break;
                case 'GDPR_UPDATE':
                  console.log('GDPR_UPDATE', this.state.wizard_ReplacementType, this.state.wizard_ReplacementValue);
                  if (this.state.useRealApi || this.state.job?.use_real_api) {
                    steps = [];
                    requests = [
                      {
                        type: this.state.requestType,
                        status: 'notStarted',
                        changes: [
                          {
                            type: this.state.wizard_ReplacementType,
                            existingValue: this.state.wizard_UpdateOldValue,
                            updatedValue: this.state.wizard_ReplacementValue,
                          },
                        ],
                      },
                    ];
                  } else {
                    steps = [
                      {
                        message: null,
                        start_time: dayjs().subtract(5, 'minutes').utc().format(),
                        status: 'completed',
                        step: 'startGDPRProcess',
                      },
                      {
                        message: null,
                        start_time: dayjs().subtract(4, 'minutes').utc().format(),
                        status: 'waiting',
                        step: 'waitGDPRComplete',
                      },
                      {
                        message: null,
                        start_time: dayjs().subtract(1, 'minute').utc().format(),
                        status: 'completed',
                        step: 'waitGDPRComplete',
                      },
                    ];
                    requests = [
                      {
                        type: this.state.requestType,
                        status: 'completed',
                        changes: [
                          {
                            type: this.state.wizard_ReplacementType,
                            existingValue: this.state.wizard_UpdateOldValue,
                            updatedValue: this.state.wizard_ReplacementValue,
                          },
                        ],
                      },
                    ];
                  }

                  if (this.state.job) {
                    await postRequest(this.state.job.id, {
                      steps,
                      requests,
                    });
                  } else {
                    await postJob({
                      type: '1',
                      name: this.state.jobName,
                      time_zone: getBrowserTimeZone(),
                      use_real_api: this.state.useRealApi,
                      subject: this.state.subject,
                      steps,
                      requests,
                    });
                  }

                  break;

                case 'GDPR_DELETE':
                  console.log('GDPR_DELETE');
                  if (this.state.useRealApi || this.state.job?.use_real_api) {
                    steps = [];
                    requests = [
                      {
                        type: this.state.requestType,
                        status: 'notStarted',
                      },
                    ];
                  } else {
                    steps = [
                      {
                        message: null,
                        start_time: dayjs().subtract(5, 'minutes').utc().format(),
                        status: 'completed',
                        step: 'startGDPRProcess',
                      },
                      {
                        message: null,
                        start_time: dayjs().subtract(4, 'minutes').utc().format(),
                        status: 'waiting',
                        step: 'waitGDPRComplete',
                      },
                      {
                        message: null,
                        start_time: dayjs().subtract(1, 'minute').utc().format(),
                        status: 'completed',
                        step: 'waitGDPRComplete',
                      },
                    ];
                    requests = [
                      {
                        type: this.state.requestType,
                        status: 'deleted',
                      },
                    ];
                  }

                  if (this.state.job) {
                    await postRequest(this.state.job.id, {
                      steps,
                      requests,
                    });
                  } else {
                    await postJob({
                      type: '1',
                      name: this.state.jobName,
                      time_zone: getBrowserTimeZone(),
                      use_real_api: this.state.useRealApi,
                      subject: this.state.subject,
                      steps,
                      requests,
                    });
                  }

                  break;

                default:
                  console.log('Invalid request type:', this.state.requestType);
              }

              this.setState({ wizard_ReplacementType: null, wizard_UpdateOldValue: null, wizard_ReplacementValue: null });

              // Get latest details about the job.
              if (this.state.job?.id) {
                const job = await getJob(this.state.job.id);
                this.setState({ job });
              }
            } catch (error) {
              console.error(error);
              throw new Error('Faield to create new request');
            }
          },
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </DataSubjectRequestsContext.Provider>
    );
  }
}
