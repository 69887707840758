import React, { useEffect, useContext } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import DashboardScreen from './Dashboard';
import ClientCredentialsProvider from 'src/providers/ClientCredentialsProvider/ClientCredentialsProvider';
import WelcomeStep from './Components/WizardCustomSteps/WelcomeStep';
import { postSetup } from 'src/api/securityAdvisor';
import OptionsStep from './Components/WizardCustomSteps/OptionsStep';
import { message } from 'antd';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import { getJobsCount } from 'src/api/securityAdvisor';
import { SA_APP_ID } from './Misc/misc';
import { uuid } from 'short-uuid';

//import PropTypes from 'prop-types';

var hipaa_required = null;
var pci_required = null;
const SecurityAdvisor = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { setupHipaa, setupPci, jobsCount, setJobsCount } = useContext(SecurityAdvisorContext);

  useEffect(() => {
    if (null === jobsCount) loadData();

    setBreadcrumb([
      {
        title: 'Security Advisor',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    hipaa_required = setupHipaa;
    pci_required = setupPci;
  }, [setupHipaa, setupPci]);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const resp = await getJobsCount();
        setJobsCount(resp?.jobs_count ?? '0');
      } catch (error) {
        console.log(error);
      }
    });
  };

  useEffect(() => console.log(JSON.stringify({ hipaa_required, pci_required })), [hipaa_required, pci_required]); // pls do not remove this

  if (null === jobsCount) return <FullScreenSpin title='Please wait' subtitle='Verifying jobs configuration' />;

  return (
    <PermissionsProvider requiredPermissions={['apps.securityadvisor.view']}>
      {contextHolder}
      <ClientCredentialsProvider
        showSetupEvenIfClientExists={jobsCount === '0'}
        appId={SA_APP_ID}
        welcomeStep={{
          title: 'Welcome',
          content: <WelcomeStep />,
        }}
        setupStepList={[
          {
            title: 'Options',
            allowBack: false,
            content: <OptionsStep />,
            onNext: async () => {
              await executeAsyncProcess(async () => {
                try {
                  const name = 'Security Advisor Job';
                  const time_zone = getBrowserTimeZone();
                  const is_enabled = true;
                  await postSetup({ name, time_zone, is_enabled, hipaa_required, pci_required });
                  messageApi.open({
                    key: uuid(),
                    type: 'success',
                    content: 'The job has been configured',
                    duration: 3,
                  });
                } catch (error) {
                  console.log(error);
                }
              });
            },
          },
        ]}
      >
        <DashboardScreen />
      </ClientCredentialsProvider>
    </PermissionsProvider>
  );
};

// HomeMasterAdmin.propTypes = {};

export default SecurityAdvisor;
